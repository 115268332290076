import React, { useEffect, useState } from "react";
import styles from "./DealCardReminder.module.scss";
import { Calendar, ConfigProvider, Modal, TimePicker, message } from "antd";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useMutation } from "react-query";
import { IReminderResponse, addReminder } from "services/Reminders";
import { NoticeType } from "antd/es/message/interface";
import DealCardReminderList from "./DealCardReminderList";
import ru from "antd/locale/ru_RU";
import content from "settings/content";
import moment from "moment";
import { addReminderToLead } from "services/Leads";

dayjs.extend(isSameOrAfter);

type Props = {
  isModalOpen: boolean;
  deal_id?: string;
  lead_id?: string;
  handleCloseReminer: () => void;
  refetchReminders: () => void;
  remindersData: IReminderResponse;
};

const DealCardReminder: React.FC<Props> = ({ deal_id, lead_id, isModalOpen, handleCloseReminer, remindersData, refetchReminders }) => {
  const { mutateAsync: onAddReminder } = useMutation({
    mutationFn: addReminder,
  });

  const { mutateAsync: onAddLeadReminder } = useMutation({
    mutationFn: addReminderToLead,
    onSuccess: () => {
      refetchReminders();
    },
  });

  const [messageApi, contextHolder] = message.useMessage();
  const [date, setDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
  const [time, setTime] = useState<string>();
  const [selectedHour, setSelectedHour] = useState<number>(0);
  const [comment, setComment] = useState("");
  const [highlightedDates, setHighlightedDates] = useState<string[]>([]);

  useEffect(() => {
    if (!remindersData) {
      return;
    }
    setHighlightedDates(remindersData?.items?.map((item) => dayjs(item.notify_at).format("YYYY-MM-DD")));
  }, [remindersData]);

  const handleTime = (e: dayjs.Dayjs | null) => {
    if (e === null) {
      return;
    }
    setTime(e.format("THH:mm:ss"));
  };

  const handleDate = (e: dayjs.Dayjs) => {
    setDate(e.format("YYYY-MM-DD"));
  };

  const handleReminder = async () => {
    if (deal_id && !lead_id) {
      onAddReminder({
        deal_id: deal_id,
        notify_at: dayjs(`${date}${time}`).toISOString(),
        comment: !comment ? content.reminders.comments : comment,
      }).then((response) => {
        if (response.status === 200) {
          alert("success", content.alert_messages.reminders.success_blank);
          setComment("");
          refetchReminders();
        } else {
          alert("error", content.alert_messages.reminders.error_blank);
        }
      });
    }
    if (lead_id && !deal_id) {
      onAddLeadReminder({
        lead_id,
        comment: !comment ? content.reminders.comments : comment,
        notify_at: dayjs(`${date}${time}`).toISOString(),
      }).then((response) => {
        if (response.status === 200) {
          alert("success", content.alert_messages.reminders.success_blank);
          setComment("");
          refetchReminders();
        } else {
          alert("error", content.alert_messages.reminders.error_blank);
        }
      });
    }
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const disabledHours = () => {
    const hours = [];
    const currentHour = moment().hour();

    // 24 - диапазон часов в сутках
    if (dayjs().format("YYYY-MM-DD") !== date) {
      return [24, 24];
    }

    for (let i = 0; i <= currentHour - 1; i++) {
      hours.push(i);
    }

    return hours;
  };

  const disabledMinutes = () => {
    const minutes = [];
    const currentMinutes = moment().minute();
    const minutesOffset = 0;

    const currentHour = moment().hour();

    if (selectedHour > currentHour) {
      return [60, 60];
    }

    // 60 - диапазон минут в часе
    if (dayjs().format("YYYY-MM-DD") !== date) {
      return [60, 60];
    }

    for (let i = 0; i <= currentMinutes + minutesOffset; i++) {
      minutes.push(i);
    }

    return minutes;
  };

  // При необходиомсти можно добавить секунды
  function disabledRangeTime() {
    return {
      disabledHours: () => disabledHours(),
      disabledMinutes: () => disabledMinutes(),
    };
  }

  const dateCellRender = (date: Dayjs) => {
    const dateString: string = date.format("YYYY-MM-DD");
    const isHighlighted = highlightedDates.includes(dateString);
    return (
      <div
        className={`custom-date-cell ${isHighlighted ? "highlighted" : ""}`}
        style={{ position: "absolute", left: 0, top: 0, width: "24px", height: "24px" }}
      ></div>
    );
  };

  return (
    <Modal
      destroyOnClose
      className={"custom_shop_modal"}
      open={isModalOpen}
      centered
      mask={false}
      onCancel={() => handleCloseReminer()}
      closeIcon={false}
      footer={false}
      width={300}
      maskClosable={false}
    >
      {contextHolder}
      <div className={styles.dealCardReminder__modal_header}>
        <span>Создать напоминание</span>
        <ButtonCustom isTransparent maxWidth="20px" className={styles.dealCardReminder__modal_close} onClick={() => handleCloseReminer()}>
          <CloseIcon />
        </ButtonCustom>
      </div>
      <ConfigProvider locale={ru}>
        <div className={styles.dealCardReminder__wrapper}>
          <div className={styles.dealCardReminder__calendar}>
            <Calendar
              cellRender={dateCellRender}
              fullscreen={false}
              onChange={(e) => handleDate(e)}
              disabledDate={(current) => dayjs(dayjs().format("YYYY-MM-DD")).isSameOrAfter(current)}
            />
          </div>
          <div className={styles.dealCardReminder__time}>
            <p>{content.reminders.time_title}</p>
            <TimePicker
              showNow={false}
              onOk={(e) => handleTime(e)}
              onChange={(e) => handleTime(e)}
              onSelect={(e) => setSelectedHour(e.hour())}
              allowClear={false}
              disabledTime={disabledRangeTime}
              showSecond={false}
            />
          </div>
          <div className={styles.dealCardReminder__comment}>
            <p>{content.reminders.comments}</p>
            <div className={styles.dealCardReminder__block}>
              <TextArea
                placeholder={content.reminders.comment_placeholder}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                maxLength={250}
              />
              <ButtonCustom disabled={!date || !time} className={styles.dealCardReminder__button} maxWidth="100px" onClick={handleReminder}>
                <span>{content.reminders.save_btn}</span>
              </ButtonCustom>
            </div>
          </div>
          {remindersData && <DealCardReminderList remindersData={remindersData} />}
        </div>
      </ConfigProvider>
    </Modal>
  );
};
export default DealCardReminder;
