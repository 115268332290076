import React from "react";
import styles from "./PublicGalleryHeader.module.scss";
import "./PublicGalleryHeader_override.scss";
import content from "settings/content";

type Props = {
  url: string;
  id: string;
};

const PublicGalleryHeader: React.FC<Props> = ({ url, id }) => {
  return (
    <div className={styles.publicGalleryHeader}>
      {/* <div className={styles.publicGalleryHeader__logo}>{content.gallery.public.header.logo}</div> */}
      <div className={styles.publicGalleryHeader__title}>{content.gallery.public.header.title}</div>
      <div className={styles.publicGalleryHeader__contacts}>
        {/* <div className={styles.publicGalleryHeader__contacts_city}>
          <CityIcon style={{ marginRight: "5px" }} />
          <SelectCustom className="public_select_header" defaultValue="Екатеринбург" options={[]} />
        </div>
        <div className={styles.publicGalleryHeader__contacts_phone}>+ 7 (343) 125-15-61</div> */}
      </div>
      {/* <div className={styles.publicGalleryHeader__title_mobile}>{content.gallery.public.header.title}</div> */}
    </div>
  );
};
export default PublicGalleryHeader;
