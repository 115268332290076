import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl } from "env";

export type AuthRequestType = {
  key?: string | null;
  login: string;
  password: string;
};

export type AuthResponseType = {
  access_token: string;
  refresh_token: string;
};

export async function login(loginRequest: AuthRequestType): Promise<AuthResponseType> {
  return axios
    .post(`${baseUrl}auth/login`, loginRequest)
    .then((response) => {
      // localStorage.clear();
      if (response.data?.access_token && response.data?.refresh_token) {
        return {
          access_token: response.data?.access_token,
          refresh_token: response.data?.refresh_token,
        };
      }
    })
    .catch((error: any) => {
      console.log("Ошибка в методе ==> login:", error.response);
      return error.response;
    });
}

export async function logout() {
  return axios
    .post(`${baseUrl}auth/logout`, {}, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then(() => {
      Cookies.remove("refresh_token");
      Cookies.remove("access_token");
    })
    .catch((error: any) => {
      console.log("Ошибка в методе ==> logout:", error.response);
      return error.response;
    });
}

export async function refreshToken(refreshRequest: AuthResponseType): Promise<{
  refresh_token: string;
  access_token: string;
}> {
  const { access_token, refresh_token } = refreshRequest;
  return axios
    .post(`${baseUrl}auth/refresh`, { refresh_token }, { headers: { Authorization: "Bearer " + access_token } })
    .then((response) => {
      Cookies.set("refresh_token", response.data.refresh_token);
      Cookies.set("access_token", response.data.access_token);
    })
    .catch((error: any) => {
      console.log("Ошибка в методе ==> refreshToken:", error.response);
      logout();
      window.location.reload();
      return error.response;
    });
}
