import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import styles from "./FiltersSettings.module.scss";
import { deleteDealFilterById, getDealFilters, IDealFilter } from "services/DealFilters";
import { Popconfirm } from "antd";
import { useMutation, useQuery } from "react-query";

type Props = {
  item: IDealFilter;
  setSelectedFilter: (item: IDealFilter | undefined) => void;
  selectedFilter?: IDealFilter;
};

const FilterItem = ({ item, setSelectedFilter, selectedFilter }: Props) => {
  const { data: dealFiltersData, refetch: refetchDealFilters } = useQuery({
    queryFn: () => getDealFilters(),
    queryKey: ["dealFilters"],
  });

  const { mutateAsync: onDeleteDealFilter } = useMutation({
    mutationFn: deleteDealFilterById,
    onSuccess: () => {
      refetchDealFilters();
      if (selectedFilter?.id === item.id) {
        setSelectedFilter(undefined);
      }
    },
  });
  return (
    <ButtonCustom className={styles.filtersList__item} isTransparent onClick={() => setSelectedFilter(item)}>
      <div className={styles.filtersList__item_block} style={{ borderColor: selectedFilter?.id === item.id ? "#ff8900" : "#acb7c5" }}>
        <span className={styles.filtersList__item_name}>{item.name}</span>
        <Popconfirm
          title="Удаление фильтра"
          description="Вы точно хотите удалить фильтр?"
          okText="Да"
          cancelText="Отмена"
          okButtonProps={{ style: { width: "40%" } }}
          cancelButtonProps={{ style: { width: "40%" } }}
          onConfirm={() => onDeleteDealFilter(item.id ?? "")}
        >
          <ButtonCustom
            onClick={(e) => {
              e.stopPropagation();
            }}
            isTransparent
            maxWidth="30px"
            className={styles.filtersList__item_delete_btn}
          >
            <DeleteIcon className={styles.filtersList__item_delete_btn_icon} />
          </ButtonCustom>
        </Popconfirm>
      </div>
    </ButtonCustom>
  );
};

export default FilterItem;
