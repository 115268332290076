import { useEffect, useState } from "react";
import styles from "./QrModal.module.scss";
import { IPhoneConnection, IQrData, getPhoneAuthQr, getPhoneConnection } from "services/Shops";
import { useQuery } from "react-query";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import Loader from "components/ui/Loader/Loader";

type Props = { connection_id: string; closeModal: () => void };

const QrModal = ({ connection_id, closeModal }: Props) => {
  const [data, setData] = useState<IQrData>();
  const [phoneConnection, setPhoneConnection] = useState<IPhoneConnection>();

  const { data: qrData, isLoading: isQrDataLoading } = useQuery({
    queryFn: () => getPhoneAuthQr(connection_id),
    onSuccess: (data) => {
      setData(data.data);
    },
    queryKey: ["qr-for-qr-modal", connection_id],
  });

  const {
    data: phoneConnectionsData,
    refetch,
    isLoading,
  } = useQuery({
    queryFn: () => getPhoneConnection(connection_id),
    queryKey: ["phoneConnection-for-qr-modal", connection_id],
    onSuccess: (data) => {
      setPhoneConnection(data.data);
    },
  });

  useEffect(() => {
    if (phoneConnection?.state === "connected") {
      closeModal();
    }
  }, [closeModal, phoneConnection]);

  if (!data || isLoading || isQrDataLoading) {
    return <Loader />;
  }

  return (
    <div>
      <img src={`data:image/png;base64,${data.auth_qr_code_base64}`} alt="QR" width="100%" />
      <p>Для привязки номера телефона к рассылке в Whatsapp необходимо выполнить следующие действия:</p>
      <ol className={styles.QrModal__list}>
        <li>Откройте WhatsApp</li>
        <li>Нажмите на “Связанные устройства”</li>
        <li>Нажмите на кнопку “Привязка устройства”</li>
        <li>Наведите камеру смартфона на QR-код расположенный выше</li>
        <li>После того, как вы выполнили привязку нажмите на кнопку “Обновить”</li>
      </ol>
      {phoneConnection?.state && phoneConnection.state !== "connected" && (
        <span className={styles.QrModal__error}>Привязка не выполнена, повторите попытку</span>
      )}
      {phoneConnection?.state && phoneConnection.state === "connected" && (
        <span className={styles.QrModal__success}>Привязка успешно выполнена</span>
      )}
      <div className={styles.QrModal__footer_btns}>
        <ButtonCustom className={styles.QrModal__footer_btn} onClick={closeModal} maxWidth="100px" bgColor="gray">
          <span>Отмена</span>
        </ButtonCustom>
        <ButtonCustom className={styles.QrModal__footer_btn} onClick={() => refetch()} maxWidth="100px" bgColor="blue" disabled={false}>
          <span>Обновить</span>
        </ButtonCustom>
      </div>
    </div>
  );
};

export default QrModal;
