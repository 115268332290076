import { IRequestStuff } from "services/User";
import { onlyAdmin, onlyLPR, Role } from "settings/content";

export const generatePassword = ({ data, setPassword }: { data: IRequestStuff; setPassword: (value: string) => void }): void => {
  const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const symbols = [...onlyLPR, ...onlyAdmin].includes(data.role as Role) ? "!@#$%&*" : "";
  const passwordLength = [...onlyLPR, ...onlyAdmin].includes(data.role as Role) ? 6 : 4;

  let password = "";

  // Генерируем первую букву
  password += letters.charAt(Math.floor(Math.random() * letters.length));

  for (let i = 0; i < 4; i++) {
    password += letters.charAt(Math.floor(Math.random() * letters.length));
  }
  for (let i = 0; i < 4; i++) {
    password += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }
  for (let i = 0; i < 4; i++) {
    password += symbols.charAt(Math.floor(Math.random() * symbols.length));
  }

  const minusValue = [...onlyLPR, ...onlyAdmin].includes(data.role as Role) ? 3 : 2;

  // Тасуем символы в пароле
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("")
    .substring(0, passwordLength - minusValue);

  const generateRandomCharacter = (characters: string) => {
    return characters.charAt(Math.floor(Math.random() * characters.length));
  };

  setPassword(
    [...onlyLPR, ...onlyAdmin].includes(data.role as Role)
      ? `${password}${generateRandomCharacter(symbols)}e${generateRandomCharacter(numbers)}${generateRandomCharacter(letters)}`
      : `${password}${generateRandomCharacter(numbers)}${generateRandomCharacter(letters)}`,
  );
};
