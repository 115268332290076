import { useState } from "react";
import GalleryCardDetailed from "pages/Gallery/GalleryCardDetailed/GalleryCardDetailed";
import { useLocation } from "react-router-dom";
import styles from "./Iframe.module.scss";

type Props = {};

const PanoramaIframe = (props: Props) => {
  const [isPanoramaError, setIsPanoramaError] = useState(false);
  const location = useLocation();
  const projectId = location.pathname.split("/")[2];

  return (
    <div className={styles.PanoramaIframe__container}>
      {projectId ? (
        <GalleryCardDetailed
          selectedProjectId={projectId ?? ""}
          isErrorHandler={setIsPanoramaError}
          isPublicGallery
          isOpenedFromProjectView
          isOpenedFromIframe
        />
      ) : null}
    </div>
  );
};

export default PanoramaIframe;
