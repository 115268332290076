import React, { createContext, ReactElement, useContext } from "react";
import { notification } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";

interface NotificationContextType {
  api: NotificationInstance;
  contextHolder: ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification({ maxCount: 10000 });

  return <NotificationContext.Provider value={{ api, contextHolder }}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotificationContext must be used within a NotificationProvider");
  }
  return context;
};
