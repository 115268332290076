import content, { onlyAdmin, onlyLPR } from "settings/content";
import styles from "./GalleryFilters.module.scss";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { useQuery } from "react-query";
import { getProjectFilters, getPublicSource } from "services/Source";
import React, { MutableRefObject, useEffect, useState } from "react";
import { Dropdown, Form } from "antd";
import { ISourceSelectKeys } from "pages/Gallery/Gallery";
import { Link, useParams } from "react-router-dom";
import Loader from "components/ui/Loader/Loader";
import ComponentError from "components/ui/ComponentError/ComponentError";
import { getProjects, getProjectStatuses, getPublicProjects, IProject, IProjectStatusObj } from "services/Projects";
import ValidateComponentByRole from "components/ValidateComponentByRole/ValidateComponentByRole";
import { useUser } from "context/useUser";
import { useShops } from "context/useShops";
import { useCities } from "context/useCities";
import SearchBar from "components/SearchBar/SearchBar";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { useClickAway, useDebounce } from "@uidotdev/usehooks";
import { useSources } from "context/useSources";

interface ISourceSelect {
  value: string;
  label: string;
}

interface IProps {
  setSearchQuery: (select_name: ISourceSelectKeys, value: string) => void;
  updateProjects: () => void;
  isPublicGallery?: boolean;
  isOnlyMyProjects: boolean;
  isOpenedFromDeal?: boolean;
}

const GalleryFilters: React.FC<IProps> = ({
  setSearchQuery,
  updateProjects,
  isPublicGallery = false,
  isOnlyMyProjects,
  isOpenedFromDeal,
}) => {
  const { users, user } = useUser();
  const { shops } = useShops();
  const { cities } = useCities();
  const { room_types } = useSources();

  const [form] = Form.useForm();
  const { id }: any = useParams();

  const [selectedRoomTypes, setSelectedRoomTypes] = useState<string | undefined>();
  const [selectedManufacturer, setSelectedManufacturer] = useState<string | undefined>();
  const [selectedCollections, setSelectedCollections] = useState<string | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();
  const [selectedUser, setSelectedUser] = useState<string | undefined>();
  const [selectedShopId, setSelectedShopId] = useState<string | undefined>();
  const [selectedCity, setSelectedCity] = useState<string | undefined>();
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [searchProjects, setSearchProjects] = useState<IProject[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleResetSelect = ({
    setState,
    key,
  }: {
    setState: React.Dispatch<React.SetStateAction<string | undefined>>;
    key: ISourceSelectKeys;
  }) => {
    setState(undefined);
    handleSelectChange(setState, "", key);
  };

  const [collections, setCollections] = useState<ISourceSelect[]>([]);
  const {
    data: sourceCollections,
    isLoading: collectionsLoading,
    error: collectionsError,
  } = useQuery({
    queryFn: () =>
      isPublicGallery
        ? getPublicSource(`collection?manufacturer_id=${selectedManufacturer || ""}`, id ?? "")
        : getProjectFilters(`collection?manufacturer_id=${selectedManufacturer || ""}`),
    queryKey: ["sourceCollections", selectedManufacturer || ""],
  });

  const [manufacturers, setManufacturers] = useState<ISourceSelect[]>([]);
  const {
    data: sourceManufacturers,
    isLoading: manufacturersLoading,
    error: manufacturersError,
  } = useQuery({
    queryFn: () => (isPublicGallery ? getPublicSource("manufacturer", id ?? "") : getProjectFilters("manufacturer")),
    queryKey: ["sourceManufacturers"],
  });

  const [statuses, setStatuses] = useState<IProjectStatusObj[]>([]);

  const { data: statusesData } = useQuery({
    queryFn: getProjectStatuses,
    queryKey: ["projectStatuses"],
    enabled: !isPublicGallery,
  });

  const {
    data: projectsSearchData,
    refetch: refetchProjectsSearch,
    isLoading: isSearchProjectsLoading,
  } = useQuery({
    queryFn: () =>
      isPublicGallery
        ? getPublicProjects({ search_query: debouncedSearch, organization_id: id })
        : getProjects({ search_query: debouncedSearch }),
    queryKey: ["projectsSearchByNumber"],
    enabled: !isPublicGallery && debouncedSearch !== "",
    onSuccess: (data) => {
      if (data.data) {
        setSearchProjects(data.data.items);
      }
    },
  });

  const handleSelectChange = (
    setState: React.Dispatch<React.SetStateAction<string | undefined>>,
    value: string | undefined,
    select_name: ISourceSelectKeys,
  ) => {
    if (select_name !== ISourceSelectKeys.city_id) {
      setSearchQuery(select_name, value ?? "");
    }

    if (value === "" || value?.includes(",")) {
      setState(undefined);
    }
    /// проверка на то передается список айдишников или один
    if (value !== "" && !value?.includes(",")) {
      setState(value);
    }
  };

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData.data);
    }
  }, [statusesData]);

  useEffect(() => {
    if (!sourceCollections) {
      return;
    }

    const convertDataForSelect = sourceCollections.data.map((item) => {
      return {
        value: item.id,
        label: item.value,
      };
    });

    setCollections(convertDataForSelect);
  }, [sourceCollections]);

  useEffect(() => {
    if (!sourceManufacturers) {
      return;
    }

    const convertDataForSelect = sourceManufacturers.data.map((item) => ({
      value: item.id,
      label: item.value,
    }));

    setManufacturers(convertDataForSelect);
  }, [sourceManufacturers]);

  useEffect(() => {
    let formedShopsIdsListString = "";

    if (selectedCity) {
      formedShopsIdsListString = shops
        .filter((shop) => shop.city_id === selectedCity)
        .map((item) => item.id)
        .join(",");
    }

    handleSelectChange(setSelectedShopId, formedShopsIdsListString, ISourceSelectKeys.shop_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity]);

  useEffect(() => {
    handleSelectChange(setSelectedCollections, undefined, ISourceSelectKeys.collection);
  }, [selectedManufacturer]);

  useEffect(() => {
    if (debouncedSearch.length === 0) {
      return;
    }
    refetchProjectsSearch();
  }, [debouncedSearch]);

  useEffect(() => {
    if (isOnlyMyProjects) {
      handleSelectChange(setSelectedUser, user.id, ISourceSelectKeys.user_id);
    } else {
      handleSelectChange(setSelectedUser, undefined, ISourceSelectKeys.user_id);
    }
    updateProjects();
  }, [isOnlyMyProjects]);

  const handleUpdateProjects = () => {
    localStorage.setItem("gallery_pagination_page", "0");
    updateProjects();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setSearchProjects([]);
    }
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  };

  const resetSelects = () => {
    handleResetSelect({ setState: setSelectedRoomTypes, key: ISourceSelectKeys.room_type });
    handleResetSelect({ setState: setSelectedCollections, key: ISourceSelectKeys.collection });
    handleResetSelect({ setState: setSelectedStatus, key: ISourceSelectKeys.status });
    handleResetSelect({ setState: setSelectedShopId, key: ISourceSelectKeys.shop_id });
    handleResetSelect({ setState: setSelectedCity, key: ISourceSelectKeys.city_id });
    handleResetSelect({ setState: setSelectedManufacturer, key: ISourceSelectKeys.manufacturer });
    handleResetSelect({ setState: setSelectedUser, key: ISourceSelectKeys.selected_user_id });
    localStorage.setItem("gallery_pagination_page", "0");
    updateProjects();
  };

  const ref: MutableRefObject<HTMLDivElement> = useClickAway((e) => {
    if (e.target instanceof HTMLElement && e.target.className.includes("ignore-click-away")) {
      setTimeout(() => {
        setIsDropdownOpen(false);
      }, 200);
    } else {
      setIsDropdownOpen(false);
    }
  });

  if ((collectionsLoading || manufacturersLoading) && !selectedManufacturer) {
    return <Loader />;
  }

  if (collectionsError || manufacturersError) {
    return <ComponentError />;
  }

  return (
    <div className={styles.gallery_filters}>
      <div className={styles.gallery_filters__selects_container}>
        <Form form={form} className={styles.gallery_filters__selects_container__types}>
          {!isPublicGallery && (
            <ValidateComponentByRole requiredRoles={[...onlyLPR, ...onlyAdmin]}>
              <Form.Item key={"status-select-item"} className={styles.gallery_filters__select}>
                <SelectCustom
                  value={selectedStatus}
                  key={"status-select"}
                  placeholder={"Статус"}
                  onChange={(value) => handleSelectChange(setSelectedStatus, value, ISourceSelectKeys.status)}
                  onDeselect={() => updateProjects()}
                  options={statuses.map((item) => ({ value: item.value, label: item.name }))}
                  className={"custom_select_orange"}
                  allowClear
                />
              </Form.Item>
            </ValidateComponentByRole>
          )}
          <Form.Item key={"brand-select-item"} className={styles.gallery_filters__select}>
            <SelectCustom
              value={selectedManufacturer}
              key="brand-select"
              placeholder="Бренд"
              onChange={(value) => handleSelectChange(setSelectedManufacturer, value, ISourceSelectKeys.manufacturer)}
              onDeselect={() => updateProjects()}
              options={manufacturers}
              className={"custom_select_orange"}
              showSearch
              allowClear
            />
          </Form.Item>
          <Form.Item key={"collection-select-item"} className={styles.gallery_filters__select}>
            <SelectCustom
              disabled={collectionsLoading}
              value={selectedCollections}
              key={"collection-select"}
              placeholder={"Коллекция"}
              onChange={(value) => handleSelectChange(setSelectedCollections, value, ISourceSelectKeys.collection)}
              onDeselect={() => updateProjects()}
              options={collections}
              className={"custom_select_orange"}
              showSearch
              // mode="multiple"
              allowClear
            />
          </Form.Item>
          <Form.Item key={"roomtype-select-item"} className={styles.gallery_filters__select}>
            <SelectCustom
              value={selectedRoomTypes}
              key={"roomtype-select"}
              disabled={false}
              // mode={"multiple"}
              placeholder={"Тип помещения"}
              onChange={(value) => handleSelectChange(setSelectedRoomTypes, value, ISourceSelectKeys.room_type)}
              onDeselect={() => updateProjects()}
              options={room_types.map((item) => ({ value: item.value, label: item.name }))}
              showSearch
              className={"custom_select_orange"}
              allowClear
            />
          </Form.Item>
          {!isPublicGallery && (
            <Form.Item key={"city-select-item"} className={styles.gallery_filters__select}>
              <SelectCustom
                key={"city-select"}
                placeholder={"Город"}
                onChange={(value) => handleSelectChange(setSelectedCity, value, ISourceSelectKeys.city_id)}
                onDeselect={() => updateProjects()}
                options={cities
                  .filter((city) => shops.some((shop) => shop.city_id === city.id))
                  .map((item) => ({ value: item.id, label: item.name }))}
                className={"custom_select_orange"}
                value={selectedCity}
                allowClear
                showSearch
              />
            </Form.Item>
          )}
          {!isPublicGallery && (
            <Form.Item key={"user-select-item"} className={styles.gallery_filters__select} hidden={isOnlyMyProjects}>
              <SelectCustom
                key={"user-select"}
                placeholder={"Сотрудник"}
                onChange={(value) => handleSelectChange(setSelectedUser, value, ISourceSelectKeys.selected_user_id)}
                onDeselect={() => updateProjects()}
                options={users.map((item) => ({ value: item.id, label: item.name }))}
                className={"custom_select_orange"}
                value={selectedUser}
                allowClear
                showSearch
                filterOption={(input: any, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </Form.Item>
          )}
          {!isPublicGallery && (
            <Form.Item key={"shops-select-item"} className={styles.gallery_filters__select}>
              <SelectCustom
                disabled={!!selectedCity}
                key={"shops-select"}
                placeholder={"Магазин"}
                onChange={(value) => handleSelectChange(setSelectedShopId, value, ISourceSelectKeys.shop_id)}
                onDeselect={() => updateProjects()}
                options={shops.map((item) => ({ value: item.id, label: item.name }))}
                className={"custom_select_orange"}
                value={!selectedCity ? selectedShopId : undefined}
                allowClear
                showSearch
              />
            </Form.Item>
          )}
        </Form>
      </div>
      <div className={styles.gallery_filters__search}>
        <ButtonCustom
          onClick={() => handleUpdateProjects()}
          className={styles.gallery_filters__custom_button}
          key={content.gallery.settings.search.id}
          children={<span>{content.gallery.settings.search.text}</span>}
          bgColor="darkGrey"
          maxWidth="100px"
        />
        <ButtonCustom
          onClick={() => resetSelects()}
          className={styles.gallery_filters__custom_button}
          key={content.gallery.settings.search.id + "btn"}
          children={<span>{content.gallery.settings.search.resetText}</span>}
          bgColor="darkGrey"
          maxWidth="100px"
        />
      </div>
      {!isOnlyMyProjects && (
        <div ref={ref} className={styles.gallery_filters__search_dropdown}>
          <Dropdown
            menu={{
              items: isSearchProjectsLoading
                ? [{ key: "loading", label: <Loader /> }]
                : searchProjects.map((item) => ({
                    key: item.id,
                    label: (
                      <Link
                        to={!isPublicGallery ? `/?project_id=${item.id}` : `/public-gallery/${id}?project_id=${item.id}`}
                        className="ignore-click-away"
                      >
                        <div className={styles.gallery_search__name}>{item.name}</div>
                        <div className={styles.gallery_search__number}>
                          <span style={{ fontWeight: "600" }}>№</span> {item.number}
                        </div>
                      </Link>
                    ),
                  })),
              style: { maxHeight: "200px", overflowY: "scroll", width: "300px" },
            }}
            open={search.length > 0 && searchProjects.length > 0 && isDropdownOpen}
          >
            <SearchBar
              className={styles.gallery_filters__search_bar}
              placeholder={"Поиск проекта"}
              suffix={<SearchIcon />}
              onChange={handleChange}
              value={search}
              onFocus={() => setIsDropdownOpen(true)}
            />
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default GalleryFilters;
