import { useState } from "react";
import ModalCustom from "components/ModalCustom/ModalCustom";
import { useMutation, useQuery } from "react-query";
import { ILead, editLead, editLeadStatus, getLeadById } from "services/Leads";
import { checkAuthenticated } from "helpers/checkAuth";
import styles from "./EditLead.module.scss";
import Loader from "components/ui/Loader/Loader";
import dayjs from "dayjs";
import { IUser, useUser } from "context/useUser";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import { LeadStatuses, firstStagesId, statuses } from "pages/LeadsPage/constants";
import { useLeads } from "context/useLeads";
import InputCustom from "components/ui/InputCustom/InputCustom";
import { useClients } from "context/useClients";
import SelectClient from "components/SelectClient/SelectClient";
import { IClient } from "services/Clients";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import LeadHistory from "pages/LeadsPage/components/LeadHistoryStatuses/LeadHistoryStatuses";
import ClientChange from "./UserChange";
import { onlyAdmin, onlyEmployee, onlyLPR, Role } from "settings/content";
import ValidateComponentByRole from "components/ValidateComponentByRole/ValidateComponentByRole";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { IReminderResponse } from "services/Reminders";
import DealCardReminder from "pages/DealsPage/components/DealCardDetail/DealCardReminder/DealCardReminder";

type Props = {
  leadId: string;
  handleCloseModal: () => void;
  remindersData?: IReminderResponse;
  refetchReminders: () => void;
};

export const isLeadStatusDisabled = ({
  currentStatus,
  targetStatus,
  user,
}: {
  currentStatus: LeadStatuses | undefined;
  targetStatus: LeadStatuses;
  user: IUser;
}) => {
  if (targetStatus === LeadStatuses.NEW) {
    return true;
  }
  const isAdminOrLpr = [...onlyLPR, ...onlyAdmin].includes(user.role as Role);
  const isClosedLead = !firstStagesId.includes(currentStatus as LeadStatuses);
  if (isAdminOrLpr) {
    return false;
  }
  if (isClosedLead && !isAdminOrLpr) {
    return true;
  }
  const currentStatusIndex = statuses.findIndex((status) => status.id === currentStatus);
  const targetStatusIndex = statuses.findIndex((status) => status.id === targetStatus);

  return currentStatusIndex > targetStatusIndex;
};

const EditLead = ({ leadId, handleCloseModal, remindersData, refetchReminders }: Props) => {
  const { users, user } = useUser();
  const { clients } = useClients();
  const { refetchLeads, leadChannelsData, leadPromosData } = useLeads();
  const authenticated = checkAuthenticated();

  const [leadData, setLeadData] = useState<ILead>();
  const [isClientChangeOpen, setIsClientChangeOpen] = useState(false);
  const [isReminderOpen, setIsReminderOpen] = useState(false);

  const { mutateAsync: onEditLeadStatus } = useMutation({
    mutationFn: editLeadStatus,
    onSuccess: () => {
      refetchLeads();
    },
  });

  const { mutateAsync: onEditLead } = useMutation({
    mutationFn: editLead,
    onSuccess: () => {
      refetchLeads();
    },
  });

  const {
    data,
    isLoading,
    isError,
    refetch: refetchLead,
  } = useQuery({
    queryFn: () => getLeadById({ id: leadId }),
    queryKey: [`lead_${leadId}`],
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      setLeadData(data.data);
    },
    enabled: authenticated && leadId !== "",
  });

  const getData = (name: keyof ILead) => {
    if (!leadData) {
      return;
    }
    const value = leadData[name];
    if (!value) {
      return;
    }
    return value;
  };

  const getLeadCreator = () => {
    return users.find((user) => user.id === leadData?.responsible_user_id);
  };

  const getClient = (clientId: string | undefined) => {
    if (!clientId || clientId === "") {
      return;
    }
    const client = clients.find((item) => item.id === clientId);
    return client;
  };

  const isEmptyValue = ({ name }: { name: keyof ILead }) => {
    if (!getData(name)) {
      return true;
    }
    if (getData(name) === "") {
      return true;
    }
    return false;
  };

  const changeClient = (value: IClient | undefined) => {
    if (!value) {
      return;
    }
    setLeadData({ ...leadData, client_id: value.id } as ILead);
  };

  const onSubmit = () => {
    if (!leadData) {
      return;
    }
    onEditLead({
      id: leadId,
      channel_id: leadData.channel_id,
      client_id: leadData.client_id,
      promo_id: leadData.promo_id !== "" ? leadData.promo_id : null,
      title: leadData.title,
    }).then((response) => {
      if (response.status === 200) {
        handleCloseModal();
      }
    });
  };

  const handleReminderClose = () => {
    setIsReminderOpen(false);
  };

  const isLeadFinished = () => {
    if (!leadData?.status) {
      return;
    }
    if (!firstStagesId.includes(leadData?.status)) {
      return true;
    }
  };

  const isSubmitDisabled = () => {
    if (!leadData?.status || isLeadFinished()) {
      return true;
    }
    return false;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ModalCustom
        isOpen={leadId !== ""}
        title={data?.data?.title ?? ""}
        handleCloseModal={handleCloseModal}
        centered
        width={600}
        footer={null}
        onCancel={handleCloseModal}
      >
        <div className={styles.EditLead}>
          <div className={styles.EditLead__descr}>
            {dayjs(leadData?.created_at).format("DD.MM.YYYY")}{" "}
            <span
              className={styles.EditLead__client}
              onClick={() => {
                if (onlyEmployee.includes(user.role as Role)) {
                  return;
                }
                setIsClientChangeOpen(true);
              }}
              style={{ cursor: onlyEmployee.includes(user.role as Role) ? "not-allowed" : "pointer" }}
            >
              {getLeadCreator()?.name ?? (getLeadCreator() ? "Ответственный без имени" : "Ответственный отсутствует")}
            </span>
          </div>
          <div className={styles.EditLead__status}>
            <span className={styles.EditLead__label}>Статус лида</span>
            <SelectCustom
              options={statuses.map((item) => ({
                value: item.id,
                label: item.name,
                disabled: isLeadStatusDisabled({ currentStatus: leadData?.status, targetStatus: item.id, user: user }),
              }))}
              onChange={(value) =>
                onEditLeadStatus({ id: leadId, status: value }).then((response) => {
                  if (response.status === 200) {
                    setLeadData((prev) => ({ ...prev, status: value }) as ILead);
                    refetchLead();
                  }
                })
              }
              className={styles.createLead__select}
              placeholder="Статус лида"
              value={leadData?.status}
              width="fit-content"
              minWidth="150px"
              disabled={isLeadFinished() && ![...onlyLPR, ...onlyAdmin].includes(user.role as Role)}
            />
          </div>
          <div className={styles.EditLead__inputs}>
            <div className={styles.EditLead__input_block}>
              <span className={styles.EditLead__label}>Клиент:</span>
              <SelectClient
                setSelectedClient={changeClient}
                selectedClient={getClient(leadData?.client_id ?? "")}
                disabled={isLeadFinished()}
              />
            </div>

            <div className={styles.EditLead__input_block}>
              <span className={styles.EditLead__label}>Название лида:</span>
              <InputCustom
                className={styles.createLead__input}
                placeholder={"Название лида"}
                name={"title"}
                value={getData("title")}
                onChange={(e) => setLeadData((prev) => ({ ...prev, title: e.target.value }) as ILead)}
                isError={isEmptyValue({ name: "title" })}
                disabled={isLeadFinished()}
              />
            </div>
            <div className={styles.EditLead__input_block}>
              <span className={styles.EditLead__label}>Промо:</span>
              <SelectCustom
                options={leadPromosData.map((item) => ({ value: item.id, label: item.name }))}
                onChange={(value) => setLeadData((prev) => ({ ...prev, promo_id: value }) as ILead)}
                className={styles.createLead__select}
                placeholder="Промо"
                value={leadPromosData.find((item) => item.id === leadData?.promo_id)?.id}
                width="200px"
                disabled={isLeadFinished()}
              />
            </div>
            <div className={styles.EditLead__input_block}>
              <span className={styles.EditLead__label}>Канал:</span>
              <SelectCustom
                options={leadChannelsData.map((item) => ({ value: item.id, label: item.name }))}
                onChange={(value) => setLeadData((prev) => ({ ...prev, channel_id: value }) as ILead)}
                className={styles.createLead__select}
                placeholder="Канал"
                value={leadChannelsData.find((item) => item.id === leadData?.channel_id)?.id}
                width="200px"
                disabled={isLeadFinished()}
              />
            </div>
            <ValidateComponentByRole requiredRoles={onlyEmployee}>
              <ButtonCustom
                isTransparent
                className={styles.EditLead__reminder_button}
                maxWidth="40px"
                onClick={() => setIsReminderOpen(true)}
              >
                <CalendarIcon />
              </ButtonCustom>
              {remindersData && (
                <DealCardReminder
                  lead_id={leadId}
                  isModalOpen={isReminderOpen}
                  handleCloseReminer={handleReminderClose}
                  remindersData={remindersData}
                  refetchReminders={() => refetchReminders()}
                />
              )}
            </ValidateComponentByRole>
          </div>
          <div>
            <LeadHistory id={leadId} remindersData={remindersData?.items} updatingHistoryTrigger={leadData?.status} />
          </div>
          <div className={styles.EditLead__submitBlock}>
            <ButtonCustom maxWidth="250px" className={styles.EditLead__submit} onClick={() => {}} bgColor="orange" disabled>
              <span>Создать сделку</span>
            </ButtonCustom>
            <ButtonCustom maxWidth="250px" className={styles.EditLead__submit} onClick={onSubmit} disabled={isSubmitDisabled()}>
              <span>Сохранить лид</span>
            </ButtonCustom>
          </div>
        </div>
      </ModalCustom>
      <ClientChange
        leadId={leadId}
        user_id={leadData?.responsible_user_id}
        department_id={leadData?.department_id}
        shop_id={leadData?.shop_id}
        isOpen={isClientChangeOpen}
        handleCloseModal={() => setIsClientChangeOpen(false)}
      />
    </>
  );
};

export default EditLead;
