import { IStuffUser } from "services/User";

export function compareStuffForSorting(stuffA: IStuffUser, stuffB: IStuffUser): number {
  // Получаем shop_id и department_id обоих объектов
  const shopIdA = stuffA.shop_id ?? "";
  const shopIdB = stuffB.shop_id ?? "";

  const departmentIdA = stuffA.department_id ?? "";
  const departmentIdB = stuffB.department_id ?? "";

  // Получаем имя обоих объектов
  const nameA = stuffA.name ?? "";
  const nameB = stuffB.name ?? "";

  // Проверяем, имеют ли оба объекта shop_id и department_id
  const hasShopAndDepartmentA = shopIdA && departmentIdA;
  const hasShopAndDepartmentB = shopIdB && departmentIdB;

  // Если оба объекта имеют shop_id и department_id, они сравниваются по ним
  if (hasShopAndDepartmentA && hasShopAndDepartmentB) {
    // Сравниваем shop_id
    const shopComparison = shopIdA.localeCompare(shopIdB, undefined, { sensitivity: "base" });
    if (shopComparison !== 0) {
      return shopComparison;
    }

    // Если shop_id равен, сравниваем department_id
    const departmentComparison = departmentIdA.localeCompare(departmentIdB, undefined, { sensitivity: "base" });
    if (departmentComparison !== 0) {
      return departmentComparison;
    }
  }

  // Если только один объект имеет shop_id и department_id, он сортируется выше
  if (hasShopAndDepartmentA && !hasShopAndDepartmentB) {
    return -1;
  }
  if (hasShopAndDepartmentB && !hasShopAndDepartmentA) {
    return 1;
  }

  // Если ни один из объектов не имеет shop_id и department_id, они сравниваются по имени
  return nameA.localeCompare(nameB, undefined, { sensitivity: "base" });
}
