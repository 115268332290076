import styles from "./PublicGallery.module.scss";
import Gallery from "pages/Gallery/Gallery";
import PublicGalleryHeader from "./PublicGalleryHeader/PublicGalleryHeader";

type Props = {
  match: {
    url: string;
    params: {
      id: string;
    };
  };
};

const PublicGallery = ({ match }: Props) => {
  return (
    <div className={styles.publicGallery}>
      <PublicGalleryHeader url={match.url} id={match.params.id} />
      <div className={styles.publicGallery__block}>
        <Gallery isPublicGallery />
      </div>
    </div>
  );
};

export default PublicGallery;
