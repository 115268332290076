import React, { useEffect, useState } from "react";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { useDebounce } from "@uidotdev/usehooks";
import styles from "./LeadsPage.module.scss";
import SearchBar from "components/SearchBar/SearchBar";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import Loader from "components/ui/Loader/Loader";
import { onlyEmployee, Role } from "settings/content";
import "./LeadsPage_override.scss";
import { NoticeType } from "antd/es/message/interface";
import { message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import ComponentError from "components/ui/ComponentError/ComponentError";
import { statuses } from "./constants";
import { ILead, editLeadStatus, getRemindersByLeadId } from "services/Leads";
import CreateLead from "./CreateLead/CreateLead";
import { useLeads } from "context/useLeads";
import EditLead from "./EditLead/EditLead";
import { useMutation, useQuery } from "react-query";
import { checkAuthenticated } from "helpers/checkAuth";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import { SortOptionsList, sortOptions } from "pages/DealsPage/sortOptions";
import { sortLeadsByCurrentOption } from "./sortLeadsByCurrentOption";
import { useUser } from "context/useUser";
import ValidateComponentByRole from "components/ValidateComponentByRole/ValidateComponentByRole";
import KanbadBoardContainer from "components/KanbanBoard/KanbadBoardContainer";
import { ColumnType } from "components/KanbanBoard/DragDropProvider";
import PageTitle from "components/ui/PageTitle/PageTitle";
const LeadsPage: React.FC = () => {
  const { user } = useUser();
  const authenticated = checkAuthenticated();
  const { leadsData, isLeadsError, isLeadsLoading, setSearchValue, refetchLeads } = useLeads();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leadId = queryParams.get("lead_id");
  const history = useHistory();

  const [kanbanData, setKanbanData] = useState<ColumnType[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [search, setSearch] = useState<string>("");
  const [isOnlyMyLeads, setIsOnlyMyLeads] = useState<boolean>(false);
  const [isOnlyNotAssignedLeads, setIsOnlyNotAssignedLeads] = useState<boolean>(false);
  const [isKanbanDragAndDropDisabled, setIsKanbanDragAndDropDisabled] = useState<boolean>(false);

  const [isModalCreateOpen, setIsModalCreateOpen] = useState<boolean>(false);
  const [currentSortOption, setCurrentSortOption] = useState<SortOptionsList | undefined>(SortOptionsList.newFirst);

  const { data: leadRemindersData, refetch: refetchLeadReminders } = useQuery({
    queryFn: () => getRemindersByLeadId(leadId ?? ""),
    queryKey: [`lead_reminder_${leadId}`],
    enabled: authenticated && !!leadId && leadId !== "",
  });

  const { mutateAsync: onEditLeadStatus } = useMutation({
    mutationFn: editLeadStatus,
    onSuccess: () => {
      // refetchLeads();
      setTimeout(() => {
        setIsKanbanDragAndDropDisabled(false);
      }, 200);
    },
  });

  const toggleModalCreate = (isOpen: boolean) => {
    setIsModalCreateOpen(isOpen);
  };

  const openModalDetail = (id: string) => {
    history.push(`leads?lead_id=${id}`);
  };

  const closeModalDetail = () => {
    history.push(`leads`);
  };

  const debouncedSearch = useDebounce(search, 500);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleStatusChange = async ({ value, id }: { value: string; id: string }): Promise<any> => {
    setCurrentSortOption(undefined);
    setIsKanbanDragAndDropDisabled(true);
    const response = await onEditLeadStatus({ id: id, status: value });
    return response;
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const updateLeads = () => {
    const dealsForKanban = leadsData.map((group) => {
      const cards = group.leads.map((lead) => ({ ...lead, client_id: lead.client_fullname }) as ILead);
      return {
        id: statuses.find((status) => status.value === group.status)?.id ?? "",
        name: statuses.find((status) => status.value === group.status)?.name ?? group.status,
        cards: sortLeadsByCurrentOption({
          leads:
            !isOnlyMyLeads && !isOnlyNotAssignedLeads
              ? cards
              : isOnlyMyLeads && !isOnlyNotAssignedLeads
                ? cards.filter((lead) => lead.responsible_user_id === user.id)
                : !isOnlyMyLeads && isOnlyNotAssignedLeads
                  ? cards.filter((lead) => !lead.responsible_user_id)
                  : [],
          isSeeReminders: onlyEmployee.includes(user.role as Role),
          currentOption: currentSortOption,
        }),
        value: group.status,
        disabled: false,
      };
    });
    setKanbanData(dealsForKanban);
  };

  useEffect(() => {
    setSearchValue(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    if (!leadsData) {
      return;
    }
    if (!currentSortOption) {
      return;
    }
    updateLeads();
  }, [leadsData, statuses, currentSortOption]);

  useEffect(() => {
    if (!leadsData) {
      return;
    }
    updateLeads();
  }, [isOnlyMyLeads, isOnlyNotAssignedLeads, leadsData]);

  useEffect(() => {
    if (currentSortOption && authenticated) {
      refetchLeads();
    }
  }, [currentSortOption, isOnlyNotAssignedLeads, isOnlyMyLeads]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }
    refetchLeads();
  }, [isOnlyMyLeads, isOnlyNotAssignedLeads]);

  if (isLeadsLoading) {
    return <Loader />;
  }

  if (isLeadsError) {
    return <ComponentError />;
  }

  return (
    <>
      {contextHolder}
      <div className={styles.leadsPage__container}>
        <div className={styles.leadsPage__header}>
          <PageTitle title="Лиды" />
          <ValidateComponentByRole requiredRoles={onlyEmployee}>
            <ButtonCustom
              maxWidth="200px"
              className={styles.leadsPage__header_custom_button}
              onClick={() => toggleModalCreate(true)}
              bgColor="orange"
            >
              <span>{"Создать лид"}</span>
            </ButtonCustom>
          </ValidateComponentByRole>
          <SearchBar
            className={styles.leadsPage__header_search}
            placeholder={"Поиск лидов"}
            suffix={<SearchIcon />}
            onChange={handleChange}
            value={search}
          />
          <SelectCustom
            options={sortOptions}
            onChange={(value) => setCurrentSortOption(value as SortOptionsList)}
            className="dealsPage__header_sort"
            placeholder="Сортировка лидов"
            width="225px"
            value={currentSortOption}
          />
          <ValidateComponentByRole requiredRoles={onlyEmployee}>
            <ButtonCustom
              maxWidth="220px"
              onClick={() => {
                setIsOnlyNotAssignedLeads(false);
                setIsOnlyMyLeads((prev) => !prev);
              }}
              bgColor={isOnlyMyLeads ? "orange" : "gray"}
            >
              <span>{!isOnlyMyLeads ? "Показать только мои лиды" : "Показать все лиды"}</span>
            </ButtonCustom>
          </ValidateComponentByRole>
          <ValidateComponentByRole requiredRoles={onlyEmployee}>
            <ButtonCustom
              maxWidth="220px"
              onClick={() => {
                setIsOnlyMyLeads(false);
                setIsOnlyNotAssignedLeads((prev) => !prev);
              }}
              bgColor={isOnlyNotAssignedLeads ? "orange" : "gray"}
            >
              <span>{!isOnlyNotAssignedLeads ? "Показать неназначенные лиды" : "Показать все лиды"}</span>
            </ButtonCustom>
          </ValidateComponentByRole>
        </div>
        <div className={styles.leadsPage__data_container}>
          <div className={styles.leadsPage__data}>
            <KanbadBoardContainer
              data={kanbanData}
              onCardClick={openModalDetail}
              changeStatusRequest={handleStatusChange}
              isDragDisabled={isKanbanDragAndDropDisabled}
              isLeads
              invalidateKanbanBoardCache={() => {}}
            />
          </div>
        </div>
      </div>
      <CreateLead isOpen={isModalCreateOpen} handleCloseModal={() => toggleModalCreate(false)} />
      <EditLead
        leadId={leadId ?? ""}
        handleCloseModal={closeModalDetail}
        remindersData={leadRemindersData}
        refetchReminders={refetchLeadReminders}
      />
    </>
  );
};

export default LeadsPage;
