import DragDropProvider, { ColumnType } from "./DragDropProvider";
import KanbanBoard from "./KanbanBoard";
import React, { useEffect, useRef, useState } from "react";

interface KanbanBoardContainerProps {
  data: ColumnType[];
  onCardClick: (id: string) => void;
  changeStatusRequest: ({ id, value }: { id: string; value: string }) => Promise<any>;
  isDragDisabled: boolean;
  isLeads?: boolean;
  invalidateKanbanBoardCache: () => void;
}

const KanbadBoardContainer: React.FC<KanbanBoardContainerProps> = (props) => {
  const cachedDataRef = useRef("");
  const [data, setData] = useState<ColumnType[]>(props.data);

  useEffect(() => {
    const stringifedData = JSON.stringify(props.data);
    if (stringifedData !== cachedDataRef.current) {
      setData(props.data);
      cachedDataRef.current = stringifedData;
    }
  }, [props.data]);

  return (
    <DragDropProvider
      data={data}
      changeStatusRequest={props.changeStatusRequest}
      isLeads={props.isLeads}
      invalidateKanbanBoardCache={props.invalidateKanbanBoardCache}
    >
      <KanbanBoard onCardClick={props.onCardClick} isDragDisabled={props.isDragDisabled} />
    </DragDropProvider>
  );
};

export default KanbadBoardContainer;
