import React from "react";
import styles from "./ModerationStatus.module.scss";
import { ProjectStatusType, getProjectStatus } from "helpers/projectStatus";

type Props = { status: ProjectStatusType };

const ModerationStatus: React.FC<Props> = ({ status }) => {
  return (
    <span className={[styles.moderationStatus, status === "moderation_required" ? styles.moderationStatus__onModeration : ""].join(" ")}>
      {getProjectStatus(status)}
    </span>
  );
};
export default ModerationStatus;
