import React, { useEffect, useState } from "react";
import styles from "./DealCardReminder.module.scss";
import "./DealCardReminder_override.scss";
import { IReminder, IReminderResponse } from "services/Reminders";
import { Popconfirm } from "antd";
import { ReactComponent as HintIcon } from "assets/icons/hint.svg";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import content from "settings/content";

type Props = {
  remindersData: IReminderResponse | undefined;
};

const DealCardReminderList: React.FC<Props> = ({ remindersData }) => {
  const [reminders, setReminders] = useState<IReminder[]>();

  useEffect(() => {
    if (!remindersData) {
      return;
    }
    setReminders(remindersData.items);
  }, [remindersData]);

  return remindersData?.status !== 200 ? (
    <p className={styles.dealCardReminder__reminder_list_error}>{content.reminders.list_error}</p>
  ) : (
    <div
      className={`${styles.dealCardReminder__reminder_list} ${
        reminders && reminders.length > 5 ? styles.dealCardReminder__reminder_list_noscroll : ""
      }`}
    >
      <p>{content.reminders.list_title}</p>
      {!reminders?.length ? (
        <p className={styles.dealCardReminder__reminder_list_empty}>{content.reminders.list_empty}</p>
      ) : (
        reminders?.map((reminder) => {
          return (
            <div key={reminder.notify_at} className={styles.dealCardReminder__reminder_list_item}>
              <span>{reminder.notify_at.substring(0, 10)}</span>
              <Popconfirm
                placement="right"
                title={reminder.notify_at.substring(0, 10)}
                description={reminder.comment}
                showCancel={false}
                className="dealCardReminderPopConfirm"
                rootClassName="dealCardReminderPopConfirm"
              >
                <ButtonCustom className={styles.dealCardReminder__reminder_list_hint} isTransparent maxWidth="20px">
                  <HintIcon />
                </ButtonCustom>
              </Popconfirm>
            </div>
          );
        })
      )}
    </div>
  );
};
export default DealCardReminderList;
