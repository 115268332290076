import { useState } from "react";
import styles from "./SelectClient.module.scss";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import { useClients } from "context/useClients";
import { IClient } from "services/Clients";
import ClientCard from "pages/DealsPage/components/DealCardCreate/ClientCard/ClientCard";
import { Tag } from "antd";

type Props = {
  selectedClient: IClient | undefined;
  setSelectedClient: (value: IClient | undefined) => void;
  isOnlyShow?: boolean;
  disabled?: boolean;
};

const SelectClient = ({ selectedClient, setSelectedClient, isOnlyShow = false, disabled = false }: Props) => {
  const { clients, refetchClients } = useClients();

  const [isClientCardOpened, setIsClientCardOpened] = useState(false);

  const handleClientChange = (clientId: string) => {
    const findedClient = clients?.find((client) => client.id === clientId);
    if (!clientId) {
      setSelectedClient(undefined);
      return;
    }
    if (!findedClient) {
      return;
    }
    setSelectedClient(findedClient);
  };
  return (
    <div className={styles.SelectClient}>
      {isOnlyShow && (
        <Tag className={styles.SelectClient__tag} color="default">
          {selectedClient?.title}
        </Tag>
      )}

      {!isOnlyShow && (
        <SelectCustom
          options={clients.map((item) => ({
            value: item.id,
            label: `${item.title ?? ""} ${item.persons[0].phone ?? ""} ${item.persons[0].email ?? ""}`,
          }))}
          onChange={handleClientChange}
          className={styles.SelectClient__select}
          placeholder="Начните вводить для поиска клиента"
          width="100%"
          allowClear
          showSearch
          value={selectedClient?.title}
          disabled={disabled}
        />
      )}
      {selectedClient && (
        <button className={styles.SelectClient__goToClientBtn} onClick={() => setIsClientCardOpened(true)}>
          Перейти к карточке клиента
        </button>
      )}
      <ClientCard
        isOpen={isClientCardOpened}
        client={selectedClient}
        onCancel={() => setIsClientCardOpened(false)}
        refetchClient={(client) => {
          setSelectedClient(client);
          refetchClients();
        }}
      />
    </div>
  );
};

export default SelectClient;
