import { useLocation } from "react-router-dom";
import styles from "./Iframe.module.scss";
import GalleryCardItems from "pages/Gallery/GalleryCardItems/GalleryCardItems";

type Props = {};

const ProjectItemsIframe = (props: Props) => {
  const location = useLocation();
  const projectId = location.pathname.split("/")[2];

  return (
    <div className={styles.ProjectItemsIframe__container}>
      {projectId ? <GalleryCardItems selectedProjectId={projectId} isPublicGallery={true} /> : null}
    </div>
  );
};

export default ProjectItemsIframe;
