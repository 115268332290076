import { ReactComponent as NotFoundIcon } from "assets/icons/notfound.svg";
import styles from "./SelectCustom.module.scss";

type Props = {};

const NotFoundContent = (props: Props) => {
  return (
    <div className={styles.notfound__wrapper}>
      <NotFoundIcon />
      <span className={styles.notfound__text}>К сожалению, по Вашему запросу ничего не найдено.</span>
    </div>
  );
};

export default NotFoundContent;
