import styles from "./ClientForm.module.scss";
import { ICreateClient } from "services/Clients";
import InputCustom from "components/ui/InputCustom/InputCustom";
import { isEmailValid } from "helpers/validation";
import { PhoneInput } from "react-international-phone";
import content from "settings/content";
import { PHONE_COUNTRIES } from "settings/countries";
import { formatPhone } from "helpers/formatString";

type Props = {
  client: ICreateClient;
  setClient: (client: ICreateClient) => void;
  isFormAlive?: boolean;
};

const ClientForm = ({ client, setClient, isFormAlive }: Props) => {
  const defaultCountryLocalStorage = localStorage.getItem("defaultCountryIso") ?? "ru";

  const setPhone = (phone: string) => {
    setClient({
      ...client,
      persons: [
        {
          ...client.persons[0],
          phone: formatPhone(phone),
        },
      ],
    });
  };

  const setEmail = (email: string) => {
    setClient({
      ...client,
      persons: [
        {
          ...client.persons[0],
          email: email,
        },
      ],
    });
  };

  const setTitle = (title: string) => {
    setClient({
      ...client,
      title: title,
      persons: [
        {
          ...client.persons[0],
          fullname: title,
        },
      ],
    });
  };

  return (
    <div className={styles.ClientForm}>
      <span>Данные о клиенте</span>
      <PhoneInput
        defaultCountry={defaultCountryLocalStorage}
        value={client.persons[0].phone ?? ""}
        onChange={(value) => setPhone(value)}
        placeholder={content.login.form.phone.placeholder}
        countries={PHONE_COUNTRIES}
        className={styles.ClientForm__phoneInput}
        name={"phone"}
        forceDialCode
      />
      <InputCustom
        placeholder={"Email"}
        name={"email"}
        value={client.persons[0].email ?? ""}
        onChange={(e) => setEmail(e.target.value)}
        isError={!!client.persons[0].email && !isEmailValid(client.persons[0].email)}
      />
      <InputCustom
        placeholder={"ФИО клиента"}
        name={"fullname"}
        value={client.title ?? ""}
        onChange={(e) => setTitle(e.target.value)}
        isError={!!client.title && client.title.length <= 3}
      />
    </div>
  );
};

export default ClientForm;
