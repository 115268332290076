import React, { useEffect, useState } from "react";
import styles from "./GalleryCards.module.scss";
import GalleryCard from "pages/Gallery/GalleryCard/GalleryCard";
import { useQuery } from "react-query";
import { createProject, getProjects, getPublicProjects, IProject } from "services/Projects";
import { Empty } from "antd";
import content, { Role } from "settings/content";
import Loader from "components/ui/Loader/Loader";
import Pagination from "components/Pagination/Pagination";
import "./GalleryCards_override.scss";
import NotFound from "components/NotFound/NotFound";
import DealCardCreate from "pages/DealsPage/components/DealCardCreate/DealCardCreate";
import { ISourceSelect } from "pages/Gallery/Gallery";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getAllDeals } from "services/Deals";
import { ISelect } from "components/ControlPanel/AnalyticsClients/AnalyticsClients";
import GalleryCardsModalMain from "./GalleryCardsModals/GalleryCardsModalMain";
import GalleryCardsModalOptions from "./GalleryCardsModals/GalleryCardsModalOptions";
import GalleryCardsModalSelectDeal from "./GalleryCardsModals/GalleryCardsModalSelectDeal";
import GalleryCardsModalCreateProject from "./GalleryCardsModals/GalleryCardsModalCreateProject";
import ComponentError from "components/ui/ComponentError/ComponentError";
import { useUser } from "context/useUser";
import { useSources } from "context/useSources";
import { useQueryParams } from "shared";

const LIMIT: number = 8;

interface IProps {
  searchQuery: ISourceSelect;
  refetchProjects: boolean;
  isPublicGallery?: boolean;
  isOpenedFromDeal?: string;
  isOnlyMyProjects?: boolean;
  isGalleryProject?: boolean;
}
export interface INewProject {
  name: string;
  room_type: string;
}

export const initialProject: INewProject = {
  name: "",
  room_type: "",
};

const GalleryCards: React.FC<IProps> = ({
  searchQuery,
  refetchProjects,
  isPublicGallery,
  isOpenedFromDeal,
  isOnlyMyProjects,
  isGalleryProject,
}) => {
  const { id }: any = useParams();
  const location = useLocation();
  const queryParams = useQueryParams();
  const projectID = queryParams.get("project_id");
  const history = useHistory();
  const [isPanoramaError, setIsPanoramaError] = useState(false);
  const { room_types } = useSources();
  const [clickedProject, setClickedProject] = useState<IProject | null>(null);

  const getPage = () => {
    const pageIndex = localStorage.getItem("gallery_pagination_page");
    if (!pageIndex) {
      return 0;
    }
    return +pageIndex;
  };

  const [pageIndex, setPageIndex] = useState<number>(getPage());

  useEffect(() => {
    setPageIndex(getPage());
  }, [refetchProjects, isOnlyMyProjects]);

  const {
    data: projects,
    refetch,
    isLoading,
    error,
    isRefetching,
  } = useQuery({
    queryFn: () =>
      isPublicGallery
        ? getPublicProjects({
            offset: pageIndex * LIMIT,
            limit: LIMIT,
            room_type_id: searchQuery.room_type,
            collection_id: searchQuery.collection,
            organization_id: id,
            manufacturer_id: searchQuery.manufacturer,
          })
        : getProjects(
            {
              offset: pageIndex * LIMIT,
              limit: LIMIT,
              room_type_id: searchQuery.room_type,
              collection_id: searchQuery.collection,
              status: searchQuery.status,
              user_id: isOnlyMyProjects ? searchQuery.user_id : searchQuery.selected_user_id,
              shop_id: searchQuery.shop_id,
              manufacturer_id: searchQuery.manufacturer,
            },
            isOnlyMyProjects,
          ),
    queryKey: [pageIndex],
  });

  const { user } = useUser();

  const { data: dealsData, error: dealsError } = useQuery({
    queryFn: () =>
      getAllDeals({
        dealSearch: { search: "" },
        filters: {
          department_ids: null,
          shop_ids: null,
          user_ids: null,
          period_start: null,
          period_end: null,
        },
      }),
    queryKey: ["deals-without-search"],
    enabled: !isPublicGallery && user.role === Role.DEPARTMENT_EMPLOYEE,
  });
  const [projectsData, setProjectsData] = useState<IProject[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalSelect, setModalSelect] = useState(false);
  const [modalCopy, setModalCopy] = useState(false);
  const [dealId, setDealId] = useState("");
  const [newProject, setNewProject] = useState<INewProject>(initialProject);
  const [newProjectFromDeal, setNewProjectFromDeal] = useState<INewProject>(initialProject);
  const [copyProjectId, setCopyProjectId] = useState<string>("");
  const [closeProject] = useState(false);
  const [dealTypes, setDealTypes] = useState<ISelect[]>([]);

  useEffect(() => {
    if (projectID && projectsData && projectsData.length) {
      setClickedProject(projectsData.find((x) => x.id === projectID || x.project_id === projectID) || null);
    }
  }, [projectID, projectsData]);

  const handlePageChange = (e: { selected: number }) => {
    if (!projects) {
      return;
    }
    localStorage.setItem("gallery_pagination_page", e.selected.toString());
    setPageIndex(e.selected);
  };

  useEffect(() => {
    if (!projects) {
      return;
    }
    setPageCount(Math.ceil(projects?.data.count / LIMIT));
    setProjectsData(projects.data.items);
  }, [projects]);
  // отфильтруем массив сделок по статутсу "Новая" и "Примерка"
  // и пересоберем для селекта
  useEffect(() => {
    if (dealsError) {
      return;
    }
    if (!dealsData?.data) {
      return;
    }
    if (isPublicGallery) {
      return;
    }
    const filteredDeals = dealsData.data.groups.filter((deal) => ["working", "created"].includes(deal.status));
    const joinedDeals = filteredDeals.map((item) => item.deals).flat();

    const selectDealsArray = joinedDeals.map((deal) => {
      return {
        label: deal.title,
        value: deal.id,
      };
    });

    setDealTypes(selectDealsArray);
  }, [dealsData, dealsError]);

  useEffect(() => {
    refetch();
  }, [refetchProjects]);

  const handleCardClick = (project: IProject) => {
    const baseQueryParams = `project_id=${project.id}`;
    history.push(!isOpenedFromDeal ? `?${baseQueryParams}` : `?deal_id=${isOpenedFromDeal}&${baseQueryParams}`);
    setClickedProject(project);
  };

  const handleCancelModal = () => {
    history.push(isPublicGallery ? `${location.pathname}` : isOpenedFromDeal ? `deals?deal_id=${isOpenedFromDeal}` : "");
    if (!isOpenedFromDeal) {
      return;
    }
    updateProjects();
  };

  const updateProjects = () => {
    refetch();
  };

  // В случае если сделка создана показываем форму и передаем id для
  // создания проекта
  const handleSuccess = (data: string) => setDealId(data);

  const handleNewProjectChange = (e: React.ChangeEvent<HTMLInputElement> | any): void => {
    setNewProject({ ...newProject, [e.target.name]: e.target.value });
  };

  const handleNewProjectFromDealChange = (e: React.ChangeEvent<HTMLInputElement> | any): void => {
    setNewProjectFromDeal({ ...newProjectFromDeal, [e.target.name]: e.target.value });
  };

  const handleCreateProject = (projectName: string, room_type: string, deal_id: string) => {
    const templateProjectId = clickedProject?.project_id || clickedProject?.id || projectID;

    createProject({
      deal_id: deal_id,
      project_name: projectName,
      room_type: room_type,
      template_project_id: templateProjectId,
    }).then((response) => {
      if (response.status === 200) {
        const link = document.createElement("a");
        link.href = `ceramic3d:?project_id=${response.data.id}&template_id=${templateProjectId}&projectName=${projectName}`;
        link.target = "_blank";
        link.click();
        setDealId("");
        setCopyProjectId("");
        setNewProject(initialProject);
        setModalCopy(false);
        setModalSelect(false);
        refetch();
      } else {
        alert("error");
      }
    });
  };

  const onProjectTitleChange = ({ id, title }: { id: string; title: string }) => {
    setProjectsData((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, name: title };
        }
        return item;
      });
    });
  };
  const handlePanoramaError = (isError: boolean) => setIsPanoramaError(isError);
  const handleModalSelect = (select: boolean) => setModalSelect(select);
  const handleModalCopy = (copy: boolean) => setModalCopy(copy);
  const handleModalCreate = () => setModalCreate(true);
  const hanldeCopyProjectId = (id: string) => setCopyProjectId(id);
  const handleNewProject = (project: INewProject) => setNewProject(project);

  if (isLoading && !projects) {
    return <Loader />;
  }

  if (projects?.data.count === 0) {
    return <NotFound />;
  }

  if (error) {
    return <ComponentError />;
  }

  return (
    <>
      {isRefetching ? (
        <Loader />
      ) : (
        <div className={styles.gallery_cards_container}>
          <div className={styles.gallery_cards_container_cards}>
            {projects?.status === 200 ? (
              projectsData?.length ? (
                projectsData?.map((card: IProject) => {
                  return (
                    <GalleryCard
                      isPublicGallery={isPublicGallery}
                      updateProjects={updateProjects}
                      key={card.id}
                      project={card}
                      handleCardClick={handleCardClick}
                      onProjectTitleChange={onProjectTitleChange}
                      isGalleryProject={isGalleryProject}
                    />
                  );
                })
              ) : (
                <div className={styles.gallery_cards_container__no_data}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )
            ) : (
              <div className={styles.gallery_cards_container__no_data}>
                <NotFound title={content.not_found} />
              </div>
            )}
          </div>
          {/* Главная модалка с панорамой, табами и кнопками */}
          <GalleryCardsModalMain
            projectID={projectID}
            isPublicGallery={isPublicGallery}
            isPanoramaError={isPanoramaError}
            handleCancelModal={handleCancelModal}
            handleModalSelect={handleModalSelect}
            handlePanoramaError={handlePanoramaError}
            isOpenedFromDeal={isOpenedFromDeal}
            handleDealSelect={handleSuccess}
            refetchProjects={updateProjects}
            isMyProject={isOnlyMyProjects}
            sourceProject={clickedProject}
          />

          {/* Модальное окно с выбором "создать сделки" и "Выбрать сделку" */}
          <GalleryCardsModalOptions
            modalSelect={modalSelect}
            handleModalCreate={handleModalCreate}
            handleModalSelect={handleModalSelect}
            handleModalCopy={handleModalCopy}
          />

          {/* Модальное окно с выбором сделки (Копирование) */}
          <GalleryCardsModalSelectDeal
            roomTypes={room_types.map((item) => ({ label: item.name, value: item.value }))}
            newProject={newProject}
            modalCopy={modalCopy}
            copyProjectId={copyProjectId}
            dealTypes={dealTypes}
            handleCreateProject={handleCreateProject}
            handleModalCopy={handleModalCopy}
            handleNewProjectChange={handleNewProjectChange}
            hanldeCopyProjectId={hanldeCopyProjectId}
            handleNewProject={handleNewProject}
          />

          {/* Модальное окно для создания сделки */}
          {modalCreate && (
            <DealCardCreate
              onCancel={() => setModalCreate(false)}
              handleSuccess={handleSuccess}
              open={modalCreate}
              updateDealsList={() => {}}
            />
          )}

          {/* Модальное окно для создания проекта, появляется, когда была создана сделка */}
          <GalleryCardsModalCreateProject
            closeProject={closeProject}
            dealId={dealId}
            roomTypes={room_types.map((item) => ({ label: item.name, value: item.value }))}
            newProject={newProject}
            handleSuccess={handleSuccess}
            handleCreateProject={handleCreateProject}
            handleModalSelect={handleModalSelect}
            handleNewProject={handleNewProject}
            handleNewProjectChange={handleNewProjectChange}
          />
        </div>
      )}
      {projects && projects?.data.count > LIMIT ? (
        <div className={styles.gallery_pagination}>
          <Pagination pageCount={pageCount} onPageChange={handlePageChange} forcePage={pageIndex} />
        </div>
      ) : null}
    </>
  );
};

export default GalleryCards;
