import { useEffect, useState } from "react";
import styles from "./ClientCard.module.scss";
import { Modal, message } from "antd";
import InputCustom from "components/ui/InputCustom/InputCustom";
import { IClient, editClient } from "services/Clients";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import content from "settings/content";
import { useMutation } from "react-query";
import { NoticeType } from "antd/es/message/interface";
import { isEmailValid, isPhoneValid, isTextValid } from "helpers/validation";
import { formatPhone } from "helpers/formatString";
import { PhoneInput } from "react-international-phone";
import { PHONE_COUNTRIES } from "settings/countries";
import { useClients } from "context/useClients";

type Props = {
  client: IClient | undefined;
  isOpen: boolean;
  onCancel: () => void;
  refetchClient: (updatedClient: IClient) => void;
};

const initialState: IClient = {
  id: "",
  title: "",
  type: null,
  persons: [
    {
      fullname: "",
      role: null,
      deleted: false,
      email: "",
      phone: "",
    },
  ],
};

const ClientCard = ({ isOpen, onCancel, client, refetchClient }: Props) => {
  const { refetchClients } = useClients();
  const [clientData, setClientData] = useState<IClient>(initialState);
  const [messageApi, contextHolder] = message.useMessage();
  const [isDisabled, setIsDisabled] = useState(true);

  const { mutateAsync: onEditClient, error } = useMutation({
    mutationFn: editClient,
  });

  useEffect(() => {
    if (!client) {
      return;
    }
    const updatedPerson = {
      ...client.persons[0],
      phone: client.persons[0].phone ? client.persons[0].phone : "",
    };
    setClientData({ ...client, persons: [updatedPerson] });
  }, [client]);

  const onTitleChange = (title: string) => {
    setClientData({
      ...clientData,
      title: title,
      persons: [
        {
          ...clientData.persons[0],
          fullname: title,
        },
      ],
    });
  };

  const onPhoneChange = (phone: string) => {
    const updatedPerson = {
      ...clientData.persons[0],
      phone: phone ? formatPhone(phone ?? "") : "",
    };
    setClientData({ ...clientData, persons: [updatedPerson] });
  };

  const onEmailChange = (email: string) => {
    const updatedPerson = {
      ...clientData.persons[0],
      email: email,
    };
    setClientData({ ...clientData, persons: [updatedPerson] });
  };

  const handleEditClient = async () => {
    onEditClient(clientData)
      .then((response) => {
        if (response.status === 200) {
          alert("success", content.alert_messages.client_card.success);
          refetchClient(response.data as IClient);
          refetchClients();
          onCancel();
        } else {
          alert("error", content.alert_messages.client_card.error);
        }
      })
      .catch((e) => {
        alert("error", content.alert_messages.client_card.error);
      });
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  useEffect(() => {
    const isFullname = clientData?.title && clientData.title !== "";
    const isPhone = isPhoneValid(clientData.persons[0].phone);
    const isEnteredEmailValid = !clientData.persons[0].email || isEmailValid(clientData.persons[0].email);
    setIsDisabled(!(isFullname && isPhone && isEnteredEmailValid));
  }, [clientData]);

  return (
    <>
      {contextHolder}
      <Modal
        className={"custom_shop_modal"}
        zIndex={99999}
        open={isOpen}
        closeIcon={false}
        onCancel={onCancel}
        centered
        footer={null}
        width={450}
        maskClosable={false}
        destroyOnClose
      >
        <div className={styles.clientCard__modal_header}>
          <span>{content.deals.client_card.title}</span>
          <ButtonCustom isTransparent maxWidth="20px" className={styles.clientCard__modal_close} onClick={onCancel}>
            <CloseIcon />
          </ButtonCustom>
        </div>
        <div className={styles.clientCard__wrapper}>
          <div className={styles.clientCard__inputs}>
            <div className={styles.clientCard__input_block}>
              <span>{content.deals.client_card.name}</span>
              <InputCustom
                className={styles.custom_input}
                placeholder={"ФИО клиента"}
                name={"fullname"}
                value={clientData.title}
                onChange={(e) => onTitleChange(e.target.value)}
                isError={!isTextValid(clientData.title)}
              />
            </div>
            <div className={styles.clientCard__input_block}>
              <span>{content.deals.client_card.phone}</span>
              <PhoneInput
                defaultCountry={clientData.persons[0].phone === "" || !clientData ? "ru" : undefined}
                value={clientData.persons[0].phone ?? ""}
                onChange={onPhoneChange}
                countries={PHONE_COUNTRIES}
                name={"phone"}
                forceDialCode
              />
            </div>
            <div className={styles.clientCard__input_block}>
              <span>{content.deals.client_card.email}</span>
              <InputCustom
                className={styles.custom_input}
                placeholder={"Email"}
                name={"email"}
                value={clientData.persons[0].email ?? ""}
                onChange={(e) => onEmailChange(e.target.value)}
                isError={!!clientData.persons[0].email && !isEmailValid(clientData.persons[0].email)}
                style={{ marginBottom: 10 }}
              />
            </div>
            {isDisabled && <span className={styles.clientCard__error_hint}>{content.alert_messages.client_card.requiredError}</span>}

            <div className={styles.clientCard__inputs_btns_block}>
              <ButtonCustom className={styles.clientCard__inputs_btn_cancel} maxWidth="90px" onClick={onCancel}>
                <span>{content.deals.client_card.btn_cancel}</span>
              </ButtonCustom>
              <ButtonCustom maxWidth="90px" onClick={handleEditClient} disabled={isDisabled}>
                <span>{content.deals.client_card.btn_save}</span>
              </ButtonCustom>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClientCard;
