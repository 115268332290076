import { useMutation } from "react-query";
import { delayReminderNotification, markNotificationAsNotified, markNotificationAsRead } from "services/Reminders";

export const useUserNotificationsMutations = () => {
  const { mutateAsync: readNotification } = useMutation({
    mutationFn: markNotificationAsRead,
  });
  const { mutateAsync: closeNotification } = useMutation({
    mutationFn: markNotificationAsNotified,
  });
  const { mutateAsync: delayNotification } = useMutation({
    mutationFn: delayReminderNotification,
  });

  return {
    readNotification,
    closeNotification,
    delayNotification,
  };
};
