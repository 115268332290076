import { useEffect, useState } from "react";
import styles from "./MailingModal.module.scss";
import { IGetPhoneBinding, IPhoneConnection, IShop, getPhoneConnections, getPhoneBinding, postPhoneBinding } from "services/Shops";
import { useMutation, useQuery } from "react-query";
import { useCities } from "context/useCities";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import { ReactComponent as TurnOffIcon } from "assets/icons/turnOff.svg";
import { ReactComponent as DeleteSmallIcon } from "assets/icons/smallDelete.svg";
import { ReactComponent as PlusIcon } from "assets/icons/add.svg";
import { ReactComponent as RedQrIcon } from "assets/icons/redqr.svg";
import ValidateComponentByRole from "components/ValidateComponentByRole/ValidateComponentByRole";
import { onlyAdmin, onlyLPR, Role } from "settings/content";
import { useUser } from "context/useUser";

type Props = {
  mailingModalId: string;
  shop: IShop | undefined;
  closeModal: () => void;
  refetchShops: () => void;
  openConnectionModal: (connection_id: string) => void;
  openDeletePhoneModal: (connection_id: string) => void;
  openAddPhoneModal: (value: string) => void;
  openQrModal: (connection_id: string) => void;
  setEmailSampleModal: (id: string) => void;
};

const MailingModal = ({
  shop,
  mailingModalId,
  closeModal,
  refetchShops,
  openConnectionModal,
  openDeletePhoneModal,
  openAddPhoneModal,
  openQrModal,
  setEmailSampleModal,
}: Props) => {
  const { user } = useUser();
  const { cities } = useCities();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState<IPhoneConnection>();
  const [phoneBinding, setPhoneBinding] = useState<IGetPhoneBinding[]>([]);
  const [phoneConnections, setPhoneConnections] = useState<IPhoneConnection[]>([]);

  const { data: phoneBindingData } = useQuery({
    queryFn: () => getPhoneBinding({ shop_id: mailingModalId }),
    queryKey: [`shop_telephone_${mailingModalId}`],
    refetchInterval: 10000,
  });

  const { data: phoneConnectionsData } = useQuery({
    queryFn: () => getPhoneConnections(),
    queryKey: [`phone_connections${mailingModalId}`],
    refetchInterval: 10000,
  });

  const { mutateAsync: onPostPhoneBinding } = useMutation({
    mutationFn: postPhoneBinding,
  });

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  useEffect(() => {
    if (phoneBindingData?.data) {
      setPhoneBinding(phoneBindingData.data);
    }
    if (phoneConnectionsData?.data) {
      setPhoneConnections(phoneConnectionsData.data);
    }
  }, [phoneBindingData, phoneConnectionsData]);

  useEffect(() => {
    if (!Array.isArray(phoneConnections)) {
      return;
    }
    const result = phoneConnections.find((con) => con.phone === phoneBinding[0]?.phone);
    setSelectedPhone(result);
  }, [phoneBinding, phoneConnections]);

  if (!shop || !phoneBindingData || !phoneConnectionsData) {
    return null;
  }

  return (
    <div className={styles.MailingModal}>
      <ValidateComponentByRole requiredRoles={[...onlyAdmin, ...onlyLPR]}>
        <ButtonCustom
          className={styles.MailingModal__sampleEditBtn}
          onClick={() => {
            setEmailSampleModal(shop.id);
          }}
          isTransparent
          maxWidth="fit-content"
        >
          <span>Настройка подписи</span>
        </ButtonCustom>
      </ValidateComponentByRole>
      <div className={styles.MailingModal__row}>
        <span className={styles.MailingModal__label}>Название магазина:</span>
        <span className={styles.MailingModal__value}>{shop.name}</span>
      </div>
      <div className={styles.MailingModal__row}>
        <span className={styles.MailingModal__label}>Регион:</span>
        <span className={styles.MailingModal__value}>{cities.find((item) => item.id === shop.city_id)?.name}</span>
      </div>
      <div className={styles.MailingModal__row}>
        <span className={styles.MailingModal__label}>Адрес:</span>
        <span className={styles.MailingModal__value}>{shop.address}</span>
      </div>
      <div className={styles.MailingModal__row}>
        <span className={styles.MailingModal__label}>Сервис рассылки:</span>
        <span className={styles.MailingModal__value}>{phoneBinding.length ? phoneBinding[0].connection_provider : ""}</span>
      </div>
      <div
        className={styles.MailingModal__row}
        style={{ flexDirection: "column", alignItems: "flex-start", gap: "5px", position: "relative" }}
      >
        <span className={styles.MailingModal__label}>Привязанный номер:</span>
        <div className={styles.MailingModal__select_block}>
          <SelectCustom
            placeholder="Выберите номер"
            width="100%"
            value={selectedPhone?.phone}
            onChange={(value) => {
              if (!Array.isArray(phoneConnections)) {
                return;
              }
              const result = phoneConnections.find((connection) => connection.phone === value);
              if (!result) {
                return;
              }
              setSelectedPhone(result);
            }}
            open={isOpen}
            onDropdownVisibleChange={handleOpenChange}
            disabled={!onlyAdmin.includes(user.role as Role)}
            options={
              Array.isArray(phoneConnections)
                ? phoneConnections.map((item) => {
                    return {
                      value: item.phone,
                      label: (
                        <div className={`${styles.MailingModal__select_label}`}>
                          <span className={`${item.state !== "connected" ? styles.errorColor : ""}`}>
                            {item.phone} {item.provider}
                          </span>
                          <div className={styles.MailingModal__select_btns}>
                            {phoneBinding && phoneBinding.length && phoneBinding[0].phone === item.phone ? (
                              <ValidateComponentByRole requiredRoles={onlyAdmin}>
                                <ButtonCustom
                                  className={styles.MailingModal__select_btn}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openConnectionModal(item.id);
                                    handleOpenChange(false);
                                  }}
                                  isTransparent
                                  maxWidth="25px"
                                >
                                  <TurnOffIcon height={"15px"} width={"15px"} />
                                </ButtonCustom>
                              </ValidateComponentByRole>
                            ) : null}
                            <ValidateComponentByRole requiredRoles={onlyAdmin}>
                              <ButtonCustom
                                className={styles.MailingModal__select_btn}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openDeletePhoneModal(item.id);
                                  handleOpenChange(false);
                                }}
                                isTransparent
                                maxWidth="25px"
                              >
                                <DeleteSmallIcon height={"15px"} width={"15px"} />
                              </ButtonCustom>
                            </ValidateComponentByRole>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
            showSearch
            filterOption={(input: any, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
          {selectedPhone?.state === "not_connected" && (
            <ValidateComponentByRole requiredRoles={onlyAdmin}>
              <ButtonCustom
                className={styles.MailingModal__qr_btn}
                onClick={(e) => {
                  e.stopPropagation();
                  openQrModal(selectedPhone.id);
                }}
                isTransparent
                maxWidth="25px"
              >
                <RedQrIcon />
              </ButtonCustom>
            </ValidateComponentByRole>
          )}
        </div>
        <ValidateComponentByRole requiredRoles={onlyAdmin}>
          <ButtonCustom
            className={styles.MailingModal__add_btn}
            onClick={(e) => {
              e.stopPropagation();
              openAddPhoneModal("1");
            }}
            isTransparent
            maxWidth="25px"
          >
            <PlusIcon height={"14px"} width={"14px"} />
          </ButtonCustom>
        </ValidateComponentByRole>
      </div>
      <div className={styles.MailingModal__footer_btns}>
        <ButtonCustom className={styles.MailingModal__footer_btn} onClick={closeModal} maxWidth="100px" bgColor="gray">
          <span>Отмена</span>
        </ButtonCustom>
        <ButtonCustom
          className={styles.MailingModal__footer_btn}
          onClick={() => {
            onPostPhoneBinding({ connection_id: selectedPhone?.id ?? "", shop_id: mailingModalId }).then((response) => {
              if (response.status === 200) {
                refetchShops();
                closeModal();
              }
            });
          }}
          maxWidth="100px"
          bgColor="blue"
          disabled={!selectedPhone || selectedPhone.state !== "connected" ? true : false}
        >
          <span>Подключить</span>
        </ButtonCustom>
      </div>
    </div>
  );
};

export default MailingModal;
