import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl5050 } from "env";

export interface IDealFilterValue {
  shop_ids: string[] | null;
  department_ids: string[] | null;
  user_ids: string[] | null;
  period_start: string | null;
  period_end: string | null;
}

export interface IDealFilter {
  id?: string;
  name: string;
  value: IDealFilterValue;
}

// Метод для получения фильтров
export async function getDealFilters(): Promise<{ data: IDealFilter[]; status: number }> {
  return axios
    .get(baseUrl5050 + `deal-filter`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getDealFilters:", err.response);
      return err.response;
    });
}

// Метод для получения фильтров по айди
export async function getDealFilterById(id: string): Promise<IDealFilter> {
  return axios
    .get(baseUrl5050 + `deal-filter/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getDealFilterById:", err.response);
      return err.response;
    });
}

// Метод для удаления фильтров по айди
export async function deleteDealFilterById(id: string): Promise<any> {
  return axios
    .delete(baseUrl5050 + `deal-filter/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> deleteDealFilterById:", err.response);
      return err.response;
    });
}

// Метод для создания фильтра
export async function createDealFilter(dealFilter: IDealFilter): Promise<{ data: IDealFilter; status: number }> {
  return axios
    .post(baseUrl5050 + `deal-filter`, { ...dealFilter }, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> createDealFilter:", err.response);
      return err.response;
    });
}

// Метод для редактирования фильтра
export async function editDealFilter(dealFilter: IDealFilter): Promise<{ data: IDealFilter; status: number }> {
  return axios
    .put(
      baseUrl5050 + `deal-filter/${dealFilter.id}`,
      { name: dealFilter.name, value: dealFilter.value },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> editDealFilter:", err.response);
      return err.response;
    });
}
