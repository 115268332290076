import { isEmail } from "validator";
import { Role, onlyAdmin, onlyLPR } from "settings/content";

export function isEmailValid(email: string | null): boolean {
  return !!email && isEmail(email);
}

const ALLOWED_PHONE_REGEXPS = [
  /^374\d{8}$/, // AM
  /^994\d{9}$/, // AZ
  /^375\d{9}$/, // BY
  /^7\d{10}$/, // RU
  /^7\d{10}$/, // KZ
  /^996\d{9}$/, // KG
  /^373\d{8}$/, // MD
  /^992\d{9}$/, // TJ
  /^993\d{8}$/, // TM
  /^998\d{9}$/, // UZ
  /^380\d{9}$/, // UA
  /^372\d{7,8}$/, // EE
  /^371\d{8}$/, // LV
];

export function isPhoneValid(phone: string | null): boolean {
  return !!phone && ALLOWED_PHONE_REGEXPS.some((x) => x.test(phone));
}

export function isPasswordValid(password: string, role: Role = Role.DEPARTMENT_EMPLOYEE): boolean {
  if (!password) {
    return false;
  }

  const ascii_uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const ascii_lowercase = "abcdefghijklmnopqrstuvwxyz";
  const ascii_letters = ascii_uppercase + ascii_lowercase;
  const punctuation = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
  const digits = "0123456789";

  const valid_symbols = ascii_letters + punctuation + digits;

  if ([...onlyLPR, ...onlyAdmin].includes(role)) {
    return !!(
      RegExp(`^[${escapeRegExp(valid_symbols)}]{6,}\$`).exec(password) &&
      RegExp(`[${escapeRegExp(ascii_letters)}]`).exec(password) &&
      RegExp(`[${escapeRegExp(punctuation)}]`).exec(password)
    );
  } else {
    return !!RegExp(`^[${escapeRegExp(valid_symbols)}]{4,}\$`).exec(password);
  }
}

function escapeRegExp(source: string): string {
  return source.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function isTextValid(text: string | null): boolean {
  return !!text && !/^\d+$/.test(text);
}
