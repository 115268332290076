import React, { useRef, useEffect, useState, memo } from "react";
import styles from "./GalleryCardDetailed.module.scss";
import { pannellum } from "helpers/pannelum";
import "./GalleryCardDetailed_override.scss";
import content from "settings/content";
import NotFound from "components/NotFound/NotFound";
import Loader from "components/ui/Loader/Loader";
import { yandexCloudUrl } from "env";
import { dirUpToPitchYaw } from "./extractPitchYawRoll";
import { IProject } from "services/Projects";

interface IMetaData {
  meta: string;
  pano: string;
  pano_small: string;
  pano_middle: string;
  preview: string;
}

type IProps = {
  selectedProjectId?: string;
  id: string;
  sceneId: string;
  height: string;
  currentTabIndex?: string;
  metaData?: IMetaData;
  panoramaId?: string;
  tabsDisabledHandler?: (toggler: boolean) => void;
  isFullSize?: boolean;
  selectedProject?: IProject | null;
};

interface IPanoCoords {
  eyeDistanceMM?: number;
  matrix?: [number[]];
  pitchAngle: number;
  pitchFOV: number;
  rollAngle: number;
  turnAngle: number;
}

const ProjectPanorama: React.FC<IProps> = memo((props: IProps) => {
  const [error, setError] = useState({ err: false, url: "" });
  const [panoType, setPanoType] = useState<string>("");
  const [panoramaDesktopCoords, setPanoramaDesktopCoords] = useState<IPanoCoords>();
  const [isTwoSecondsLeft, setIsTwoSecondsLeft] = useState(false);

  const selectedProjectId = props.selectedProject?.project_id || props.selectedProject?.id || props.selectedProjectId;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTwoSecondsLeft(true);
      clearTimeout(timer);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // В библиотеке отсутсвует типизация. Any указано явно.
  const viewer = useRef<any>(null);

  const getMetaData = async (item: any) => {
    const request = await fetch(`${yandexCloudUrl}${props.panoramaId}/pano/${item}`);
    if (request.status === 404) {
      setError({ err: true, url: request.url });
    }
    const coords = await request.json();

    setPanoramaDesktopCoords(coords);
  };

  const checkPano = async () => {
    const requestBigPano = await fetch(`${yandexCloudUrl}${selectedProjectId}/pano/${props.metaData?.pano}`);
    const requestMiddlePano = await fetch(`${yandexCloudUrl}${selectedProjectId}/pano/${props.metaData?.pano_middle}`);
    const requestSmallPano = await fetch(`${yandexCloudUrl}${selectedProjectId}/pano/${props.metaData?.pano_small}`);
    if (requestSmallPano.status === 200 && requestBigPano.status !== 200 && requestMiddlePano.status !== 200) {
      setPanoType("small");
      return;
    }
    if (requestMiddlePano.status === 200 && (requestBigPano.status !== 200 || window.innerWidth < 650)) {
      setPanoType("middle");
      return;
    }
    if (requestBigPano.status === 200) {
      setPanoType("big");
      return;
    }
  };

  // Некоторые панорамы могут быть еще не загружены в хранилище.
  // Будем отправлять запросы каждые 3и секунды при открытом модальном окне
  // Как только панорама будет загружена чистим интервалы
  useEffect(() => {
    if (props.id !== props.currentTabIndex) {
      return;
    }
    // Будем подключать интервал, только для той панорамы, у
    // который статус panoType === "small"
    const request = setInterval(() => {
      if (panoType === "small" || panoType === "") {
        checkPano();
        return;
      }
    }, 3000);

    if (panoType === "big") {
      clearInterval(request);
    }

    return () => clearInterval(request);
  }, [props.currentTabIndex, panoType]);

  // Мы передаем в табы в цикле все панорамы. Будем проверять по индексу и выбранному табу
  // для какой панорамы будем подгружать данные
  useEffect(() => {
    checkPano();
  }, [props.currentTabIndex]);

  useEffect(() => {
    getMetaData(props.metaData?.meta);
  }, [props]);

  useEffect(() => {
    viewer.current?.destroy();
    if (!panoramaDesktopCoords) {
      return;
    }

    const viewParams = dirUpToPitchYaw(panoramaDesktopCoords.matrix ?? []);

    if (!viewParams) {
      return;
    }

    viewer.current = pannellum.viewer(props.id, {
      autoLoad: true,
      panorama: `${yandexCloudUrl}${props.panoramaId}/pano/${
        panoType === "small" ? props.metaData?.pano_small : panoType === "middle" ? props.metaData?.pano_middle : props.metaData?.pano
      }`,
      dynamicUpdate: true,
      compass: false,
      friction: 1,
      mouseZoom: true,
      showZoomCtrl: true,
      showFullscreenCtrl: true,
      type: "equirectangular",
      hfov: 1000,
      pitch: viewParams.pitch,
      yaw: viewParams.yaw,
    });

    return () => {
      viewer.current.destroy();
    };
  }, [props.currentTabIndex, panoramaDesktopCoords, panoType, props]);

  useEffect(() => {
    if (viewer?.current && viewer.current?.resize) {
      viewer.current.resize();
    }
  }, [props.isFullSize]);

  return error.err ? (
    <div className={styles.gallery_card_detailed__error}>
      <span>{content.gallery.metadata_error}</span>
      <span>{String(error.url)}</span>
    </div>
  ) : (
    <>
      {panoType === "small" && <span className={styles.gallery_card_detailed__warning}>{content.gallery.load_warning}</span>}
      <div style={{ display: panoType !== "" ? "block" : "none" }} id={props.id} />
      {panoType === "" && !isTwoSecondsLeft && (
        <div id={props.id} style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Loader />
        </div>
      )}
      {panoType === "" && isTwoSecondsLeft && (
        <div id={props.id} style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <NotFound title="Ваша панорама скоро будет готова, пожалуйста подождите." />
        </div>
      )}
    </>
  );
});

export default ProjectPanorama;
