import { useEffect, useState } from "react";
import ModalCustom from "components/ModalCustom/ModalCustom";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import styles from "./EditLead.module.scss";
import { useShops } from "context/useShops";
import { useUser } from "context/useUser";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { getLeadById, getLeadHistoryById, leadTransfer } from "services/Leads";
import { useMutation, useQuery } from "react-query";
import { checkAuthenticated } from "helpers/checkAuth";

type Props = {
  leadId: string;
  user_id: string | null | undefined;
  department_id: string | null | undefined;
  shop_id: string | null | undefined;
  isOpen: boolean;
  handleCloseModal: () => void;
};

interface IUserData {
  shop_id: string | null | undefined;
  department_id: string | null | undefined;
  responsible_user_id: string | null | undefined;
}

const UserChange = ({ leadId, department_id, shop_id, user_id, isOpen, handleCloseModal }: Props) => {
  const authenticated = checkAuthenticated();

  const { allDepartments, shops } = useShops();
  const { users } = useUser();

  const { refetch } = useQuery({
    queryFn: () => getLeadById({ id: leadId }),
    queryKey: [`lead_${leadId}`],
    enabled: authenticated && leadId !== "",
  });

  const { refetch: refetchLeadHistory } = useQuery({
    queryFn: () => getLeadHistoryById({ id: leadId }),
    queryKey: [`lead_${leadId}/history`],

    enabled: authenticated && leadId !== "" && !!leadId,
  });

  const { mutateAsync: onLeadTransfer } = useMutation({
    mutationFn: leadTransfer,
    onSuccess: () => {
      refetch();
    },
  });

  const [userData, setUserData] = useState<IUserData>({ department_id, responsible_user_id: user_id, shop_id });

  useEffect(() => {
    setUserData({ department_id, responsible_user_id: user_id, shop_id });
  }, [isOpen]);

  const isSubmitDisabled = () => {
    if (!userData.shop_id || !userData.department_id) {
      return true;
    }
    return false;
  };

  const onSubmit = () => {
    if (!userData.shop_id || !userData.department_id) {
      return;
    }
    onLeadTransfer({ id: leadId, department_id: userData.department_id, responsible_user_id: userData.responsible_user_id ?? null }).then(
      (response) => {
        if (response.status === 200) {
          refetchLeadHistory();
          handleCloseModal();
        }
      },
    );
  };

  return (
    <ModalCustom
      isOpen={isOpen}
      title={"Изменение сотрудника/отдела"}
      handleCloseModal={handleCloseModal}
      centered
      width={400}
      footer={null}
      onCancel={handleCloseModal}
      destroyOnClose
    >
      <div className={styles.EditLead}>
        <div className={styles.EditLead__input_block}>
          <span className={styles.EditLead__label}>Магазин:</span>
          <SelectCustom
            options={shops.map((item) => ({ value: item.id, label: item.name }))}
            onChange={(value) =>
              setUserData((prev) => ({ ...prev, shop_id: value, department_id: undefined, responsible_user_id: undefined }))
            }
            className={styles.createLead__select}
            placeholder="Магазин"
            value={shops.find((item) => item.id === userData.shop_id)?.id}
            width="200px"
            disabled={false}
          />
        </div>
        <div className={styles.EditLead__input_block}>
          <span className={styles.EditLead__label}>Отдел:</span>
          <SelectCustom
            options={allDepartments
              .filter((item) => item.shop_id === userData.shop_id)
              .map((item) => ({ value: item.id, label: item.name }))}
            onChange={(value) => setUserData((prev) => ({ ...prev, department_id: value, responsible_user_id: undefined }))}
            className={styles.createLead__select}
            placeholder="Отдел"
            value={allDepartments.find((item) => item.id === userData.department_id)?.id}
            width="200px"
            disabled={!userData.shop_id}
          />
        </div>
        <div className={styles.EditLead__input_block}>
          <span className={styles.EditLead__label}>Сотрудник:</span>
          <SelectCustom
            options={users
              .filter((item) => item.department_id === userData.department_id)
              .map((item) => ({ value: item.id, label: item.name }))}
            onChange={(value) => setUserData((prev) => ({ ...prev, responsible_user_id: value }))}
            className={styles.createLead__select}
            placeholder="Сотрудник"
            value={users.find((item) => item.id === userData.responsible_user_id)?.id}
            width="200px"
            disabled={!userData.department_id || !userData.shop_id}
            allowClear
          />
        </div>
        <div className={styles.EditLead__submitBlock}>
          <ButtonCustom maxWidth="250px" className={styles.EditLead__submit} onClick={onSubmit} disabled={isSubmitDisabled()}>
            <span>Сохранить</span>
          </ButtonCustom>
        </div>
      </div>
    </ModalCustom>
  );
};

export default UserChange;
