import GalleryFilters from "./GalleryFilters/GalleryFilters";
import GalleryCards from "./GalleryCards/GalleryCards";
import styles from "./Gallery.module.scss";
import { useState } from "react";
import PageTitle from "components/ui/PageTitle/PageTitle";
import { useUser } from "context/useUser";
import { onlyEmployee, Role } from "settings/content";

export interface ISourceSelect {
  room_type: string;
  collection: string;
  status: string;
  user_id: string;
  shop_id: string;
  manufacturer: string;
  selected_user_id: string;
}

export enum ISourceSelectKeys {
  room_type = "room_type",
  collection = "collection",
  status = "status",
  user_id = "user_id",
  shop_id = "shop_id",
  city_id = "city_id",
  manufacturer = "manufacturer",
  selected_user_id = "selected_user_id",
}

interface IGalleryProps {
  isPublicGallery?: boolean;
  isOpenedFromDeal?: string;
}

export enum IGalleryModes {
  gallery = "Галерея проектов",
  my_projects = "Мои проекты",
}

const Gallery = ({ isPublicGallery = false, isOpenedFromDeal }: IGalleryProps) => {
  const { user } = useUser();
  const [searchQuery, setSearchQuery] = useState<ISourceSelect>({
    room_type: "",
    collection: "",
    status: "",
    user_id: "",
    shop_id: "",
    manufacturer: "",
    selected_user_id: "",
  });

  const [refetchProjects, setRefethchProjects] = useState(false);
  const [galleryMode, setGalleryMode] = useState<IGalleryModes>(IGalleryModes.gallery);

  const HandleSearchQuery = (select_name: ISourceSelectKeys, value: string) => {
    setSearchQuery((prev) => ({ ...prev, [select_name]: value }));
  };

  const handleUpdateProjects = () => {
    setRefethchProjects(!refetchProjects);
  };

  const onGalleryModeChange = (value: IGalleryModes) => {
    localStorage.setItem("gallery_pagination_page", "0");
    setGalleryMode(value as IGalleryModes);
  };

  return (
    <section className={[styles.gallery, isPublicGallery ? styles.gallery__public : ""].join(" ")}>
      <div className={[styles.gallery__header, isPublicGallery ? styles.gallery__header_public : ""].join(" ")}>
        <div className={styles.gallery__header_block}>
          <div className={styles.gallery__header_block_title}>
            <div style={{ cursor: "pointer" }} onClick={() => onGalleryModeChange(IGalleryModes.gallery)}>
              {galleryMode === IGalleryModes.gallery ? (
                <PageTitle title={IGalleryModes.gallery} />
              ) : (
                <span className={styles.gallery__header_block_inactive_title}>{IGalleryModes.gallery}</span>
              )}
            </div>
            {!isPublicGallery && onlyEmployee.includes(user.role as Role) && (
              <div style={{ cursor: "pointer" }} onClick={() => onGalleryModeChange(IGalleryModes.my_projects)}>
                {galleryMode === IGalleryModes.my_projects ? (
                  <PageTitle title={IGalleryModes.my_projects} />
                ) : (
                  <span className={styles.gallery__header_block_inactive_title}>{IGalleryModes.my_projects}</span>
                )}
              </div>
            )}
          </div>
        </div>
        <GalleryFilters
          setSearchQuery={HandleSearchQuery}
          updateProjects={handleUpdateProjects}
          isPublicGallery={isPublicGallery}
          isOnlyMyProjects={galleryMode === IGalleryModes.my_projects}
          isOpenedFromDeal={!!isOpenedFromDeal}
        />
      </div>
      <div className={styles.gallery__container}>
        <GalleryCards
          searchQuery={searchQuery}
          refetchProjects={refetchProjects}
          isPublicGallery={isPublicGallery}
          isOpenedFromDeal={isOpenedFromDeal}
          isOnlyMyProjects={galleryMode === IGalleryModes.my_projects}
          isGalleryProject={galleryMode === IGalleryModes.gallery}
        />
      </div>
    </section>
  );
};

export default Gallery;
