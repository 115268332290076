import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { checkAuthenticated } from "helpers/checkAuth";
import { IClient, getAllClients } from "services/Clients";

interface IClientsProps {
  children: ReactElement;
}

interface IClientsContext {
  clients: IClient[];
  refetchClients: () => void;
}

const initialContext: IClientsContext = {
  clients: [],
  refetchClients: () => {},
};

const ClientsContext = React.createContext<IClientsContext>(initialContext);

export const useClients = () => {
  return useContext(ClientsContext);
};

export const ClientsProvider: React.FC<IClientsProps> = ({ children }) => {
  const authenticated = checkAuthenticated();

  const [clients, setClients] = useState<IClient[]>([]);
  const { data: clientsData, refetch: refetchClients } = useQuery({
    queryFn: () => getAllClients(),
    queryKey: ["Clients"],
    enabled: authenticated,
  });

  useEffect(() => {
    if (!clientsData?.data) {
      return;
    }
    setClients(clientsData.data.items);
  }, [clientsData]);

  return <ClientsContext.Provider value={{ clients, refetchClients }}>{children}</ClientsContext.Provider>;
};
