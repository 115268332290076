import styles from "pages/DealsPage/components/DealCardDetail/DetailCardHistoryStatuses/DetailCardHistoryStatuses.module.scss";
import { ILeadHistory, leadHistoryTypesEnum } from "services/Leads";
import dayjs from "dayjs";
import { useUser } from "context/useUser";
import { statuses } from "pages/LeadsPage/constants";
import { useClients } from "context/useClients";
import { useLeads } from "context/useLeads";
import { useShops } from "context/useShops";

type Props = {
  historyItem: ILeadHistory;
};

const LeadHistoryItem = ({ historyItem }: Props) => {
  const { users } = useUser();
  const { clients } = useClients();
  const { allDepartments, shops } = useShops();
  const { leadChannelsData, leadPromosData } = useLeads();
  const time = dayjs(historyItem.created_at).format("HH:mm");
  const author = users.find((user) => user.id === historyItem.created_by)?.name;

  if (historyItem.transaction_type === leadHistoryTypesEnum.statusChange) {
    const newStatus = statuses.find((item) => item.id === historyItem.transaction_value.new_status)?.name;
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил статус на <span className={styles.bold_text}>{newStatus}</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.titleChange) {
    const newTitle = historyItem.transaction_value.new_title;
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил название на <span className={styles.bold_text}>{newTitle}</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.clientChange) {
    const newClient = clients.find((item) => item.id === historyItem.transaction_value.new_client_id)?.title;
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил клиента на <span className={styles.bold_text}>{newClient}</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.promoChange) {
    const newPromo = leadPromosData.find((item) => item.id === historyItem.transaction_value.new_promo_id)?.name ?? "промо без названия";
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил акцию на <span className={styles.bold_text}>{newPromo}</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.channelChange) {
    const newChannel =
      leadChannelsData.find((item) => item.id === historyItem.transaction_value.new_channel_id)?.name ?? "канал без названия";
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил канал на <span className={styles.bold_text}>{newChannel}</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.budgetChange) {
    const newBudget = historyItem.transaction_value.new_budget;
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил бюджет на <span className={styles.bold_text}>{newBudget}</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.responsibleUserChange) {
    const newUser = users.find((item) => item.id === historyItem.transaction_value.new_responsible_user_id)?.name ?? "Сотрудник без имени";
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил ответственного на <span className={styles.bold_text}>{newUser}</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.departmentChange && !historyItem.transaction_value.new_shop_id) {
    const newDepartment =
      allDepartments.find((item) => item.id === historyItem.transaction_value.new_department_id)?.name ?? "Отдел без названия";
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил отдел на <span className={styles.bold_text}>{newDepartment}</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.departmentChange && historyItem.transaction_value.new_shop_id) {
    const newDepartment =
      allDepartments.find((item) => item.id === historyItem.transaction_value.new_department_id)?.name ?? "Отдел без названия";
    const newShop = shops.find((item) => item.id === historyItem.transaction_value.new_shop_id)?.name ?? "Магазин без названия";
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.transaction_type + historyItem.created_by}>
        <span className={styles.bold_text}>{author}</span> сменил магазин и отдел на{" "}
        <span className={styles.bold_text}>
          {newShop} {newDepartment}
        </span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  if (historyItem.transaction_type === leadHistoryTypesEnum.reminder) {
    return (
      <div className={styles.LeadHistory__status} key={historyItem.created_at + historyItem.created_by}>
        Напоминание на {dayjs(historyItem.created_at).format("DD.MM.YYYY HH:mm")}{" "}
        <span className={styles.bold_text}>"{historyItem.transaction_value.reminder_text}"</span>
        <span className={styles.LeadHistory__time}>{time}</span>
      </div>
    );
  }
  return null;
};

export default LeadHistoryItem;
