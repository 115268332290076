import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl } from "env";

export interface IWorkstation {
  id: string;
  department_id: string | null;
  license_id: string | null;
  name: string | null;
}

export interface IWorkstations {
  data: IWorkstation[];
  status: number;
}

export interface IAddWorkstation {
  department_id: string;
  license_id: string;
  name: string;
}

export interface IAddWorkstationResponse {
  id: string;
  status: number;
}

export async function getWorkstations(): Promise<IWorkstations> {
  return axios
    .get(baseUrl + "workstation", { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getWorkstations:", err.response);
      return err.response;
    });
}

export async function getWorkstationById(workstation_id: string): Promise<IWorkstation> {
  return axios
    .get(baseUrl + `workstation/${workstation_id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getWorkstationById:", err.response);
      return err.response;
    });
}

export async function addWorkstation(requestWorkstation: IAddWorkstation): Promise<IAddWorkstationResponse> {
  return axios
    .post(baseUrl + "workstation", requestWorkstation, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        id: response.data.id,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> addWorkstation:", err.response);
      return err.response;
    });
}

export async function deleteWorkstation(workstation_id: string): Promise<{ status: number }> {
  return axios
    .delete(baseUrl + `workstation/${workstation_id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> addWorkstation:", err.response);
      return err.response;
    });
}

export async function editWorkstation({
  workstation_id,
  name,
  license_id,
  department_id,
}: {
  workstation_id: string;
  name: string;
  license_id: string;
  department_id: string;
}): Promise<{ status: number; data: any }> {
  return axios
    .put(
      baseUrl + `workstation/${workstation_id}`,
      { name, license_id, department_id },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> editWorkstation:", err.response);
      return err.response;
    });
}
