import React, { useState } from "react";
import OrganizationHeader from "pages/Organization/OrganizationHeader/OrganizationHeader";
import content from "settings/content";
import WorkstationsList from "./WorkstationsList/WorkstationsList";
import WorkstationAdd from "./WorkstationAdd/WorkstationAdd";
import styles from "./Workstations.module.scss";
import EditWorkstation from "./EditWorkstation/EditWorkstation";
type Props = {};

const Workstations: React.FC<Props> = ({}) => {
  const [workstationId, setWorkstationId] = useState("");
  const [editWorkstationId, setEditWorkstationId] = useState("");

  const updateWorkstationsList = (id: string) => {
    setWorkstationId(id);
  };

  return (
    <>
      <div className={styles.workstations}>
        <WorkstationAdd updateWorkstationsList={updateWorkstationsList} />
        <EditWorkstation editWorkstationId={editWorkstationId} setEditWorkstationId={setEditWorkstationId} />
        <OrganizationHeader title={""} placeholder={content.organizations.keys.placeholder} isSearch={false} />
      </div>
      <WorkstationsList workstationId={workstationId} setEditWorkstationId={setEditWorkstationId} />
    </>
  );
};
export default Workstations;
