import { useState } from "react";
import styles from "./SendPanoModal.module.scss";
import "./SendPanoModal.scss";
import "react-quill/dist/quill.snow.css";
import { useUser } from "context/useUser";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { useMutation, useQuery } from "react-query";
import { getProjectNotificationText, sendPanosToClients } from "services/Projects";
import { INotification } from "services/Reminders";
import { useNotificationsContext } from "context/useNotifications";
import { getDeal } from "services/Deals";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import Loader from "components/ui/Loader/Loader";

type Props = {
  projectId: string;
  deal_id: string;
  project_id_list: string[];
  closeModal: () => void;
};

const SendPanoModal = ({ projectId, deal_id, project_id_list, closeModal }: Props) => {
  const { data: notificationText, isLoading } = useQuery({
    queryFn: () => getProjectNotificationText({ deal_id, project_id: null }),
    queryKey: [`notificationText${deal_id}`],
    enabled: !!deal_id,
    onSuccess: (data) => {
      setText(data.data.text);
    },
  });

  const { setMailingNotification, mailingNotification } = useNotificationsContext();
  const { user } = useUser();

  const [text, setText] = useState(notificationText?.data.text);

  const { mutateAsync: onSendPanos } = useMutation({
    mutationFn: sendPanosToClients,
    onSuccess: () => {},
  });

  const { data: dealData } = useQuery({
    queryFn: () => getDeal({ id: deal_id }),
    queryKey: [`deal_${deal_id}`],
    enabled: !!deal_id,
  });

  const onSubmit = () => {
    onSendPanos({ deal_id, text: text ?? "" }).then((response) => {
      if (response.status === 200) {
        closeModal();
      }
      const statusValue = response.data?.status;
      if (
        statusValue &&
        statusValue !== "NO_EMAIL_BINDING" &&
        statusValue !== "NO_PHONE_BINDING" &&
        statusValue !== "NO_EMAIL_AND_PHONE_BINDING"
      ) {
        const comment = (() => {
          if (statusValue && statusValue === "SUCCESS") {
            return `Уведомление клиенту ${dealData?.data.client_fullname} успешно отправлено`;
          } else if (response.data?.status_text) {
            return `Уведомление клиенту не отправлено. Ошибка: ${response.data?.status_text}`;
          }
        })();
        const obj: INotification = {
          id: "",
          created_at: "",
          deal_id: deal_id,
          lead_id: null,
          created_by: user.id,
          comment: comment ?? "",
          notify_at: dayjs().format(),
          postponed_until: "",
          processed: false,
          was_read: false,
          isSuccess: statusValue === "SUCCESS",
        };
        setMailingNotification([obj]);
      }
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.SendPanoModal}>
      <TextArea value={text} onChange={(e) => setText(e.target.value)} rows={10} placeholder="Текст рассылки" />
      <ButtonCustom className={styles.sendBtn} onClick={onSubmit} maxWidth="170px">
        <span>Отправить панорамы</span>
      </ButtonCustom>
    </div>
  );
};

export default SendPanoModal;
