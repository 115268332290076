import React, { memo, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "PrivateRoute";
import LoginPage from "pages/LoginPage/LoginPage";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import PanoramaIframe from "pages/ProjectIframe/PanoramaIframe";
import ProjectItemsIframe from "pages/ProjectIframe/ProjectItemsIframe";
import Panorama from "pages/Panorama/Panorama";
import PublicProjectView from "pages/PublicGallery/PublicProjectView/PublicProjectView";
import PublicGallery from "pages/PublicGallery/PublicGallery";
import { checkAuthenticated } from "helpers/checkAuth";
import { getPermittedPages } from "helpers/checkPagePermission";
import { PageName } from "settings/content";
import { WithWorkspaceLayout } from "./WithWorkspaceLayout";
import { UserNotificationsOrchestrator } from "widgets/UserNotificationsOrchestrator";
import { useEffectAsync, useRefreshToken } from "shared";

export const AppRouter = memo(() => {
  const authenticated = checkAuthenticated();

  useRefreshToken();

  const [pagePermission, setPagePermission] = useState<boolean>(authenticated);

  useEffectAsync(async () => {
    if (!authenticated) {
      return;
    }

    const data = await getPermittedPages();

    setPagePermission(!!data && data.includes(PageName.organization_page));
  }, [authenticated]);

  return (
    <BrowserRouter>
      <UserNotificationsOrchestrator>
        <Switch>
          <ProtectedRoute isAuthenticated={!authenticated} authenticationPath={"/"} path={"/signin"} exact component={LoginPage} />
          <ProtectedRoute isAuthenticated={!authenticated} authenticationPath={"/"} path={"/reset-password"} component={ResetPassword} />
          <Route path={"/panorama-iframe/:id"} component={PanoramaIframe} />
          <Route path={"/project-items-iframe/:id"} component={ProjectItemsIframe} />
          <Route path={"/panorama-view/:id/pano/:pano"} exact component={Panorama} />
          <Route path={"/view-project/:id"} exact component={PublicProjectView} />
          <Route path={"/public-gallery/:id"} exact component={PublicGallery} />
          <Route path="/" render={() => <WithWorkspaceLayout isAuthenticated={authenticated} pagePermission={pagePermission} />} />
        </Switch>
      </UserNotificationsOrchestrator>
    </BrowserRouter>
  );
});
