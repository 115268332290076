import { Tabs } from "antd";
import content, { onlyAdmin, onlyLPR } from "settings/content";
import ShopsCards from "./Shops/ShopsCards/ShopsCards";
import Stuff from "./Stuff/Stuff";
import { ReactComponent as ShopIcon } from "assets/icons/shop.svg";
import { ReactComponent as StuffIcon } from "assets/icons/stuff.svg";
import { ReactComponent as KeyIcon } from "assets/icons/key.svg";
import styles from "./Organization.module.scss";
import "./Organization_override.scss";
import Keys from "./Keys/Workstations";
import { useEffect } from "react";
import ValidateComponentByRole from "components/ValidateComponentByRole/ValidateComponentByRole";
import { useHistory, useLocation } from "react-router-dom";

export interface IProps {}

const Organization: React.FC<IProps> = ({}: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentTab = queryParams.get("tab");

  const onTabChange = (value: string) => {
    queryParams.set("tab", value);
    history.replace({
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    if (!currentTab) {
      onTabChange("shops");
    }
  }, []);

  const tabs = [
    {
      label: (
        <div className={styles.organization__tab}>
          <ShopIcon />
          <span>{content.organizations.tabs.tabShops}</span>
        </div>
      ),
      key: "shops",

      children: <ShopsCards />,
    },
    {
      label: (
        <div className={styles.organization__tab}>
          <StuffIcon />
          <span>{content.organizations.tabs.tabStaff}</span>
        </div>
      ),
      key: "stuff",
      children: <Stuff tabname={currentTab ?? ""} />,
    },
    {
      label: (
        /// вернуть лпр когда метод на бэке поправят
        <ValidateComponentByRole requiredRoles={[...onlyAdmin, ...onlyLPR]}>
          <div className={styles.organization__tab}>
            <KeyIcon />
            <span>{content.organizations.tabs.tabKeys}</span>
          </div>
        </ValidateComponentByRole>
      ),
      key: "keys",
      children: <Keys />,
    },
  ];
  return (
    <div className={`${styles.organization__tabs} organization__tabs`}>
      <Tabs defaultActiveKey={currentTab ?? "shops"} items={tabs} onChange={(tabname) => onTabChange(tabname)} />
    </div>
  );
};

export default Organization;
