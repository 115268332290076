import React, { useEffect, useRef, useState } from "react";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { useDebounce } from "@uidotdev/usehooks";
import styles from "./DealsPage.module.scss";
import SearchBar from "components/SearchBar/SearchBar";
import DealCardCreate from "./components/DealCardCreate/DealCardCreate";
import DealCardDetail from "./components/DealCardDetail/DealCardDetail";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IDeal, IDealStatusResponse, editDealStatus, getAllDeals, getDeal, getDealStatuses } from "services/Deals";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/GearSix.svg";
import Loader from "components/ui/Loader/Loader";
import content, { onlyEmployee, onlyLPR, Role } from "settings/content";
import "./DealsPage_override.scss";
import { NoticeType } from "antd/es/message/interface";
import { message } from "antd";
import { useUser } from "context/useUser";
import { useHistory, useLocation } from "react-router-dom";
import ComponentError from "components/ui/ComponentError/ComponentError";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import { SortOptionsList, sortOptions } from "./sortOptions";
import { sortDealsByCurrentOption } from "./sortDealsByCurrentOption";
import ValidateComponentByRole from "components/ValidateComponentByRole/ValidateComponentByRole";
import KanbadBoardContainer from "components/KanbanBoard/KanbadBoardContainer";
import { ColumnType } from "components/KanbanBoard/DragDropProvider";
import { INotification } from "services/Reminders";
import dayjs from "dayjs";
import { useNotificationsContext } from "context/useNotifications";
import ModalCustom from "components/ModalCustom/ModalCustom";
import FiltersSettings from "./components/FiltersSettings/FiltersSettings";
import { getDealFilters, IDealFilter } from "services/DealFilters";
import StatusesSettings from "components/StatusesSettings/StatusesSettings";
import { DownloadDealsExcelReportButton } from "features/DowloadDealsExcelReport";
import PageTitle from "components/ui/PageTitle/PageTitle";
import { cloneDeep } from "lodash";

const DealsPage: React.FC = () => {
  const firstStatusesDataRef = useRef<IDealStatusResponse[]>([]);
  const { user } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dealID = queryParams.get("deal_id");
  const history = useHistory();
  const { setMailingNotification } = useNotificationsContext();
  const queryClient = useQueryClient();

  const { data: statusesData, refetch: refetchDealStatuses } = useQuery({
    queryFn: () => getDealStatuses(),
    queryKey: ["statuses"],
  });

  const { data: dealFiltersData, refetch: refetchDealFilters } = useQuery({
    queryFn: () => getDealFilters(),
    queryKey: ["dealFilters"],
  });

  const { mutateAsync: onEditDealStatus } = useMutation({
    mutationFn: editDealStatus,
    onSuccess: () => {
      // updateDealsList();
      setTimeout(() => {
        setIsKanbanDragAndDropDisabled(false);
      }, 300);
    },
  });

  const [selectedFilter, setSelectedFilter] = useState<IDealFilter>();
  const [kanbanData, setKanbanData] = useState<ColumnType[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<IDealStatusResponse[]>([]);
  const [isOnlyMyDeals, setIsOnlyMyDeals] = useState<boolean>(false);
  const [currentSortOption, setCurrentSortOption] = useState<SortOptionsList | undefined>(SortOptionsList.newFirst);
  const [isKanbanDragAndDropDisabled, setIsKanbanDragAndDropDisabled] = useState<boolean>(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [isStatusSettingsOpen, setIsStatusSettingsOpen] = useState<boolean>(false);

  const {
    data: deals,
    isRefetching,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryFn: () =>
      getAllDeals({
        dealSearch: { search },
        filters: {
          department_ids: selectedFilter?.value.department_ids ?? null,
          shop_ids: selectedFilter?.value.shop_ids ?? null,
          user_ids: selectedFilter?.value.user_ids ?? null,
          period_start: selectedFilter?.value.period_start ?? null,
          period_end: selectedFilter?.value.period_end ?? null,
        },
      }),
    queryKey: ["deals", selectedFilter],
  });

  const invalidateAllDealsCache = () => {
    void queryClient.invalidateQueries(["deals", selectedFilter]);
  };

  const handleStatusChange = async ({ value, id }: { value: string; id: string }): Promise<any> => {
    setIsKanbanDragAndDropDisabled(true);
    setCurrentSortOption(undefined);
    const response = await onEditDealStatus({ dealId: id, status: value });
    const clientName = (await getDeal({ id }))?.data.client_fullname;
    const statusValue = response?.data?.project_notification_status;
    if (
      statusValue &&
      statusValue !== "NO_EMAIL_BINDING" &&
      statusValue !== "NO_PHONE_BINDING" &&
      statusValue !== "NO_EMAIL_AND_PHONE_BINDING"
    ) {
      const comment = (() => {
        if (response.data?.project_notification_status && response.data.project_notification_status === "SUCCESS") {
          return `Уведомление клиенту ${clientName ?? ""} успешно отправлено`;
        } else if (response.data?.project_notification_status_text) {
          return `Уведомление клиенту ${clientName ?? ""} не отправлено. Ошибка: ` + response.data?.project_notification_status_text;
        }
      })();
      const obj: INotification = {
        id: "",
        created_at: "",
        deal_id: response.dealId,
        lead_id: null,
        created_by: user.id,
        comment: comment ?? "",
        notify_at: dayjs().format(),
        postponed_until: "",
        processed: false,
        was_read: false,
        isSuccess: response.data.project_notification_status === "SUCCESS",
      };
      setMailingNotification([obj]);
    }
    await refetch();
    return response;
  };

  const updateDealsList = () => {
    refetch();
  };

  const alertUpdate = () => {
    alert("success", content.alert_messages.deals.edit.success_deal);
  };

  const openModalCreate = () => {
    setIsModalCreateOpen(true);
  };

  const closeModalCreate = () => {
    setIsModalCreateOpen(false);
  };

  const openModalDetail = (id: string) => {
    history.push(`deals?deal_id=${id}`);
  };

  const closeModalDetail = () => {
    history.push(`deals`);
  };

  // const onlyMyDealsHandler = () => {
  //   const currentIsOnlyMyDealsValue = !isOnlyMyDeals;
  //   setIsOnlyMyDeals(currentIsOnlyMyDealsValue);
  //   refetch().then((response) => {
  //     if (response.data?.data) {
  //       const dealsForKanban = response.data?.data.groups.map((group) => {
  //         const cards = group.deals.map((deal) => ({ ...deal, client_id: deal.client_fullname }) as IDeal);
  //         return {
  //           id: statuses.find((status) => status.value === group.status)?.id ?? "",
  //           name: statuses.find((status) => status.value === group.status)?.name ?? group.status,
  //           cards: currentIsOnlyMyDealsValue
  //             ? sortDealsByCurrentOption({
  //                 deals: cards.filter((deal) => deal.created_by === user.id),
  //                 currentOption: currentSortOption,
  //                 isSeeReminders: onlyEmployee.includes(user.role as roleTypes),
  //               })
  //             : sortDealsByCurrentOption({
  //                 deals: cards,
  //                 currentOption: currentSortOption,
  //                 isSeeReminders: onlyEmployee.includes(user.role as roleTypes),
  //               }),
  //           value: group.status,
  //           disabled: false,
  //         };
  //       });
  //       setKanbanData(dealsForKanban);
  //     }
  //   });
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSortOptionChange = (value: SortOptionsList) => {
    setCurrentSortOption(value);
    refetch();
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  useEffect(() => {
    updateDealsList();
  }, [debouncedSearch]);

  useEffect(() => {
    if (statusesData) {
      const filteredStatuses = statusesData.filter((item) => !item.disabled);
      setStatuses(filteredStatuses);
    }
  }, [statusesData]);

  useEffect(() => {
    if (statuses.length === 0) {
      return;
    }
    if (!deals?.data.groups) {
      return;
    }

    if (statuses && statuses.length && JSON.stringify(firstStatusesDataRef.current) !== JSON.stringify(statuses)) {
      firstStatusesDataRef.current = cloneDeep(statuses);
    }

    const savedStatuses = firstStatusesDataRef.current;

    const dealsForKanban = deals?.data.groups.map((group) => {
      const cards = group.deals.map((deal) => ({ ...deal, client_id: deal.client_id }) as IDeal);
      return {
        id: savedStatuses.find((status) => status.value === group.status)?.id ?? "",
        name: savedStatuses.find((status) => status.value === group.status)?.name ?? group.status,
        cards: isOnlyMyDeals
          ? sortDealsByCurrentOption({
              deals: cards.filter((deal) => deal.created_by === user.id),
              currentOption: currentSortOption,
              isSeeReminders: onlyEmployee.includes(user.role as Role),
            })
          : sortDealsByCurrentOption({
              deals: cards,
              currentOption: currentSortOption,
              isSeeReminders: onlyEmployee.includes(user.role as Role),
            }),
        value: group.status,
        disabled: false,
      };
    });
    setKanbanData(dealsForKanban);
  }, [deals, currentSortOption, statuses]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ComponentError />;
  }

  return (
    <>
      {contextHolder}
      <div className={styles.dealsPage__container}>
        <div className={styles.dealsPage__header}>
          <PageTitle title="Сделки" />
          <ValidateComponentByRole requiredRoles={onlyEmployee}>
            <ButtonCustom maxWidth="200px" className={styles.dealsPage__header_custom_button} onClick={openModalCreate} bgColor="orange">
              <span>{content.deals.header.create_deal}</span>
            </ButtonCustom>
          </ValidateComponentByRole>
          <DealCardCreate
            open={isModalCreateOpen}
            onCancel={closeModalCreate}
            updateDealsList={updateDealsList}
            openModalDetail={openModalDetail}
          />
          {dealID && (
            <DealCardDetail
              id={dealID}
              onCancel={closeModalDetail}
              statusesData={statuses}
              updateDealsList={updateDealsList}
              alertUpdate={alertUpdate}
            />
          )}
          <ButtonCustom maxWidth="100px" onClick={() => setIsFiltersOpen(true)} bgColor={"gray"}>
            <span>Фильтры</span>
          </ButtonCustom>
          <SelectCustom
            options={dealFiltersData?.data.map((item) => ({ label: item.name, value: item.id ?? item.name })) ?? []}
            onChange={(value) => {
              setSelectedFilter(dealFiltersData?.data.find((item) => item.id === value));
              setTimeout(() => {
                refetch();
              }, 500);
            }}
            className="dealsPage__header_filter"
            placeholder="Выберите фильтр"
            width="200px"
            value={selectedFilter?.id}
            allowClear
            showSearch
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          />
          <SearchBar
            className={styles.dealsPage__header_search}
            placeholder={content.deals.header.search_deals}
            suffix={<SearchIcon />}
            onChange={handleChange}
            value={search}
          />
          {/* <ValidateComponentByRole requiredRoles={onlyEmployee}>
              <ButtonCustom
                className={isOnlyMyDeals ? styles.dealsPage__header_show_deals_active : styles.dealsPage__header_show_deals}
                maxWidth="220px"
                onClick={() => onlyMyDealsHandler()}
                bgColor={"gray"}
              >
                <span>{!isOnlyMyDeals ? content.deals.header.show_my_deals : content.deals.header.show_all_deals}</span>
              </ButtonCustom>
            </ValidateComponentByRole> */}
          <SelectCustom
            options={sortOptions}
            onChange={(value) => handleSortOptionChange(value as SortOptionsList)}
            className="dealsPage__header_sort"
            placeholder="Сортировка сделок"
            width="225px"
            value={currentSortOption}
          />
          <ValidateComponentByRole requiredRoles={content.statuses_settings.requiredRole}>
            <ButtonCustom
              maxWidth="70px"
              className={styles.dealsPage__header_custom_button}
              onClick={() => setIsStatusSettingsOpen(true)}
              bgColor="darkGrey"
            >
              <SettingsIcon />
            </ButtonCustom>
          </ValidateComponentByRole>
          <ValidateComponentByRole requiredRoles={[...onlyLPR]}>
            <DownloadDealsExcelReportButton />
          </ValidateComponentByRole>
          <ModalCustom
            title={"Фильтрация сделок"}
            isOpen={isFiltersOpen}
            handleCloseModal={() => setIsFiltersOpen(false)}
            centered={false}
            width={800}
            footer={null}
            onCancel={() => setIsFiltersOpen(false)}
            destroyOnClose
          >
            <FiltersSettings />
          </ModalCustom>
          <ModalCustom
            title={"Настройка статусов сделок"}
            isOpen={isStatusSettingsOpen}
            handleCloseModal={() => setIsStatusSettingsOpen(false)}
            centered={false}
            width={600}
            footer={null}
            onCancel={() => setIsStatusSettingsOpen(false)}
            destroyOnClose
          >
            <StatusesSettings
              closeModal={() => setIsStatusSettingsOpen(false)}
              refetchDeals={refetch}
              refetchDealStatuses={refetchDealStatuses}
            />
          </ModalCustom>
        </div>
        <div className={styles.dealsPage__data_container}>
          <div className={styles.dealsPage__data}>
            <KanbadBoardContainer
              data={kanbanData}
              onCardClick={openModalDetail}
              changeStatusRequest={handleStatusChange}
              isDragDisabled={isKanbanDragAndDropDisabled}
              invalidateKanbanBoardCache={invalidateAllDealsCache}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DealsPage;
