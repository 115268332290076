import { useUserNotificationsMutations } from "widgets/UserNotificationsOrchestrator/shared/hooks/useUserNotificationsMutations";
import { useUser } from "context/useUser";
import { useNotificationsContext } from "context/useNotifications";
import { getRemindersNotifications, INotification } from "services/Reminders";
import { useNotificationContext } from "context/useAntdNotification";
import React, { useCallback, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { Dropdown, MenuProps } from "antd";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { useQuery } from "react-query";
import { Role } from "settings/content";
import { useHistory } from "react-router-dom";
import "./UserNotificationsStyles.scss";
import calendar from "dayjs/plugin/calendar";
import { buildUrlByNotification } from "widgets/UserNotificationsOrchestrator/shared/utils/buildUrlByNotification";

dayjs.extend(calendar);

export const Context = React.createContext({ name: "notificationsContext" });

export const useUserNotificationsOrchestrator = () => {
  const history = useHistory();

  const { user } = useUser();

  const { api, contextHolder } = useNotificationContext();
  const contextValue = useMemo(() => ({ name: "NotificationsContext" }), []);

  const { notifications, setNotifications, mailingNotification, setMailingNotification } = useNotificationsContext();

  const { closeNotification, delayNotification, readNotification } = useUserNotificationsMutations();

  const removeNotification = (notification: INotification) => {
    setNotifications((prevState) => {
      api.destroy(notification.id);
      return prevState.filter((x) => x.id !== notification.id);
    });
  };

  const onNotificationClick = useCallback(
    async (notification: INotification, isNeedToRead?: boolean) => {
      const notificationSourceUrl = buildUrlByNotification(notification);

      if (isNeedToRead) {
        const request = await readNotification(notification.id);

        if (request) {
          removeNotification(notification);
          if (notificationSourceUrl) {
            history.push(notificationSourceUrl);
            return;
          }
        }
        return;
      }

      setMailingNotification([]);

      if (notificationSourceUrl) {
        removeNotification(notification);
        history.push(notificationSourceUrl);
        return;
      }
    },
    [readNotification, history, api],
  );

  const createLabel = (notification: INotification, label: React.ReactNode, minutes: number) => (
    <ButtonCustom
      onClick={async () => {
        await delayNotification({ notificationId: notification.id, minutes });
        removeNotification(notification);
      }}
      className="dropdown-btn"
      maxWidth="100%"
      isTransparent
    >
      <span>{label}</span>
    </ButtonCustom>
  );

  const openNotification = (notification: INotification) => {
    const notificationType = notification.isSuccess ? "info" : "error";

    api[notificationType]({
      onClose: async () => {
        await closeNotification(notification.id);
        api.destroy(notification.id);
      },
      message: (
        <div className="notification-title-block">
          <span className="notification-title-name" style={{ color: !notification.isSuccess ? "inherit" : "black" }}>
            {notification.id ? "Напоминание" : notification.isSuccess ? "Сделка завершена" : "Ошибка отправления"}
          </span>
          <span className="notification-title-time">
            {dayjs(notification.postponed_until ? notification.postponed_until : notification.notify_at).calendar(null, {
              sameDay: "[Сегодня в] HH:mm", // Тот же день ( Today at 2:30 AM )
              nextDay: "[Завтра в] HH:mm", // Следующий день ( Tomorrow at 2:30 AM )
              nextWeek: "dddd", // Следующая неделя ( Sunday at 2:30 AM )
              lastDay: "[Вчера в] HH:mm", // Накануне ( Yesterday at 2:30 AM )
              lastWeek: "[На прошлой неделе]", // Прошлая неделя ( Last Monday at 2:30 AM )
              sameElse: "DD/MM/YYYY HH:mm", // Все остальное ( 7/10/2011 )
            })}
          </span>
        </div>
      ),
      description: (
        <Context.Consumer>
          {() => {
            const items: MenuProps["items"] = [
              {
                key: "hour",
                label: createLabel(notification, "1 час", 60),
              },
              {
                key: "halfhour",
                label: createLabel(notification, "30 минут", 30),
              },
              {
                key: "tenMinutes",
                label: createLabel(notification, "10 минут", 10),
              },
              {
                key: "fiveMinutes",
                label: createLabel(notification, "5 минут", 5),
              },
            ];
            return (
              <div className="notification-text-block">
                <div className="notification-text">{`${notification.comment}`}</div>
                <div className="notification-buttons">
                  {notification.id && (
                    <ButtonCustom className="notification-btn" maxWidth="fit-content">
                      <Dropdown menu={{ items }} overlayStyle={{ zIndex: 3000 }} placement="top">
                        <span>Отложить</span>
                      </Dropdown>
                    </ButtonCustom>
                  )}
                  <ButtonCustom
                    onClick={() => onNotificationClick(notification, !!notification.id)}
                    className="notification-btn"
                    maxWidth="fit-content"
                  >
                    <span>К {notification.deal_id ? "сделке" : notification.lead_id ? "лиду" : ""}</span>
                  </ButtonCustom>
                </div>
              </div>
            );
          }}
        </Context.Consumer>
      ),
      placement: "bottomRight",
      duration: null,
      key: notification.id,
    });
  };

  const { data: userNotifications } = useQuery({
    queryFn: () => getRemindersNotifications(),
    queryKey: ["userNotifications"],
    enabled: user.role === Role.DEPARTMENT_EMPLOYEE,
    refetchInterval: 30000,
  });

  useEffect(() => {
    const reversedNotifications = [...notifications].reverse();
    if (mailingNotification.length) {
      reversedNotifications.unshift(mailingNotification[0]);
    }
    reversedNotifications.forEach(openNotification);
  }, [notifications, mailingNotification]);

  useEffect(() => {
    if (userNotifications?.data) {
      setNotifications(userNotifications.data);
    }
  }, [userNotifications]);

  return {
    contextHolder,
    contextValue,
  };
};
