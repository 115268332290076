import React, { useEffect, useRef, useState } from "react";
import styles from "./MailingEmailModal.module.scss";
import {
  IShop,
  getEmailBinding,
  getEmailConnections,
  postEmailBinding,
  IEmailConnection,
  IEmailBinding,
  getShopNotificationSettingsById,
  postShopNotificationSettingsById,
  INotificationsSettings,
} from "services/Shops";
import { useMutation, useQuery } from "react-query";
import { useCities } from "context/useCities";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import { ReactComponent as PlusIcon } from "assets/icons/add.svg";
import { ReactComponent as TurnOffIcon } from "assets/icons/turnOff.svg";
import { ReactComponent as DeleteSmallIcon } from "assets/icons/smallDelete.svg";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ValidateComponentByRole from "components/ValidateComponentByRole/ValidateComponentByRole";
import { onlyAdmin, onlyLPR, Role } from "settings/content";
import { useUser } from "context/useUser";

type Props = {
  emailMailingModalId: string;
  shop: IShop | undefined;
  closeModal: () => void;
  refetchShops: () => void;
  openAddEmailModal: (value: string) => void;
  openDeleteEmailConnectionModal: (value: string) => void;
  openDeleteEmailBindingModal: (value: string) => void;
  setEmailSampleModal: (id: string) => void;
};

const MailingEmailModal = ({
  shop,
  emailMailingModalId,
  closeModal,
  refetchShops,
  openAddEmailModal,
  openDeleteEmailBindingModal,
  openDeleteEmailConnectionModal,
  setEmailSampleModal,
}: Props) => {
  const { user } = useUser();
  const ref: React.RefObject<HTMLDivElement> = useRef(null);

  const { cities } = useCities();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<IEmailConnection>();
  const [emailBinding, setEmailBinding] = useState<IEmailBinding[]>([]);
  const [emailConnections, setEmailConnections] = useState<IEmailConnection[]>([]);
  const [notificationSettings, setNotificationSettings] = useState<INotificationsSettings>();

  const { data: emailBindingData } = useQuery({
    queryFn: () => getEmailBinding({ shop_id: emailMailingModalId }),
    queryKey: [`shop_email_${emailMailingModalId}`],
    refetchInterval: 10000,
  });

  const { data: emailConnectionsData } = useQuery({
    queryFn: () => getEmailConnections(),
    queryKey: [`email_connections${emailMailingModalId}`],
    refetchInterval: 10000,
  });

  const { data: isAvalableData } = useQuery({
    queryFn: () => getShopNotificationSettingsById({ shop_id: emailMailingModalId }),
    queryKey: [`isAvalableData-${emailMailingModalId}`],
    onSuccess: (data) => {
      setNotificationSettings(data.data);
    },
  });

  const { mutateAsync: onPostEmailBinding } = useMutation({
    mutationFn: postEmailBinding,
  });

  const { mutateAsync: onAvailabilityChange } = useMutation({
    mutationFn: postShopNotificationSettingsById,
  });

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  useEffect(() => {
    if (emailBindingData?.data) {
      setEmailBinding(emailBindingData.data);
    }
    if (emailConnectionsData?.data) {
      setEmailConnections(emailConnectionsData.data);
    }
  }, [emailBindingData, emailConnectionsData]);

  useEffect(() => {
    if (!Array.isArray(emailConnections)) {
      return;
    }
    const result = emailConnections.find((con) => con.email === emailBinding[0]?.email);
    setSelectedEmail(result);
  }, [emailBinding, emailConnections]);

  if (!shop || !emailBindingData || !emailConnectionsData) {
    return null;
  }

  const onAvalabilityChange = (e: CheckboxChangeEvent): void => {
    onAvailabilityChange({
      shop_id: emailMailingModalId,
      allow_override_for_deal: e.target.checked,
      additional_footer_text: notificationSettings?.additional_footer_text || "",
    }).then((response) => {
      if (response.status === 200) {
        setNotificationSettings((prev) => ({
          ...prev,
          allow_override_for_deal: e.target.checked,
          additional_footer_text: prev?.additional_footer_text || "",
          shop_id: prev?.shop_id || "",
        }));
      }
    });
  };

  return (
    <div className={styles.MailingModal} ref={ref}>
      <ValidateComponentByRole requiredRoles={[...onlyAdmin, ...onlyLPR]}>
        <ButtonCustom
          className={styles.MailingModal__sampleEditBtn}
          onClick={() => {
            setEmailSampleModal(shop.id);
          }}
          isTransparent
          maxWidth="fit-content"
        >
          <span>Настройка подписи</span>
        </ButtonCustom>
      </ValidateComponentByRole>
      <div className={styles.MailingModal__row}>
        <span className={styles.MailingModal__label}>Название магазина:</span>
        <span className={styles.MailingModal__value}>{shop.name}</span>
      </div>
      <div className={styles.MailingModal__row}>
        <span className={styles.MailingModal__label}>Регион:</span>
        <span className={styles.MailingModal__value}>{cities.find((item) => item.id === shop.city_id)?.name}</span>
      </div>
      <div className={styles.MailingModal__row}>
        <span className={styles.MailingModal__label}>Адрес:</span>
        <span className={styles.MailingModal__value}>{shop.address}</span>
      </div>
      <div
        className={styles.MailingModal__row}
        style={{ flexDirection: "column", alignItems: "flex-start", gap: "5px", position: "relative" }}
      >
        <span className={styles.MailingModal__label}>Привязанная почта:</span>
        <div className={styles.MailingModal__select_block}>
          <SelectCustom
            placeholder="Выберите почту"
            width="100%"
            value={selectedEmail?.email}
            onChange={(value) => {
              if (!Array.isArray(emailConnections)) {
                return;
              }
              if (!value) {
                setSelectedEmail(undefined);
              }
              const result = emailConnections.find((connection) => connection.email === value);
              if (!result) {
                return;
              }
              setSelectedEmail(result);
            }}
            open={isOpen}
            allowClear
            onDropdownVisibleChange={handleOpenChange}
            disabled={!onlyAdmin.includes(user.role as Role)}
            options={
              Array.isArray(emailConnections)
                ? emailConnections.map((item) => {
                    return {
                      value: item.email,
                      label: (
                        <div className={`${styles.MailingModal__select_label}`}>
                          <span>{item.email}</span>
                          <div className={styles.MailingModal__select_btns}>
                            {emailBinding && emailBinding.length && emailBinding[0].email === item.email ? (
                              <ValidateComponentByRole requiredRoles={onlyAdmin}>
                                <ButtonCustom
                                  className={styles.MailingModal__select_btn}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openDeleteEmailBindingModal(item.id);
                                    handleOpenChange(false);
                                  }}
                                  isTransparent
                                  maxWidth="25px"
                                >
                                  <TurnOffIcon height={"15px"} width={"15px"} />
                                </ButtonCustom>
                              </ValidateComponentByRole>
                            ) : null}
                            <ValidateComponentByRole requiredRoles={onlyAdmin}>
                              <ButtonCustom
                                className={styles.MailingModal__select_btn}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openDeleteEmailConnectionModal(item.id);
                                  handleOpenChange(false);
                                }}
                                isTransparent
                                maxWidth="25px"
                              >
                                <DeleteSmallIcon height={"15px"} width={"15px"} />
                              </ButtonCustom>
                            </ValidateComponentByRole>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
            showSearch
            filterOption={(input: any, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </div>
        {isAvalableData?.status === 200 && (
          <ValidateComponentByRole requiredRoles={onlyAdmin}>
            <Checkbox
              name={"availablity-for-choosing"}
              // value={item.disabled}
              checked={notificationSettings?.allow_override_for_deal}
              onChange={onAvalabilityChange}
              style={{ display: "flex", alignItems: "center" }}
            >
              Позволить продавцам выбирать рассылку
            </Checkbox>
          </ValidateComponentByRole>
        )}
        <ValidateComponentByRole requiredRoles={onlyAdmin}>
          <ButtonCustom
            className={styles.MailingModal__add_btn}
            onClick={(e) => {
              e.stopPropagation();
              openAddEmailModal("1");
            }}
            isTransparent
            maxWidth="25px"
          >
            <PlusIcon height={"14px"} width={"14px"} />
          </ButtonCustom>
        </ValidateComponentByRole>
      </div>
      <div className={styles.MailingModal__footer_btns}>
        <ButtonCustom className={styles.MailingModal__footer_btn} onClick={closeModal} maxWidth="100px" bgColor="gray">
          <span>Отмена</span>
        </ButtonCustom>
        <ButtonCustom
          className={styles.MailingModal__footer_btn}
          onClick={() => {
            onPostEmailBinding({ connection_id: selectedEmail?.id ?? "", shop_id: emailMailingModalId }).then((response) => {
              if (response.status === 200) {
                refetchShops();
                closeModal();
              }
            });
          }}
          maxWidth="100px"
          bgColor="blue"
          disabled={!selectedEmail}
        >
          <span>Подключить</span>
        </ButtonCustom>
      </div>
    </div>
  );
};

export default MailingEmailModal;
