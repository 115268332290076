import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { DraggableLocation, DropResult } from "react-beautiful-dnd";
import { getDealStatuses, IDeals, IDealStatusResponse } from "services/Deals";
import { ILead } from "services/Leads";
import { useQuery } from "react-query";
import { isLeadStatusDisabled } from "pages/LeadsPage/EditLead/EditLead";
import { useUser } from "context/useUser";
import { LeadStatuses } from "pages/LeadsPage/constants";
import { message } from "antd";
import { NoticeType } from "antd/es/message/interface";

export interface ColumnType extends IDealStatusResponse {
  cards: IDeals[] | ILead[];
}

interface ILeadStatus {
  id: string;
  value: string;
  disabled: boolean;
  name: string;
  editable: boolean;
}

type DragDropProps = (source: DraggableLocation, destination: DraggableLocation) => void;

type RowDropshadowProps = (event: any, destinationIndex: number, sourceIndex: number) => void;

type ColumnDropshadowProps = (event: any, destinationIndex: number, sourceIndex: number) => void;

type RowDropshadow = { marginTop: number; height: number };
type ColDropshadow = { marginLeft: number; height: number };

type DragDropContextProps = {
  handleDragEnd: (result: DropResult) => void;
  handleDragStart: (event: any) => void;
  handleDragUpdate: (event: any) => void;
  rowDropshadowProps: RowDropshadow;
  colDropshadowProps: ColDropshadow;
  columns: ColumnType[];
  setColumns: React.Dispatch<React.SetStateAction<ColumnType[]>>;
};

const DragDropContext = React.createContext<DragDropContextProps | undefined>(undefined);

const getDraggedElement = (draggableId: string) => {
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedElement = document.querySelector(domQuery);
  return draggedElement;
};

const getUpdatedChildrenArray = (draggedElement: Element, destinationIndex: number, sourceIndex: number) => {
  const child: Element[] = Array.from(draggedElement!.parentNode!.children);

  if (destinationIndex === sourceIndex) {
    return child;
  }

  const draggedItem = child[sourceIndex];

  child.splice(sourceIndex, 1);

  return child.splice(0, destinationIndex, draggedItem);
};

const getStyle = (
  updatedChildrenArray: Element[],
  destinationIndex: number,
  property: any,
  clientDirection: "clientHeight" | "clientWidth",
) =>
  updatedChildrenArray.slice(0, destinationIndex).reduce((total, curr) => {
    const style = window.getComputedStyle(curr);

    const prop = parseFloat(style[property]);
    return total + curr[clientDirection] + prop;
  }, 0);

interface DragDropProviderProps extends PropsWithChildren {
  data: ColumnType[];
  changeStatusRequest: ({ id, value }: { id: string; value: string }) => Promise<any>;
  invalidateKanbanBoardCache: () => void;
  isLeads?: boolean;
}

const DragDropProvider: React.FC<DragDropProviderProps> = ({
  children,
  data,
  changeStatusRequest,
  isLeads,
  invalidateKanbanBoardCache,
}) => {
  const { user } = useUser();
  const [messageApi, contextHolder] = message.useMessage();

  const [columns, setColumns] = useState<ColumnType[]>(data);
  const [dealStatuses, setDealStatuses] = useState<IDealStatusResponse[]>([]);
  const [leadStatuses, setLeadStatuses] = useState<ILeadStatus[]>([]);

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const { data: dealStatusesData } = useQuery({
    queryFn: () => getDealStatuses(),
    queryKey: ["statuses"],
    onSuccess: (data) => {
      if (!data) {
        return;
      }
      setDealStatuses(data);
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    setColumns(data);

    if (isLeads) {
      setLeadStatuses(
        data.map((item) => {
          return {
            id: item.id,
            value: item.value,
            disabled: item.disabled,
            name: item.name,
            editable: false,
          };
        }),
      );
    }
  }, [data]);

  const [colDropshadowProps, setColDropshadowProps] = useState<ColDropshadow>({
    marginLeft: 0,
    height: 0,
  });
  const [rowDropshadowProps, setRowDropshadowProps] = useState<RowDropshadow>({
    marginTop: 0,
    height: 0,
  });

  const moveRowSameColumn: DragDropProps = (source, destination) => {
    setColumns((prev) => {
      const updated = [...prev];

      const [{ cards }] = updated.filter(({ id }) => id === source.droppableId);

      const [removed] = cards.splice(source.index, 1);

      cards.splice(destination.index, 0, removed as any);
      return updated;
    });
  };

  const moveRowDifferentColumn: DragDropProps = (source, destination) => {
    setColumns((prev) => {
      const updated = [...prev];
      const [sourceColumn] = updated.filter(({ id }) => id === source.droppableId);
      const [destinationColumn] = updated.filter(({ id }) => id === destination.droppableId);

      const sourceRow = sourceColumn.cards;
      const destinationRow = destinationColumn.cards;

      const [removed] = sourceRow.splice(source.index, 1);

      destinationRow.splice(destination.index, 0, removed as any);

      return updated;
    });
  };
  const handleRowMove: DragDropProps = (source, destination) => {
    // if (source.droppableId === destination.droppableId) {
    //   return;
    // }
    if (source.droppableId !== destination.droppableId) {
      moveRowDifferentColumn(source, destination);
    } else {
      moveRowSameColumn(source, destination);
    }
  };

  const handleDropshadowRow: RowDropshadowProps = (event, destinationIndex, sourceIndex) => {
    const draggedElement = getDraggedElement(event.draggableId);
    if (!draggedElement) {
      return;
    }
    const { clientHeight } = draggedElement as Element;
    const updatedChildrenArray: Element[] = getUpdatedChildrenArray(draggedElement as Element, destinationIndex, sourceIndex);
    const marginTop = getStyle(updatedChildrenArray, destinationIndex, "marginBottom", "clientHeight");
    setRowDropshadowProps({
      height: clientHeight + 2,
      marginTop: marginTop + 2 * destinationIndex,
    });
  };

  const handleDropshadowColumn: ColumnDropshadowProps = (event, destinationIndex, sourceIndex) => {
    const draggedElement: Element | Node | null = getDraggedElement(event.draggableId)!.parentNode!.parentNode;
    if (!draggedElement) {
      return;
    }
    const { clientHeight } = draggedElement as Element;
    const updatedChildrenArray: Element[] = getUpdatedChildrenArray(draggedElement as Element, destinationIndex, sourceIndex);
    const marginLeft = getStyle(updatedChildrenArray, destinationIndex, "marginRight", "clientWidth");
    setColDropshadowProps({
      height: clientHeight,
      marginLeft,
    });
  };

  const handleDragUpdate = (event: { type?: any; source?: any; destination?: any }) => {
    const { source, destination } = event;
    if (!destination) {
      return;
    }
    if (event.type === "column") {
      handleDropshadowColumn(event, destination.index, source.index);
    } else {
      handleDropshadowRow(event, destination.index, source.index);
    }
  };

  const handleDragStart = (event: { source: { index: any }; type: string }) => {
    const { index } = event.source;
    if (event.type === "column") {
      handleDropshadowColumn(event, index, index);
    } else {
      handleDropshadowRow(event, index, index);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const statuses = isLeads ? leadStatuses : dealStatuses;
    const destinationStatus = statuses.find((status) => status.id === result.destination?.droppableId);
    const sourceStatus = statuses.find((status) => status.id === result.source?.droppableId);
    const statusValue = destinationStatus?.value;
    const isStatusDisabled = !isLeads
      ? false
      : isLeadStatusDisabled({
          currentStatus: source.droppableId as LeadStatuses,
          targetStatus: destination.droppableId as LeadStatuses,
          user: user,
        });

    if (!statusValue) {
      return;
    }
    if (isStatusDisabled) {
      alert("error", `Нельзя переместить лид из статуса "${sourceStatus?.name}" в статус "${destinationStatus?.name}"`);
      return;
    }

    handleRowMove(source, destination);
    changeStatusRequest({ id: result.draggableId, value: statusValue }).then((response) => {
      if (response.status !== 200) {
        invalidateKanbanBoardCache();
        handleRowMove(destination, source);
      }
    });
  };

  return (
    <DragDropContext.Provider
      value={{
        handleDragEnd,
        handleDragStart,
        handleDragUpdate,
        rowDropshadowProps,
        colDropshadowProps,
        columns,
        setColumns,
      }}
    >
      {contextHolder}
      {children}
    </DragDropContext.Provider>
  );
};

export function useDragDrop() {
  const context = useContext(DragDropContext);
  if (context === undefined) {
    throw new Error("useDragDrop must be used inside DragDropProvider");
  }

  return context;
}

export default DragDropProvider;
