import React, { useEffect, useState } from "react";
import styles from "pages/Organization/Keys/Workstations.module.scss";
import InputCustom from "components/ui/InputCustom/InputCustom";
import SelectCustom, { IOptionsProps } from "components/ui/SelectCustom/SelectCustom";
import { IWorkstation, editWorkstation, getWorkstationById } from "services/Workstation";
import ModalCustom from "components/ModalCustom/ModalCustom";
import { getDepartment } from "services/Department";
import { useMutation, useQuery } from "react-query";
import { getLicense } from "services/License";
import Loader from "components/ui/Loader/Loader";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { useWorkstations } from "context/useWorkstations";
import { useShops } from "context/useShops";

type Props = {
  editWorkstationId: string;
  setEditWorkstationId: React.Dispatch<React.SetStateAction<string>>;
};

const EditWorkstation = ({ editWorkstationId, setEditWorkstationId }: Props) => {
  const { refetch } = useWorkstations();
  const { shops } = useShops();
  const [workstation, setWorkstation] = useState<IWorkstation>();
  const [departmentOptions, setDepartmentsOptions] = useState<IOptionsProps[]>([]);
  const [licencesOptions, setLicencesOptions] = useState<IOptionsProps[]>([]);

  const onChange = ({ name, value }: { name: string; value: string }) => {
    if (workstation) {
      setWorkstation((prev) => {
        if (!prev) {
          return prev;
        }
        return { ...prev, [name]: value };
      });
    }
  };

  const onSubmit = async () => {
    if (!workstation || !workstation?.department_id || !workstation?.license_id || !workstation?.name) {
      return;
    }
    const request = await onEditWorkstation({
      department_id: workstation.department_id,
      workstation_id: workstation.id,
      license_id: workstation.license_id,
      name: workstation.name,
    });
    if (request.status === 200) {
      refetch();
      refetchLicences();
      setEditWorkstationId("");
    }
  };

  useEffect(() => {
    if (editWorkstationId === "") {
      setWorkstation(undefined);
    }
    return () => {
      setWorkstation(undefined);
    };
  }, [editWorkstationId]);

  const { mutateAsync: onEditWorkstation } = useMutation({
    mutationFn: editWorkstation,
  });

  const { data: departmentData } = useQuery({
    queryFn: () => getDepartment(),
    queryKey: ["departments"],
    onSuccess: (data) => {
      if (!Array.isArray(data.data)) {
        return;
      }
      const options = data.data.map((item) => ({
        value: item.id,
        label: item.name + ` (${shops.find((shop) => shop.id === item.shop_id)?.name} )`,
      }));
      setDepartmentsOptions(options);
    },
  });

  const { data: licencesData, refetch: refetchLicences } = useQuery({
    queryFn: () => getLicense(),
    queryKey: ["licences"],
    onSuccess: (data) => {
      if (!Array.isArray(data.data)) {
        return;
      }
      const options = data.data
        .sort((a, b) => a.key.localeCompare(b.key))
        .map((item) => ({
          value: item.id,
          label: item.key,
          disabled: item.assigned,
        }));
      setLicencesOptions(options);
    },
  });

  const { data: workstationData } = useQuery({
    queryFn: () => getWorkstationById(editWorkstationId),
    queryKey: ["workstation", editWorkstationId],
    onSuccess: (data) => {
      setWorkstation(data);
    },
  });

  return (
    <ModalCustom
      isOpen={editWorkstationId !== ""}
      title={"Редактирование информации о рабочем месте"}
      handleCloseModal={() => {
        setEditWorkstationId("");
        refetchLicences();
      }}
      width={400}
      footer={null}
      centered
    >
      {!workstation ? (
        <Loader />
      ) : (
        <div className={styles.EditWorkstation__wrapper}>
          <InputCustom
            isError={!workstation.name || workstation.name.length <= 2}
            value={workstation.name ?? undefined}
            onChange={(e) => {
              onChange({ name: e.target.name, value: e.target.value });
            }}
            placeholder={"Название/номер рабочего места"}
            name={"name"}
          />
          <SelectCustom
            options={departmentOptions}
            onChange={(value) => {
              onChange({ name: "department_id", value });
            }}
            placeholder="Начните вводить для поиска отдела"
            width="100%"
            allowClear
            showSearch
            value={workstation.department_id && workstation.department_id !== "" ? workstation.department_id : undefined}
            isError={!workstation.department_id}
          />
          <SelectCustom
            options={licencesOptions}
            onChange={(value) => {
              onChange({ name: "license_id", value });
            }}
            placeholder="Начните вводить для поиска ключа"
            width="100%"
            allowClear
            showSearch
            value={workstation.license_id && workstation.license_id !== "" ? workstation.license_id : undefined}
            isError={!workstation.license_id}
          />
          <ButtonCustom
            maxWidth="150px"
            className={styles.EditWorkstation__edit_btn}
            onClick={onSubmit}
            disabled={!workstation.name || !workstation.license_id || !workstation.department_id}
          >
            <span>Сохранить</span>
          </ButtonCustom>
        </div>
      )}
    </ModalCustom>
  );
};

export default EditWorkstation;
