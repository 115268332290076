import { Modal } from "antd";
import React, { useState } from "react";
import styles from "pages/Gallery/GalleryCards/GalleryCards.module.scss";
import content from "settings/content";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import { INewProject, initialProject } from "pages/Gallery/GalleryCards/GalleryCards";
import InputCustom from "components/ui/InputCustom/InputCustom";
import { ISelect } from "components/ControlPanel/AnalyticsClients/AnalyticsClients";
import { useHistory } from "react-router-dom";

type Props = {
  dealId: string;
  closeProject: boolean;
  newProject: INewProject;
  roomTypes: ISelect[];
  handleSuccess: (data: string) => void;
  handleNewProjectChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
  handleModalSelect: (select: boolean) => void;
  handleNewProject: (project: INewProject) => void;
  handleCreateProject: (projectName: string, room_type_id: string, deal_id: string) => void;
};

const GalleryCardsModalCreateProject: React.FC<Props> = ({
  dealId,
  closeProject,
  newProject,
  handleNewProjectChange,
  roomTypes,
  handleSuccess,
  handleModalSelect,
  handleNewProject,
  handleCreateProject,
}) => {
  const history = useHistory();
  const [hint, setHint] = useState(false);

  return (
    <Modal
      className={"custom_shop_modal"}
      key={dealId + "projectname"}
      centered
      width={320}
      open={dealId !== ""}
      closeIcon={false}
      zIndex={99999}
      onCancel={() => {
        if (!closeProject) {
          setHint(true);
          return;
        }
        setHint(false);
        handleSuccess("");
        handleNewProject(initialProject);
        handleModalSelect(false);
      }}
      footer={null}
      maskClosable={false}
    >
      {hint && (
        <div className={styles.gallery_cards_container_warning}>
          <p>{content.alert_messages.projects.hint.hint_title}</p>
          <p>{content.alert_messages.projects.hint.hint_subtitle}</p>
          <div className={styles.gallery_cards_container_warning_btns}>
            <ButtonCustom onClick={() => setHint(false)} className={styles.blanks__close}>
              <span>{content.alert_messages.projects.continue}</span>
            </ButtonCustom>
            <ButtonCustom
              onClick={() => (
                handleSuccess(""), handleModalSelect(false), handleNewProject(initialProject), setHint(false), history.push("")
              )}
              isTransparent
            >
              <span>{content.alert_messages.projects.close}</span>
            </ButtonCustom>
          </div>
        </div>
      )}
      <div className={styles.gallery_cards_container__modal_header}>
        <span>{content.deals.detail_deal_card.buttons.title.create_project}</span>
        <ButtonCustom
          isTransparent
          maxWidth="20px"
          className={styles.gallery_cards_container__modal_close}
          onClick={() => handleSuccess("")}
        >
          <CloseIcon />
        </ButtonCustom>
      </div>
      <div className={styles.gallery_cards_container__modal_copy_body}>
        <div className={styles.gallery_cards_container__project_input}>
          <InputCustom
            onChange={handleNewProjectChange}
            value={newProject.name}
            name="name"
            placeholder={content.deals.detail_deal_card.placeholder.newProjectName}
            allowClear
          />
        </div>
        <div className={styles.gallery_cards_container__project_input}>
          <SelectCustom
            options={[...(roomTypes || [])]}
            onChange={(value) => handleNewProject({ ...newProject, room_type: value as string })}
            onDeselect={(value) => handleNewProject({ ...newProject, room_type: newProject.room_type })}
            placeholder={content.deals.detail_deal_card.placeholder.newProjectRoomType}
            width="100%"
            // mode="multiple"
          />
        </div>
        <ButtonCustom
          className={styles.custom_button}
          onClick={() => handleCreateProject(newProject.name, newProject.room_type, dealId)}
          disabled={newProject.name.length < 2 || !newProject.room_type.length}
          children={<span>{content.deals.detail_deal_card.buttons.title.create_project}</span>}
        />
      </div>
    </Modal>
  );
};
export default GalleryCardsModalCreateProject;
