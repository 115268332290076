import React from "react";
import { CustomButton } from "shared";
import { useOpenDeal } from "features/OpenDeal/shared";

interface OpenDealButtonProps {
  dealId?: string;
}

export const OpenDealButton: React.FC<OpenDealButtonProps> = ({ dealId }) => {
  const { openDealOnClick } = useOpenDeal(dealId);

  return <CustomButton onClick={openDealOnClick}>Открыть сделку</CustomButton>;
};
