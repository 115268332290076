import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl } from "env";

export interface ILicense {
  id: string;
  key: string;
  assigned: boolean;
}

export interface IGetLicense {
  status: string;
  data: ILicense[];
}

export async function getLicense(): Promise<IGetLicense> {
  return axios
    .get(baseUrl + "license", { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getLicense:", err.response);
      return err.response;
    });
}
