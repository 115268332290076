import axios from "axios";
import Cookies from "js-cookie";
import { LeadStatuses } from "pages/LeadsPage/constants";
import { IAddReminderResponse, IReminderRequest, IReminderResponse } from "./Reminders";
import { baseUrl5050 } from "env";

export enum leadHistoryTypesEnum {
  titleChange = "title_change",
  statusChange = "status_change",
  departmentChange = "department_change",
  responsibleUserChange = "responsible_user_change",
  budgetChange = "budget_change",
  clientChange = "client_change",
  promoChange = "promo_change",
  channelChange = "channel_change",
  reminder = "reminder",
}

export interface ILead {
  id: string;
  title: string | null;
  budget: string | null;
  status: LeadStatuses;
  client_id: string | null;
  organization_id: string | null;
  shop_id: string | null;
  department_id: string | null;
  responsible_user_id: string | null;
  channel_id: string | null;
  promo_id: string | null;
  created_at: string;
  updated_at: string | null;
  created_by: string | null;
  reminders_count: number;
  client_fullname: string | null;
}

export interface ILeadData {
  leads: ILead[];
  status: LeadStatuses;
}

export interface IGetLeadsList {
  data: {
    groups: ILeadData[];
  };
  status: number;
}

export interface ICreateLead {
  title: string;
  client_id: string;
  channel_id: string | null;
  promo_id: string | null;
}

export interface IEditLead {
  id: string | null;
  title: string | null;
  client_id: string | null;
  channel_id: string | null;
  promo_id: string | null;
}

export interface ILeadChannel {
  id: string;
  name: string;
}

export interface ILeadPromo {
  id: string;
  name: string;
  starts_at: string;
  ends_at: string;
}

export interface ILeadHistory {
  created_at: string;
  created_by: string;
  transaction_type: leadHistoryTypesEnum;
  transaction_value: {
    old_title: string | null | undefined;
    new_title: string | null | undefined;
    old_status: string | null | undefined;
    new_status: string | null | undefined;
    old_shop_id: string | null | undefined;
    old_department_id: string | null | undefined;
    new_shop_id: string | null | undefined;
    new_department_id: string | null | undefined;
    old_responsible_user_id: string | null | undefined;
    new_responsible_user_id: string | null | undefined;
    old_budget: string | null | undefined;
    new_budget: string | null | undefined;
    old_client_id: string | null | undefined;
    new_client_id: string | null | undefined;
    old_promo_id: string | null | undefined;
    new_promo_id: string | null | undefined;
    old_channel_id: string | null | undefined;
    new_channel_id: string | null | undefined;
    reminder_text?: string | null | undefined; /// этого нет в ответе, добавлено для сращивания с напоминаниями
  };
}

export async function getLeadsList({ search }: { search?: string }): Promise<IGetLeadsList> {
  const searchString = search ? `?search=${search}` : "";
  try {
    const request = await axios.get(baseUrl5050 + `lead/dashboard${searchString}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getLeadsList:");
    return {
      data: {
        groups: [],
      },
      status: err.request.status,
    };
  }
}

export async function getLeadById({ id }: { id: string }): Promise<{ data: ILead | null; status: number }> {
  if (!id) {
    return { data: null, status: 400 };
  }
  try {
    const request = await axios.get(baseUrl5050 + `lead/${id}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getLeadById:");
    return {
      data: null,
      status: err.request.status,
    };
  }
}

export async function getLeadHistoryById({ id }: { id: string }): Promise<{ data: ILeadHistory[]; status: number }> {
  if (!id) {
    return { data: [], status: 400 };
  }
  try {
    const request = await axios.get(baseUrl5050 + `lead/${id}/history`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getLeadHistoryById:");
    return {
      data: [],
      status: err.request.status,
    };
  }
}

export async function createLead(lead: ICreateLead): Promise<{ data: ILead | null; status: number }> {
  try {
    const request = await axios.post(baseUrl5050 + `lead`, lead, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> createLead:");
    return { data: null, status: err.request.status };
  }
}

export async function editLead({ channel_id, client_id, promo_id, title, id }: IEditLead): Promise<{ data: ILead | null; status: number }> {
  try {
    const request = await axios.put(
      baseUrl5050 + `lead/${id}`,
      { channel_id, client_id, promo_id, title },
      {
        headers: { Authorization: "Bearer " + Cookies.get("access_token") },
      },
    );
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> editLead:");
    return { data: null, status: err.request.status };
  }
}

export async function editLeadStatus({ id, status }: { id: string; status: string }): Promise<{ data: ILead | null; status: number }> {
  try {
    const request = await axios.put(
      baseUrl5050 + `lead/${id}/status`,
      { status },
      {
        headers: { Authorization: "Bearer " + Cookies.get("access_token") },
      },
    );
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> editLeadStatus:");
    return { data: null, status: err.request.status };
  }
}

export async function getLeadChannels(): Promise<{ data: ILeadChannel[]; status: number }> {
  try {
    const request = await axios.get(baseUrl5050 + `lead-channel`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getLeadChannels:");
    return { data: [], status: err.request.status };
  }
}

export async function getLeadPromos(): Promise<{ data: ILeadPromo[]; status: number }> {
  try {
    const request = await axios.get(baseUrl5050 + `lead-promo`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getLeadPromos:");
    return { data: [], status: err.request.status };
  }
}

export async function leadTransfer({
  id,
  department_id,
  responsible_user_id,
}: {
  id: string;
  department_id: string;
  responsible_user_id: string | null;
}): Promise<{ data: ILead | null; status: number }> {
  try {
    const request = await axios.put(
      baseUrl5050 + `lead/${id}/transfer`,
      { department_id, responsible_user_id },
      {
        headers: { Authorization: "Bearer " + Cookies.get("access_token") },
      },
    );
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> leadTransfer:");
    return { data: null, status: err.request.status };
  }
}

export async function getRemindersByLeadId(lead_id: string): Promise<IReminderResponse> {
  return axios
    .get(baseUrl5050 + `reminder/lead/${lead_id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        count: response.data.count,
        items: response.data.items,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getRemindersByLeadId:", err.response);
      return err.response;
    });
}

export async function addReminderToLead(reminder_request: IReminderRequest): Promise<IAddReminderResponse> {
  return axios
    .post(baseUrl5050 + `reminder/lead`, reminder_request, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        id: response.data.id,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> addReminderToLead:", err.response);
      return err.response;
    });
}
