import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl5054 } from "env";

export interface IMyLesson {
  user_name: string;
  user_email: string;
  user_phone: string;
  user_shop_address: string;
  user_shop_name: string;
  trainer_name: string;
  name: string;
  finished_at: string;
}

export interface IGetLessons {
  data: IMyLesson[];
  status: number;
}

export async function getMyLessons(interval: { dateFrom: string; dateTo: string }): Promise<IGetLessons> {
  return axios
    .get(baseUrl5054 + `lesson/me?start_date=${interval.dateFrom}&end_date=${interval.dateTo}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getMyLessons:", err.response);
      return err.response;
    });
}

export async function getShopLessons(interval: { dateFrom: string; dateTo: string }): Promise<IGetLessons> {
  return axios
    .get(baseUrl5054 + `lesson/shop?start_date=${interval.dateFrom}&end_date=${interval.dateTo}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getDepartmentLessons:", err.response);
      return err.response;
    });
}

export async function getOrganizationLessons(interval: { dateFrom: string; dateTo: string }): Promise<IGetLessons> {
  return axios
    .get(baseUrl5054 + `lesson/organization?start_date=${interval.dateFrom}&end_date=${interval.dateTo}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getOrganizationLessons:", err.response);
      return err.response;
    });
}
