import React, { useEffect, useState } from "react";
import styles from "./DetailCardHistoryStatuses.module.scss";
import { Avatar, Modal, message } from "antd";
import { getInitials } from "helpers/formatString";
import { IHistoryItem } from "./DetailCardHistoryStatuses";
import moment from "moment";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";

import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import InputCustom from "components/ui/InputCustom/InputCustom";
import { CheckOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { deleteComment, editComment } from "services/Deals";
import { useUser } from "context/useUser";
import content from "settings/content";
import { NoticeType } from "antd/es/message/interface";
import "./DetailCardHistoryStatuses.scss";

type Props = {
  historyItem: IHistoryItem;
  updateHistory: () => void;
};

const CommentComponent = ({ historyItem, updateHistory }: Props) => {
  const { user } = useUser();
  const [messageApi, contextHolder] = message.useMessage();

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>("");

  const { mutateAsync: onEditComment } = useMutation({
    mutationFn: editComment,
    onSuccess: () => {},
  });

  const { mutateAsync: onDeleteComment } = useMutation({
    mutationFn: deleteComment,
    onSuccess: () => {},
  });

  useEffect(() => {
    setCommentText(historyItem.text);
  }, [historyItem]);

  const handleCommentEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(e.target.value);
  };

  const handleEditComment = (commentText: string): void => {
    if (historyItem?.id && commentText !== "") {
      onEditComment({ comment_id: historyItem.id, text: commentText })
        .then((response) => {
          if (response?.status && response.status === 200) {
            setIsEditing(false);
            updateHistory();
          }
        })
        .catch((err) => {
          alert("error", content.alert_messages.deals.comment.error_comment_edit);
        });
    }
  };

  const handleDeleteComment = (): void => {
    if (historyItem?.id) {
      onDeleteComment({ comment_id: historyItem.id })
        .then((response) => {
          if (response?.status && response.status === 200) {
            updateHistory();
          }
        })
        .catch((err) => {
          alert("error", content.alert_messages.deals.comment.error_comment_delete);
        })
        .finally(() => setIsDeleting(false));
    }
  };

  return (
    <div className={styles.detailCardHistoryStatuses__message}>
      {contextHolder}
      <div className={styles.detailCardHistoryStatuses__message_header}>
        <Avatar
          size={35}
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            backgroundColor: "#FF8900",
            color: "#fff",
          }}
        >
          {getInitials(historyItem.name ?? "")}
        </Avatar>
        <div className={styles.detailCardHistoryStatuses__message_headerText}>
          <span className={styles.detailCardHistoryStatuses__message_author}>{historyItem.name}</span>
          <span className={styles.detailCardHistoryStatuses__message_date}>{moment(historyItem.created_at).format("D MMMM, HH:mm")}</span>
        </div>
        {historyItem.created_by === user.id && (
          <div className={styles.detailCardHistoryStatuses__message_header_controle}>
            <ButtonCustom
              className={styles.iconBtn}
              onClick={() => (isEditing ? handleEditComment(commentText) : setIsEditing(!isEditing))}
              maxWidth={"20px"}
              disabled={isEditing && commentText === "" ? true : false}
              isTransparent
            >
              {isEditing ? <CheckOutlined className={styles.saveIcon} /> : <EditIcon />}
            </ButtonCustom>
            {isEditing && (
              <ButtonCustom
                className={styles.iconBtn}
                onClick={() => {
                  setCommentText(historyItem.text);
                  setIsEditing(false);
                }}
                maxWidth={"20px"}
                isTransparent
              >
                <CrossIcon />
              </ButtonCustom>
            )}
            {!isEditing && (
              <ButtonCustom className={styles.iconBtn} onClick={() => setIsDeleting(true)} maxWidth={"20px"} isTransparent>
                <DeleteIcon />
              </ButtonCustom>
            )}
          </div>
        )}
      </div>
      <div className={styles.detailCardHistoryStatuses__message_text}>
        {isEditing ? <InputCustom name={historyItem.id} value={commentText} onChange={handleCommentEdit} /> : commentText}
      </div>
      <Modal
        title="Удалить сообщение?"
        open={isDeleting}
        onOk={() => handleDeleteComment()}
        onCancel={() => setIsDeleting(false)}
        okText={"Удалить"}
        cancelText={"Отмена"}
        okButtonProps={{ style: { background: "#FF0000", color: "#fff" } }}
        width={300}
        centered
        maskClosable={false}
      ></Modal>
    </div>
  );
};

export default CommentComponent;
