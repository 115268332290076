import { useMutation } from "react-query";
import { refreshToken } from "services/Authorization";
import { useEffect } from "react";
import Cookies from "js-cookie";

export const useRefreshToken = () => {
  const { mutateAsync: resetToken } = useMutation({
    mutationFn: refreshToken,
  });

  useEffect(() => {
    const intervalId: NodeJS.Timeout = setInterval(async () => {
      const token = Cookies.get("refresh_token");
      const accToken = Cookies.get("access_token");

      if (token && accToken) {
        await resetToken({ access_token: accToken, refresh_token: token });
      }
    }, 240000);

    return () => clearInterval(intervalId);
  }, [resetToken]);
};
