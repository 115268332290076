import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import styles from "./DealCard.module.scss";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { getInitials } from "helpers/formatString";
import { Avatar } from "antd";
import { ReactComponent as MessageIcon } from "assets/icons/message.svg";
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { onlyEmployee, Role } from "settings/content";
import { useUser } from "context/useUser";

export interface IDealCardProps {
  created_at?: string;
  title: string;
  client_fullname: string;
  isEditing?: boolean;
  reminders_count?: number;
  isLead?: boolean;
  responsible_user_id?: string;
}

const DealCard: React.FC<IDealCardProps> = ({
  created_at,
  title,
  client_fullname,
  isEditing,
  reminders_count,
  isLead = false,
  responsible_user_id,
}) => {
  const { user, users } = useUser();
  return (
    <div className={`${styles.dealCard} ${isEditing ? styles.selectedDeal : ""}`}>
      <div className={styles.dealCard__date}>
        <span className={styles.dealCard__author}>{users.find((item) => item.id === responsible_user_id)?.name}</span>
        <span>{created_at}</span>
      </div>
      <div className={styles.dealCard__container}>
        <div className={styles.dealCard__title}>{title ? title : "Без названия"}</div>
        <div className={styles.dealCard__block}>
          <UserIcon />
          <div className={styles.dealCard__name} title={client_fullname}>
            {client_fullname}
          </div>
        </div>
      </div>
      <div className={styles.dealCard__buttons}>
        <div className={styles.dealCard__buttons_block}>
          <Avatar
            size={25}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "12px",
              backgroundColor: "#FF8900",
              color: "#fff",
            }}
          >
            {getInitials(client_fullname)}
          </Avatar>
          {!isLead && (
            <ButtonCustom className={styles.dealCard__button} isTransparent>
              <MessageIcon />
            </ButtonCustom>
          )}
        </div>
        {onlyEmployee.includes(user.role as Role) && (
          <div className={styles.dealCard__buttons_block}>
            <ButtonCustom className={styles.dealCard__button} isTransparent>
              <AlertIcon />
            </ButtonCustom>
            {!reminders_count ? null : <span className={styles.dealCard__alert_count}>{reminders_count}</span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default DealCard;
