import React from "react";
import { Draggable, DraggableProvided, Droppable, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd";
import { ColumnType } from "./DragDropProvider";
import Row from "./Row";
import styles from "./KanbanBoard.module.scss";
import DealsColumnList from "pages/DealsPage/components/DealsColumnList/DealsColumnList";

type Props = {
  column: ColumnType;
  columnIndex: number;
  onCardClick: (id: string) => void;
  isDragDisabled: boolean;
};

const Column: React.FC<Props> = ({ column, columnIndex, onCardClick, isDragDisabled }) => {
  return (
    <Draggable draggableId={column.id} index={columnIndex} isDragDisabled>
      {(provided: DraggableProvided) => (
        <div className={styles.column__container} {...provided.draggableProps} ref={provided.innerRef}>
          <Droppable droppableId={column.id} type="task">
            {(prov: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <div className={styles.row__container} ref={prov.innerRef} {...prov.droppableProps}>
                <DealsColumnList
                  dataLength={column.cards.length}
                  title={column.name ? column.name : column.value}
                  key={column.name}
                  childrens={column.cards.map((card, taskIndex) => (
                    <Row key={card?.id} card={card} index={taskIndex} onCardClick={onCardClick} isDragDisabled={isDragDisabled} />
                  ))}
                />
                {prov.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default Column;
