import InputCustom from "components/ui/InputCustom/InputCustom";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { isPasswordValid } from "helpers/validation";
import styles from "components/ProfileSettings/ProfileSettingsCurrentUser/ProfileSettingsCurrentUser.module.scss";
import content, { onlyAdmin, onlyLPR, Role } from "settings/content";
import { useEffect, useState } from "react";
import { useUser } from "context/useUser";

interface IProps {
  oldPassword: string;
  handleOldPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  newPassword: string;
  handleNewPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  repeatPassword: string;
  handleRepeatPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updatePassword: () => void;
  disabled: boolean;
}
const ProfileSettingsPassword: React.FC<IProps> = ({
  oldPassword,
  newPassword,
  repeatPassword,
  handleOldPassword,
  handleNewPassword,
  handleRepeatPassword,
  updatePassword,
  disabled,
}) => {
  const { user } = useUser();
  const [repeatHint, setRepeatHint] = useState(false);
  const [passwordHint, setPasswordHint] = useState(false);

  useEffect(() => {
    if (!repeatPassword) {
      setRepeatHint(false);
    }
    if (repeatPassword !== newPassword) {
      setRepeatHint(true);
      return;
    }
    setRepeatHint(false);
  }, [repeatPassword, newPassword]);

  useEffect(() => {
    setPasswordHint(!!newPassword && !isPasswordValid(newPassword, user.role as Role));
  }, [newPassword]);

  const isPasswordError = (password: string, repeatPassword: string): boolean => {
    if (!password) {
      return false;
    }
    return !isPasswordValid(password, user.role as Role) || password !== repeatPassword;
  };

  return (
    <div className={styles.profile_settings__current_user_container}>
      <div className={styles.profile_settings__current_user_container_title}>{content.profile_settings.block_titles.change_password}</div>
      <div className={styles.profile_settings__current_user_container_password_block}>
        <InputCustom
          className={styles.profile_settings__block_input}
          type={"password"}
          value={oldPassword}
          onChange={handleOldPassword}
          placeholder={content.profile_settings.inputs.olg_password}
        />
        <InputCustom
          isError={isPasswordError(newPassword, repeatPassword)}
          className={styles.profile_settings__block_input}
          type={"password"}
          value={newPassword}
          onChange={handleNewPassword}
          placeholder={content.profile_settings.inputs.new_password}
        />
        {newPassword && (
          <span className={styles.profile_settings__current_user__passwordHint} style={{ color: passwordHint ? "#f00" : "#000" }}>
            {[...onlyLPR, ...onlyAdmin].includes(user.role as Role)
              ? content.login.form.password.lpr_password_hint
              : content.login.form.password.password_hint}
          </span>
        )}
        <InputCustom
          isError={isPasswordError(newPassword, repeatPassword)}
          value={repeatPassword}
          onChange={handleRepeatPassword}
          className={styles.profile_settings__block_input}
          type={"password"}
          placeholder={content.profile_settings.inputs.repeat_password}
        />
        {repeatHint && (
          <span className={styles.profile_settings__current_user__passwordHint} style={{ color: "#f00" }}>
            {content.login.form.password.password_compare_hint}
          </span>
        )}
      </div>
      <ButtonCustom
        className={styles.profile_settings__current_user_container_button}
        children={<span>{content.profile_settings.buttons.save}</span>}
        onClick={updatePassword}
        disabled={disabled}
      />
    </div>
  );
};

export default ProfileSettingsPassword;
