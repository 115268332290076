import InputCustom from "components/ui/InputCustom/InputCustom";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import styles from "./SearchBar.module.scss";
import { IInput } from "components/ui/InputCustom/InputCustom";

interface IInputSearchProps extends IInput {
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const SearchBar: React.FC<IInputSearchProps> = ({ placeholder, className, onChange, value, onFocus }) => {
  return (
    <InputCustom
      className={className ? className : styles.search_bar}
      placeholder={placeholder}
      suffix={<SearchIcon />}
      onChange={onChange}
      value={value}
      allowClear
      onFocus={onFocus}
    ></InputCustom>
  );
};

export default SearchBar;
