import moment from "moment";
import { IDeals } from "services/Deals";
import { SortOptionsList } from "./sortOptions";

export const sortDealsByCurrentOption = ({
  currentOption,
  deals,
  isSeeReminders,
}: {
  deals: IDeals[];
  currentOption: SortOptionsList | undefined;
  isSeeReminders: boolean;
}): IDeals[] => {
  if (!currentOption) {
    return deals;
  }
  if (currentOption === SortOptionsList.newFirst) {
    return deals.sort(function (a, b) {
      const aReminders = a.reminders_count ? a.reminders_count : 0;
      const bBReminders = b.reminders_count ? b.reminders_count : 0;
      if (!!aReminders && !bBReminders && isSeeReminders) {
        return -1; // a перед b
      } else if (!aReminders && bBReminders && isSeeReminders) {
        return 1; // b перед a
      } else {
        return moment(b.created_at).diff(moment(a.created_at));
      }
    });
  }
  if (currentOption === SortOptionsList.oldFirst) {
    return deals.sort(function (a, b) {
      const aReminders = a.reminders_count ? a.reminders_count : 0;
      const bBReminders = b.reminders_count ? b.reminders_count : 0;
      if (!!aReminders && !bBReminders && isSeeReminders) {
        return -1; // a перед b
      } else if (!aReminders && bBReminders && isSeeReminders) {
        return 1; // b перед a
      } else {
        return moment(a.created_at).diff(moment(b.created_at));
      }
    });
  }
  if (currentOption === SortOptionsList.byClientName) {
    return deals.sort((a, b) => {
      const isANumber = /^\d/.test(a.client_fullname);
      const isBNumber = /^\d/.test(b.client_fullname);
      const aReminders = a.reminders_count ? a.reminders_count : 0;
      const bBReminders = b.reminders_count ? b.reminders_count : 0;
      if (!!aReminders && !bBReminders && isSeeReminders) {
        return -1; // a перед b
      } else if (!aReminders && bBReminders && isSeeReminders) {
        return 1; // b перед a
      } else if (isANumber && !isBNumber) {
        return -1; // a перед b
      } else if (!isANumber && isBNumber) {
        return 1; // b перед a
      } else {
        return a.client_fullname.localeCompare(b.client_fullname); // обычная алфавитная сортировка
      }
    });
  }
  if (currentOption === SortOptionsList.byTitle) {
    return deals.sort((a, b) => {
      const isANumber = /^\d/.test(a.title);
      const isBNumber = /^\d/.test(b.title);

      const aReminders = a.reminders_count ? a.reminders_count : 0;
      const bBReminders = b.reminders_count ? b.reminders_count : 0;
      if (!!aReminders && !bBReminders && isSeeReminders) {
        return -1; // a перед b
      } else if (!aReminders && bBReminders && isSeeReminders) {
        return 1; // b перед a
      } else if (isANumber && !isBNumber) {
        return -1; // a перед b
      } else if (!isANumber && isBNumber) {
        return 1; // b перед a
      } else {
        return a.title.localeCompare(b.title); // обычная алфавитная сортировка
      }
    });
  }
  if (currentOption === SortOptionsList.updatedFirst) {
    // return deals.sort(function (a, b) {
    //   return moment(b.updated_at).diff(moment(a.updated_at));
    // });
    return deals;
  }
  return deals;
};
