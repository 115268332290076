import { memo, MutableRefObject, useCallback, useEffect, useState } from "react";
import styles from "./ControlPanel.module.scss";
import "./ControlPanel_override.scss";
import { useQuery } from "react-query";
import { getCurrentUserInfo } from "services/User";
import { getDraftDealsCount } from "services/Deals";
import ControlCard from "./ControlCard";
import { Modal, Skeleton } from "antd";
import ControlCardPopUp from "./ControlCardPopUp/ControlCardPopUp";
import content, { Role } from "settings/content";
import ValidateComponentByRole from "components/ValidateComponentByRole/ValidateComponentByRole";
import { ReactComponent as CloseIcon } from "assets/icons/cross.svg";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import { checkAuthenticated } from "helpers/checkAuth";
import { useUser } from "context/useUser";
import User from "components/User/User";
import ProfileSettings from "components/ProfileSettings/ProfileSettings";
import ModalCustom from "components/ModalCustom/ModalCustom";
import NotificationsList from "components/NotificationsList/NotificationsList";
import { useClickAway } from "@uidotdev/usehooks";
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { getReminders, INotification } from "services/Reminders";
import dayjs from "dayjs";
import { useNotificationContext } from "context/useAntdNotification";
import { useNotificationsContext } from "context/useNotifications";
import logo from "assets/logos/CERAMIC CRM.svg";

interface IPopUp {
  name: string;
  title: string;
}

const initialState = { name: "", title: "" };

const ControlPanel = () => {
  const { api } = useNotificationContext();
  const { setNotifications } = useNotificationsContext();

  const { user } = useUser();
  const authenticated = checkAuthenticated();

  const [openCardName, setOpenCardName] = useState<IPopUp>(initialState);
  const [countLeads, setCountLeads] = useState<number>(0);
  const [isNotificationsVisible, setIsNotificationsVisible] = useState<boolean>(false);
  const [reminders, setReminders] = useState<INotification[]>([]);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  const profileToggler = useCallback((toggler: boolean) => {
    setIsSettingsVisible(toggler);
  }, []);

  const { data: userNotificationsInfo } = useQuery({
    queryFn: () => getReminders(),
    queryKey: ["userReminders"],
    enabled: authenticated && user.role === Role.DEPARTMENT_EMPLOYEE,
    refetchInterval: 30000,
  });

  /* Временно закомменчено, т.к. из-за этого не работает диалоговое окно в профиле пользователей */
  const ref: MutableRefObject<HTMLDivElement> = useClickAway(() => {
    // setIsSettingsVisible(false);
  });

  const {
    data: userInfo,
    isLoading,
    refetch: refetchUser,
  } = useQuery({
    queryFn: () => getCurrentUserInfo(),
    queryKey: ["user"],
    enabled: authenticated,
  });

  const { data: draftDealsCount, refetch } = useQuery({
    queryFn: () => getDraftDealsCount(),
    queryKey: ["draft_deals"],
    enabled: false,
  });

  const getNotificationsCount = (count: number) => {
    return count || null;
  };

  const handleOpenCard = (name: string, title: string) => {
    if (name === openCardName.name) {
      setOpenCardName(initialState);
      return;
    }
    setOpenCardName({ name, title });
  };

  const handleCount = (name: string): number => {
    if (name === "leads") {
      return countLeads;
    }
    return 0;
  };

  const handleUpdateDraftDealsCount = async () => {
    await refetch();
  };

  const updateUserInfo = async () => {
    await refetchUser();
  };

  const calculateModalWidth = () => {
    if (openCardName.name === "register") {
      return 600;
    }
    if (openCardName.name === "analytic_projects") {
      return 992;
    }
    if (openCardName.name === "statistics_coach") {
      return 1000;
    }
    if (openCardName.name === "analytic_active_days") {
      return 1300;
    }
  };

  useEffect(() => {
    if (!draftDealsCount) {
      return;
    }
    setCountLeads(draftDealsCount?.data.count);
  }, [draftDealsCount]);

  useEffect(() => {
    if (userNotificationsInfo) {
      /// Сортируем напоминания по дате и фильтруем, оставляя только непрочитанные
      const sortedData = userNotificationsInfo.data
        .sort((a, b) => {
          const dateA = dayjs(a.notify_at);
          const dateB = dayjs(b.notify_at);

          if (dateA.isBefore(dateB)) {
            return -1;
          } else if (dateA.isAfter(dateB)) {
            return 1;
          } else {
            return 0;
          }
        })
        .filter((notification) => !notification.was_read);

      setReminders(sortedData);
    }
  }, [userNotificationsInfo]);

  return (
    <div className={styles.controlPanel__container}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <div className={styles.controlPanelHeaderContainer}>
            <div className={styles.headerName}>
              <img src={logo} />
            </div>
            <div className={styles.headerMenu__user} ref={ref}>
              <User username={userInfo?.name} profileToggler={profileToggler} />
              <ValidateComponentByRole requiredRoles={[Role.DEPARTMENT_EMPLOYEE]}>
                <div className={styles.headerMenu__notification_block}>
                  <ButtonCustom
                    onClick={() => setIsNotificationsVisible(!isNotificationsVisible)}
                    className={styles.headerMenu__button}
                    isTransparent
                  >
                    <AlertIcon width={27} height={27} />
                  </ButtonCustom>
                  {reminders && reminders.length > 0 && (
                    <span className={styles.headerMenu__alert_count}>{getNotificationsCount(reminders.length)}</span>
                  )}
                </div>
              </ValidateComponentByRole>
              {isSettingsVisible && <ProfileSettings userInfo={userInfo} updateUserInfo={updateUserInfo} profileToggler={profileToggler} />}
              <ModalCustom
                isOpen={isNotificationsVisible}
                title={"Уведомления"}
                handleCloseModal={() => setIsNotificationsVisible(false)}
                centered={false}
                width={470}
                footer={null}
              >
                <NotificationsList
                  onClick={(id) => {
                    setReminders((prev) => prev.filter((item) => item.id !== id));
                    setNotifications((prev) => prev.filter((item) => item.id !== id));
                    setIsNotificationsVisible(false);
                    api.destroy(id);
                  }}
                  notifications={reminders}
                />
              </ModalCustom>
            </div>
          </div>
          <div className={styles.controlPanel__buttons_container}>
            <div className={styles.controlPanel__buttons_container__buttons_list}>
              {content.control.controlButtons.map((item) => (
                <ValidateComponentByRole requiredRoles={item.requiredRole} key={item.name}>
                  <ControlCard
                    icon={item.icon}
                    title={item.title}
                    name={item.name}
                    path={item.path}
                    notificationCount={handleCount(item.name)}
                    handleOpenCard={handleOpenCard}
                    type={item.type}
                  />
                </ValidateComponentByRole>
              ))}
            </div>
            <Modal
              centered
              className={"custom_control_modal"}
              destroyOnClose
              open={openCardName.name !== ""}
              onCancel={() => setOpenCardName(initialState)}
              footer={false}
              width={calculateModalWidth()}
              closeIcon={false}
              maskClosable={false}
            >
              <div className={styles.controlPanel__modal_header}>
                <span>{openCardName.title}</span>
                <ButtonCustom
                  isTransparent
                  maxWidth="20px"
                  className={styles.controlPanel__modal_close}
                  onClick={() => setOpenCardName(initialState)}
                >
                  <CloseIcon />
                </ButtonCustom>
              </div>
              <ControlCardPopUp updateDraftDealsCount={handleUpdateDraftDealsCount} header={openCardName} />
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(ControlPanel);
