import { INotification } from "services/Reminders";

export const buildUrlByNotification = (notification: INotification): string | null => {
  if (notification.deal_id) {
    return `/deals?deal_id=${notification.deal_id}`;
  }

  if (notification.lead_id) {
    return `/leads?lead_id=${notification.lead_id}`;
  }

  return null;
};
