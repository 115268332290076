import { useEffect, useState } from "react";
import { Input, Modal, message } from "antd";
import InputCustom from "components/ui/InputCustom/InputCustom";
import { modalStuffTypes } from "pages/Organization/Stuff/Stuff";
import styles from "./StuffCardDetail.module.scss";
import "./StuffCardDetail_override.scss";
import content, { Role } from "settings/content";
import ButtonCustom from "components/ui/ButtonCustom/ButtonCustom";
import {
  ICreateStuffRequest,
  IRequestStuff,
  activateUser,
  createStuff,
  deactivateUser,
  editStuff,
  editStuffPassword,
  getStuffById,
  getUserRoles,
} from "services/User";
import { useMutation, useQuery } from "react-query";
import { NoticeType } from "antd/es/message/interface";
import { formatPhone } from "helpers/formatString";
import SelectCustom from "components/ui/SelectCustom/SelectCustom";
import { IShop } from "services/Shops";
import { IDepartment } from "services/Department";
import { isEmailValid, isPhoneValid, isPasswordValid } from "helpers/validation";
import { PhoneInput, ParsedCountry } from "react-international-phone";
import { PHONE_COUNTRIES } from "settings/countries";
import { generatePassword } from "./generatePassword";

export interface IStuffCardDetail {
  isEdit: string;
  open: boolean;
  onCancel: () => void;
  editAndRefetch: () => void;
  createAndRefetch: () => void;
  shops: IShop[];
  departments: IDepartment[];
  refetchStuffs: () => void;
}

const initialData = {
  phone: undefined,
  login: "",
  email: "",
  name: "",
  password: "",
  shop_id: "",
  department_id: "",
  role: "",
};

const StuffCardDetail: React.FC<IStuffCardDetail> = ({
  isEdit,
  open,
  onCancel,
  editAndRefetch,
  createAndRefetch,
  departments,
  shops,
  refetchStuffs,
}) => {
  const defaultCountryLocalStorage = localStorage.getItem("defaultCountryIso");

  const [isFormTried, setIsFormTried] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState<IRequestStuff>(initialData);
  const [password, setPassword] = useState<string>("");
  const [shopId, setShopId] = useState<string>("");
  const [departmentId, setDepartmentId] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true);
  const [defaultCountry, setDefaultCountry] = useState<string | undefined>(defaultCountryLocalStorage ?? "ru");

  const { data: currentStuff, refetch } = useQuery({
    queryFn: () => getStuffById(isEdit),
    queryKey: ["currentStuff"],
    enabled: isEdit !== modalStuffTypes.CREATE && isEdit !== modalStuffTypes.EMPTY,
  });

  const { data: userRoles } = useQuery({
    queryFn: () => getUserRoles(),
    queryKey: ["roles"],
  });

  const { mutateAsync: onEditStuff } = useMutation({
    mutationFn: editStuff,
    onSuccess: () => {},
  });

  const { mutateAsync: onEditStuffPassword } = useMutation({
    mutationFn: editStuffPassword,
    onSuccess: () => {},
  });

  const { mutateAsync: onCreateStuff } = useMutation({
    mutationFn: createStuff,
    onSuccess: () => {},
  });

  const { mutateAsync: onDeactivateUser } = useMutation({
    mutationFn: deactivateUser,
    onSuccess: () => refetch(),
  });

  const { mutateAsync: onActivateUser } = useMutation({
    mutationFn: activateUser,
    onSuccess: () => refetch(),
  });

  const validateForm = (data: IRequestStuff) => {
    if (data.role !== Role.DEPARTMENT_EMPLOYEE) {
      // TODO: data.email can be undefined, but shouldn't
      if (!data.phone || !isPhoneValid(data.phone) || !isEmailValid(data.email || null)) {
        setIsFormDisabled(true);
        return;
      }
    }
    if (data.role === Role.DEPARTMENT_EMPLOYEE) {
      if (!data?.login || data.login === "" || data.login.length < 3) {
        setIsFormDisabled(true);
        return;
      }
    }
    if (data.name === "" || data.role === "") {
      setIsFormDisabled(true);
      return;
    }
    if (isEdit === modalStuffTypes.CREATE && !isPasswordValid(password, data.role as Role)) {
      setIsFormDisabled(true);
      return;
    }
    if (isEdit !== modalStuffTypes.CREATE && password && !isPasswordValid(password, data.role as Role)) {
      setIsFormDisabled(true);
      return;
    }
    if ((data.role === "department_employee" || data.role === "department_manager") && (data.shop_id === "" || data.department_id === "")) {
      setIsFormDisabled(true);
      return;
    }
    if (data.role === "shop_manager" && data.shop_id === "") {
      setIsFormDisabled(true);
      return;
    }
    setIsFormDisabled(false);
  };

  useEffect(() => {
    setData(initialData);
    setPassword("");
    setIsFormTried(false);

    if (isEdit === modalStuffTypes.CREATE) {
      setShopId("");
      setDepartmentId("");
      setRole("");
    }
  }, [isEdit]);

  useEffect(() => {
    if (currentStuff?.data) {
      const phone = currentStuff.data.phone;
      setData({ ...currentStuff.data, phone: phone ? phone : "" });
    }
    if (isEdit === modalStuffTypes.CREATE) {
      setData(initialData);
    }
  }, [currentStuff?.data, isEdit]);

  useEffect(() => {
    validateForm(data);
    if (data.role) {
      setRole(data.role);
    }
    if (data.department_id) {
      setDepartmentId(data.department_id);
    }
    if (data.shop_id) {
      setShopId(data.shop_id);
    }
    if (data.role === "organization_manager" && data.department_id !== "") {
      setData({ ...data, department_id: "" });
      setDepartmentId("");
    }
    if (data.role === "organization_manager" && data.shop_id !== "") {
      setData({ ...data, shop_id: "" });
      setShopId("");
    }
    if (data.role === "shop_manager" && data.department_id !== "") {
      setData({ ...data, department_id: "" });
      setDepartmentId("");
    }
  }, [data, password]);

  useEffect(() => {
    setData((prev) => ({ ...prev, password: password }));
  }, [password]);

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const getDataInput = (name: string) => {
    return data[name as keyof IRequestStuff];
  };

  const handleData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (defaultCountry) {
      localStorage.setItem("defaultCountryIso", defaultCountry);
    }
  }, [defaultCountry]);

  const onPhoneChange = (
    phone: string,
    meta: {
      country: ParsedCountry;
      inputValue: string;
    },
  ) => {
    const formatedPhone = formatPhone(phone);
    setDefaultCountry(meta.country.iso2);
    setData({ ...data, phone: formatedPhone });
  };

  const handleShop = (value: string) => {
    setData({ ...data, shop_id: value, department_id: "" });
    setDepartmentId("");
  };

  const handleDepartment = (value: string) => {
    setData({ ...data, department_id: value });
  };

  const handleRole = (value: string) => {
    setData({ ...data, role: value });
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleCreate = () => {
    setIsFormTried(true);
    if (!data?.role || !data?.name) {
      return;
    }
    const requestData: ICreateStuffRequest = {
      role: data.role,
      login: data?.login?.toLowerCase() ?? "",
      password: password,
      name: data.name,
      department_id: data.department_id === "" ? null : data.department_id,
      shop_id: data.shop_id === "" ? null : data.shop_id,
      email: null,
      phone: null,
    };

    if (data?.email) {
      requestData.email = data.email?.toLowerCase();
    }
    if (data?.phone && data?.phone.length > 9) {
      requestData.phone = formatPhone(data.phone);
    }

    // Временная валидация на сущуствующий номер телефона
    onCreateStuff(requestData).then((response) => {
      if (response.status === 200) {
        createAndRefetch();
        setPassword("");
      } else {
        alert("error", JSON.stringify(response.data) !== "" ? JSON.stringify(response.data) : "Что-то пошло не так");
      }
    });
  };

  const handleEdit = async () => {
    setIsFormTried(true);
    const requestData: IRequestStuff = {
      department_id: data.department_id !== "" ? data.department_id : null,
      shop_id: data.shop_id !== "" ? data.shop_id : null,
      email: data?.email && data.email !== "" ? data.email?.toLowerCase() : null,
      name: data.name,
      phone: data?.phone && data?.phone.length > 9 ? formatPhone(data.phone ?? "") : null,
      role: data.role,
      // login: data.login,
    };
    if (password) {
      const passwordRequest = await onEditStuffPassword({ id: isEdit, password });

      if (!passwordRequest?.status || (passwordRequest?.status && passwordRequest.status !== 200)) {
        alert("error", content.alert_messages.stuffs.edit.password_error_stuff);
      }
    }
    onEditStuff({
      id: isEdit,
      userInfo: requestData,
    })
      .then((response) => {
        if (response.status === 200) {
          editAndRefetch();
          setPassword("");
        } else {
          alert("error", content.alert_messages.stuffs.edit.error_stuff);
        }
      })
      .catch((err) => {
        alert("error", content.alert_messages.stuffs.edit.error_stuff);
      });
  };

  const handleBlock = (id: string) => {
    onDeactivateUser(id).then((res) => {
      if (res.status === 200) {
        alert("success", content.alert_messages.stuffs.deactivate.deactivate_success);
        refetch();
        refetchStuffs();
      } else {
        alert("success", content.alert_messages.stuffs.deactivate.deactivate_error);
      }
    });
  };

  const handleUnblock = (id: string) => {
    onActivateUser(id).then((res) => {
      if (res.status === 200) {
        alert("success", content.alert_messages.stuffs.activate.activate_success);
        refetch();
        refetchStuffs();
      } else {
        alert("success", content.alert_messages.stuffs.activate.activate_error);
      }
    });
  };

  return (
    <Modal
      destroyOnClose={true}
      width={371}
      className="custom_styles"
      centered
      open={open}
      onCancel={() => {
        onCancel();
        setData(initialData);
        setPassword("");
        setShopId("");
        setDepartmentId("");
        setRole("");
      }}
      footer={null}
      maskClosable={false}
    >
      <div className={styles.stuffCard_detail}>
        {contextHolder}
        <div className={styles.stuffCard_detail__header}>
          <p className={styles.stuffCard_detail__header_title}>
            {isEdit === "create" ? content.organizations.stuff.title : content.organizations.stuff.title_edit}
          </p>
        </div>
        <div className={styles.stuffCard_detail__form}>
          {userRoles?.data && (
            <div className={styles.stuffCard_detail__form_data}>
              <p className={styles.stuffCard_detail__form_title}>{content.organizations.stuff.detail_card.roles.title}</p>
              <SelectCustom
                defaultValue={data.role}
                width="100%"
                placeholder={content.organizations.stuff.detail_card.roles.placeholder}
                options={userRoles.data
                  ?.map((role) => ({
                    value: role.id,
                    label: role.name,
                  }))
                  .filter((item) => item.value !== "organization_admin")}
                onChange={handleRole}
                value={role}
                disabled={data.role === "organization_admin"}
              />
            </div>
          )}
          {content.organizations.stuff.detail_card.data_input.map((item, index) => {
            return (
              <div className={styles.stuffCard_detail__form_data} key={index}>
                {item.type === "password" ? (
                  <div className={styles.stuffCard_detail__form_data_label}>
                    <p className={styles.stuffCard_detail__form_title}>{item.title}</p>
                    <ButtonCustom
                      maxWidth="120px"
                      className={styles.stuffCard_detail__form_psw_btn}
                      onClick={() => generatePassword({ data, setPassword })}
                    >
                      <span>{content.organizations.stuff.password_btn}</span>
                    </ButtonCustom>
                  </div>
                ) : (
                  <p className={styles.stuffCard_detail__form_title}>{item.title}</p>
                )}

                {item.name === "phone" ? (
                  <PhoneInput
                    name={item.name}
                    value={getDataInput(item.name) as string}
                    onChange={onPhoneChange}
                    placeholder={content.login.form.phone.placeholder}
                    countries={PHONE_COUNTRIES}
                    defaultCountry={getDataInput(item.name) ? defaultCountry : "ru"}
                    forceDialCode
                  />
                ) : item.type === "password" ? (
                  <Input.Password
                    autoComplete="new-password"
                    name={item.name}
                    placeholder={item.placeholder}
                    value={password}
                    allowClear
                    onChange={handlePassword}
                    style={{ borderColor: password === "" ? "" : password.length < 4 ? "#FF0000" : "" }}
                    status={!password || isPasswordValid(password, data.role as Role) ? "" : "error"}
                  />
                ) : (
                  <InputCustom
                    name={item.name}
                    placeholder={item.placeholder}
                    value={getDataInput(item.name) as string}
                    onChange={handleData}
                    isError={(() => {
                      const currentValue = getDataInput(item.name);
                      if (!isFormTried && !currentValue) {
                        return false;
                      }
                      if (isFormTried && (!currentValue || currentValue.length < 3)) {
                        return true;
                      }
                      // TODO: data.email can be undefined, but shouldn't
                      if (item.name === "email" && !isEmailValid(data.email || null)) {
                        return true;
                      }
                      return !currentValue || currentValue.length < 3;
                    })()}
                  />
                )}
              </div>
            );
          })}
          {data.role !== "organization_admin" && data.role !== "organization_manager" && (
            <div className={styles.stuffCard_detail__form_data}>
              <p className={styles.stuffCard_detail__form_title}>{content.organizations.stuff.detail_card.shop.title}</p>
              <SelectCustom
                width="100%"
                placeholder={content.organizations.stuff.detail_card.shop.placeholder}
                options={shops?.map((shop) => ({
                  value: shop.id,
                  label: `${shop.name} (${shop.address})`,
                }))}
                onChange={handleShop}
                value={shopId}
                disabled={data.role === "organization_manager"}
              />
            </div>
          )}
          {data.role !== "organization_admin" && data.role !== "organization_manager" && data.role !== "shop_manager" && (
            <div className={styles.stuffCard_detail__form_data}>
              <p className={styles.stuffCard_detail__form_title}>{content.organizations.stuff.detail_card.department.title}</p>
              <SelectCustom
                width="100%"
                placeholder={content.organizations.stuff.detail_card.department.placeholder}
                options={departments
                  ?.filter((item) => item.shop_id === shopId)
                  ?.map((department) => ({
                    value: department.id,
                    label: department.name,
                  }))}
                onChange={handleDepartment}
                value={departmentId}
                disabled={!shopId || data.role === "organization_manager" || data.role === "shop_manager"}
              />
            </div>
          )}

          <div className={styles.stuffCard_detail__footer_container}>
            <ButtonCustom
              maxWidth="140px"
              className={styles.stuffCard_detail__footer_container_custom_button_save}
              children={<span>{content.organizations.stuff.detail_card.footer.save}</span>}
              disabled={isFormDisabled}
              onClick={isEdit === modalStuffTypes.CREATE ? handleCreate : handleEdit}
            />
            {isEdit !== modalStuffTypes.CREATE && (
              <ButtonCustom
                maxWidth="210px"
                className={
                  data.deactivated_at
                    ? styles.stuffCard_detail__footer_container_custom_button_unblock
                    : styles.stuffCard_detail__footer_container_custom_button_block
                }
                children={
                  data.deactivated_at ? <span>Активировать</span> : <span>{content.organizations.stuff.detail_card.footer.block}</span>
                }
                onClick={() => {
                  data.deactivated_at ? handleUnblock(data.id || "") : handleBlock(data.id || "");
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StuffCardDetail;
