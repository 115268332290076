import React, { ReactElement, useContext, useEffect, useState } from "react";
import { QueryObserverResult, useQuery } from "react-query";
import { checkAuthenticated } from "helpers/checkAuth";
import { IGetLeadsList, ILeadChannel, ILeadData, ILeadPromo, getLeadChannels, getLeadPromos, getLeadsList } from "services/Leads";

interface ILeadsProps {
  children: ReactElement;
}

interface ILeadsContext {
  leadsData: ILeadData[];
  refetchLeads: () => void;
  isLeadsLoading: boolean;
  isLeadsError: boolean;
  leadChannelsData: ILeadChannel[];
  leadPromosData: ILeadPromo[];
  setSearchValue: (value: string) => void;
}

const initialContext: ILeadsContext = {
  leadsData: [],
  refetchLeads: () => {},
  setSearchValue: () => {},
  isLeadsLoading: false,
  isLeadsError: false,
  leadChannelsData: [],
  leadPromosData: [],
};

const LeadsContext = React.createContext<ILeadsContext>(initialContext);

export const useLeads = () => {
  return useContext(LeadsContext);
};

export const LeadsProvider: React.FC<ILeadsProps> = ({ children }) => {
  const authenticated = checkAuthenticated();

  const [leadsData, setLeadsData] = useState<ILeadData[]>([]);
  const [leadChannelsData, setLeadsChannelsData] = useState<ILeadChannel[]>([]);
  const [leadPromosData, setLeadsPromosData] = useState<ILeadPromo[]>([]);
  const [searchValue, setSearchValue] = useState<string>();

  const {
    data,
    refetch,
    isLoading: isLeadsLoading,
    isError: isLeadsError,
    isFetching: isLeadsFetching,
  } = useQuery({
    queryFn: () => getLeadsList({ search: searchValue }),
    queryKey: ["leads"],
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      if (!Array.isArray(data.data.groups)) {
        return;
      }
      setLeadsData(data.data.groups);
    },
    enabled: authenticated,
  });

  const { refetch: refetchLeadsChannels } = useQuery({
    queryFn: () => getLeadChannels(),
    queryKey: ["leads-channels"],
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      if (!Array.isArray(data.data)) {
        return;
      }
      setLeadsChannelsData(data.data);
    },
    enabled: authenticated,
  });

  const { refetch: refetchLeadsPromos } = useQuery({
    queryFn: () => getLeadPromos(),
    queryKey: ["leads-promos"],
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      if (!Array.isArray(data.data)) {
        return;
      }
      setLeadsPromosData(data.data);
    },
    enabled: authenticated,
  });

  useEffect(() => {
    if (!authenticated) {
      return;
    }
    refetch();
  }, [searchValue]);

  return (
    <LeadsContext.Provider
      value={{
        leadsData,
        refetchLeads: refetch,
        isLeadsLoading,
        isLeadsError,
        leadChannelsData,
        leadPromosData,
        setSearchValue,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};
