import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl5050, baseUrl5055 } from "env";

export interface AnalyticsDeal {
  shop_id: string;
  department_id: string;
  created_by: string;
  deals_count: number;
  deals_sold_count: number;
  days_to_sold_average: number;
  projects_count: number;
}
export interface IAnalyticsProjectsResponse {
  data: AnalyticsDeal[];
  status: number;
}

export interface IGetAnalyticsByActiveDaysItem {
  key: string;
  shop_id: string;
  department_id: string;
  active_days_count: number;
  opened_projects_count: number;
  unique_opened_projects_count: number;
  opened_multiroom_projects_count: number;
  opened_unique_multiroom_projects_count: number;
  visualizations_count: number;
  modules: any[];
}

export interface IGetAnalyticsByActiveDays {
  data: {
    count: number;
    items: IGetAnalyticsByActiveDaysItem[];
  };
  status: number;
}

export async function getAnalyticsProjects(date_from: string, date_to: string): Promise<IAnalyticsProjectsResponse> {
  const date = date_from && date_to ? `?period_start=${date_from}&period_end=${date_to}` : "";
  return axios
    .get(baseUrl5050 + `analytics/deals${date}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data.items,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getAnalyticsProjects:", err.response);
      return err.response;
    });
}

export async function getAnalyticsByActiveDays(interval: { dateFrom: string; dateTo: string }): Promise<IGetAnalyticsByActiveDays> {
  return axios
    .get(baseUrl5055 + `analytics/active-days?start_date=${interval.dateFrom}&end_date=${interval.dateTo}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getAnalyticsByActiveDays:", err.response);
      return err.response;
    });
}
