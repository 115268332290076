import React from "react";
import Layout from "components/Layout/Layout";
import ProtectedRoute from "PrivateRoute";
import DealsPage from "pages/DealsPage/DealsPage";
import LeadsPage from "pages/LeadsPage/LeadsPage";
import { BlanksPage } from "pages/BlanksPage/BlanksPage";
import MainPage from "pages/MainPage/MainPage";
import Organization from "pages/Organization/Organization";

export interface WithWorkspaceLayoutProps {
  isAuthenticated: boolean;
  pagePermission: boolean;
}

export const WithWorkspaceLayout: React.FC<WithWorkspaceLayoutProps> = ({ isAuthenticated, pagePermission }) => {
  return (
    <Layout>
      <ProtectedRoute isAuthenticated={isAuthenticated} authenticationPath={"/signin"} path={"/deals"} exact component={DealsPage} />
      <ProtectedRoute isAuthenticated={isAuthenticated} authenticationPath={"/signin"} path={"/leads"} exact component={LeadsPage} />
      <ProtectedRoute isAuthenticated={isAuthenticated} authenticationPath={"/signin"} path={"/blanks"} exact component={BlanksPage} />
      <ProtectedRoute isAuthenticated={isAuthenticated} authenticationPath={"/signin"} path={"/"} exact component={MainPage} />
      {pagePermission && (
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          authenticationPath={"/signin"}
          path={"/organization"}
          exact
          component={Organization}
        />
      )}
    </Layout>
  );
};
